<template>
  <v-list nav dense>
    <div>
      <wired-nav-menu :mini-flag="miniFlag" :chat-message-received-flag="chatMessageReceivedFlag" :chat-unread-messages-count="chatUnreadMessagesCount"></wired-nav-menu>
    </div>
  </v-list>
</template>

<script>
import WiredNavMenu from './WiredNavMenu'

export default {
  components: {
    WiredNavMenu
  },
  props:{
    miniFlag:{
      type:Boolean,
      default:false
    },
    chatMessageReceivedFlag:{
      type:Boolean,
      default:false
    },
    chatUnreadMessagesCount:{
      type:Number,
      default:0
    }
  }
}
</script>
