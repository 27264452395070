import { doPostOne } from '@/services/marketplace_connection.service'
import { snakeCase } from 'lodash'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { getField, updateField } from 'vuex-map-fields'
const snakes = createHumps(snakeCase)

export const marketplaceConnectionOne = {
  namespaced: true,
  state: {
    one: null,
    inProgress: true
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    }
  },
  actions: {
    new({ commit }, { businessEntityId }) {
      commit('inProgress', false)
      commit('setOne', {
        businessEntityId:businessEntityId,
        marketplace:'Joor',
        userName: '',
        userPw: '',
        enabled: false
      })
    },

    /* fetchOne: async({ commit }, { businessEntityId }) => {
      commit('inProgress', true)
      try {
        const businessConnection = await doFetchOne({ businessEntityId })

        if (businessConnection) {
          commit('setOne', humps(businessConnection))
        }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },*/

    create: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        const created = await doPostOne({ connection:snakes(Object.assign({}, state.one)) })

        commit('setOne', humps(created))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    }

    /*update: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        const updated = await doPatchOne({ connection:snakes(Object.assign({}, state.one)) })

        commit('setOne', humps(updated))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    }*/
  }

}