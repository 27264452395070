import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapEmptyRequest,wrapRequestWithRange } = nav
const ashtHubName = process.env.VUE_APP_ASHTA_HUB_NAME

const ashtaApiUrl = process.env.VUE_APP_ASHTA_API_URL

export const doPostOne = wrapEmptyRequest(({ registrationObj }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(registrationObj)
  }

  return fetch(`${ashtaApiUrl}/registration_request`, requestOptions)
})

export const doFetchList = wrapRequestWithRange(({  limit, offset }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=exact', ...getHubAuthHeader(ashtHubName) }
  }
  const params = {  limit: limit || 50, offset: offset || 0 }
  const qs = Object.keys(params).map((key) => key + '=' + params[key]).join('&')

  return fetch(`${ashtaApiUrl}/registration_request?${qs}`, requestOptions)
})