import { doPostChannelOne, doPostOne,doPachChatReceipt,doGetChatSummary } from '@/services/chat_room.service'
import { snakeCase } from 'lodash'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { getField, updateField } from 'vuex-map-fields'
const snakes = createHumps(snakeCase)

export const chatRoomOne = {
  namespaced: true,
  state: {
    one: null,
    channel: null,
    inProgress: true
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setChennel(state, channel) {
      state.channel = channel
    }
  },
  actions: {
    new({ commit }, { appId,channelId,authorId }) {
      commit('inProgress', false)
      commit('setOne', {
        'appId': appId,
        'channelId':channelId,
        'authorId':authorId,
        'postedContent': null,
        'createdAt':null
      })
    },
    newChennel ({ commit }, { appId ,name }) {
      commit('inProgress', false)
      commit('setChennel', {
        'appId': appId,
        'name':name,
        'resource_ids':null
      })
    },
    saveChennel: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        const created = await doPostChannelOne({ 'channel':snakes( state.channel) })

        commit('setChennel', humps(created))

        return created
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    send: async({ commit, state },{ channelIdNew }) => {
      commit('inProgress', true)
      try {
        if (channelIdNew)
        {
          state.one.channelId = channelIdNew
        }
        const created = await doPostOne({ message:snakes(Object.assign({}, state.one)) })

        commit('setOne', humps(created))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    updateChatReceipt: async({ commit },{ updateProperty, appId, channelId,recipientId } ) => {
      commit('inProgress', true)
      try {
        const data = await doPachChatReceipt({ updateProperty, appId, channelId,recipientId })

        //  commit('setChennel', humps(created))

        return humps(data)
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    getChatSummary: async({ commit },{ appId,filter } ) => {
      commit('inProgress', true)
      try {
        const data = await doGetChatSummary({ appId,filter })

        //  commit('setChennel', humps(created))
        if (data) {
          return humps(data)
        }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    }
  }
}