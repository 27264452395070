<template>
  <div
    v-shortkey="['ctrl', '/']"
    v-resize="resizeView"
    class="d-flex flex-grow-1"
    @shortkey="onKeyup"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      :mini-variant.sync="mini"
      :permanent="navigationDrawerVisibility"
      :width="navigationDrawerWidth"
      class="elevation-1 background-color-4D4E4E"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      dark
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2 mb-8">
          <div :class="!mini?'d-flex align-center justify-center justify-space-between':'d-flex align-center justify-center'">
           
            <v-img
              v-if="!mini"
              contain
              dark
              aspect-ratio="2"
              class="ml-n1"
              max-width="130"
              :src="require('@/assets/ashta-white.png')"
            ></v-img>
           
            <div v-if="!mini">
              <v-btn
                class="float-right d-none d-sm-flex navigation-drawer-button"
                icon
                @click.stop="mini = !mini"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                class="float-right d-flex d-sm-none navigation-drawer-button"
                icon
                @click.stop="drawer = !drawer"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>
            <v-app-bar-nav-icon
              v-else
              class="d-none d-sm-flex navigation-drawer-button"
              @click.stop="mini = !mini"
            ></v-app-bar-nav-icon>
          </div>
          <v-img
            v-if="mini"
            contain
            class="mt-1 mx-auto"
            max-width="50"
            :src="require('@/assets/ashta-blue-symbol.png')"
          ></v-img>

          <div v-if="!mini" class="text-caption grey--text">
            {{ product.version }}
          </div>
        </div>
      </template>
      <main-menu :mini-flag="mini" />
      <template #append>
      
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
      class="header"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <!-- uncomment below and commentout above -->
            <!-- <div v-else class="d-flex flex-grow-1 align-center"> -->

            <v-app-bar-nav-icon
              class="d-flex d-sm-none"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-spacer class="d-lg-block"></v-spacer>
            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-app-bar
        v-if="showPageRouteBreadcrumb"
        v-show="$vuetify.breakpoint.smAndUp"
        color="#f2f5f8"
        app
        fixed
        class="mt-sm-7 mt-md-8 elevation-0"
      >
        <v-row>
          <v-col>
            <breadcrumb class="grey--text"></breadcrumb>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-container class="fill-height mt-sm-4 mt-md-6" :fluid="!isContentBoxed">
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>

      <v-footer app inset>
        <v-spacer></v-spacer>
        <div class="overline">
          Fait avec <v-icon small color="pink">mdi-heart</v-icon>
        </div>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// navigation menu configurations
import config from '../configs'
import MainMenu from '../components/navigation/MainMenu'

import Breadcrumb from '../components/common/Breadcrumb'
import ToolbarUser from '../components/toolbar/ToolbarUser'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    Breadcrumb
  },
  data() {
    return {
      drawer: null,
      showSearch: false,
      mini: false,
      navigation: config.navigation
    }
  },
  computed: {
    navigationDrawerWidth: function () {
      return this.$vuetify.breakpoint.sm
        ? '170'
        : this.$vuetify.breakpoint.md
          ? '200'
          : this.$vuetify.breakpoint.lgAndUp
            ? '245'
            : '245'
    },
    showPageRouteBreadcrumb: function () {
      return this.$store.state.global.showPageRouteBreadcrumb
    },
    navigationDrawerVisibility: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : false
    },
  
    ...mapState('app', [
      'product',
      'isContentBoxed',
      'menuTheme',
      'toolbarTheme',
      'isToolbarDetached',
      'appId',
      'toast'
    ])
  },
  watch: {
  
  },
  mounted() {
  },
  async created() {
    //to fetch current logged in user info
    if (this.$store.state.auth.userAuth) {
      this.$store.dispatch(
        'dashboard/fetchUserState',
        this.$store.state.auth.userAuth.id
      )
    }
  },
  methods: {
    resizeView() {
      if (!this.$vuetify.breakpoint.smAndUp) {
        this.mini = false
      }
    },
    onKeyup(e) {
      this.$refs.search.focus()
    }
  }
}
</script>

<style src="./DefaultLayout.scss" lang="scss" scoped />