//import { organizationService } from 'oak-vuex'
 
import { getField, updateField } from 'vuex-map-fields'
const clientId = process.env.VUE_APP_MICROSOFTT_CLIENTID
const authority = process.env.VUE_APP_MICROSOFTT_AUTHORTY
const redirectUri = process.env.VUE_APP_MICROSOFTT_REDIRECT_URL

export const oauth = {
  namespaced: true,
  state: {
    msalConfig: {
      auth: {
        clientId: clientId,
        authority:authority,
        redirectUri: redirectUri
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    },
    accessToken: ''
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setAccessToken(state, token) {
      state.accessToken = token
    }

  },
  actions: {

  }

}