import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest } = nav
const retainHubOakApiUrl = process.env.VUE_APP_RETAINHUB_OAK_API_URL

const retainhubHubName = process.env.VUE_APP_RETAINHUB_NAME

export const doFetchList = wrapRequest(({ entity, lineageFilter } ) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(retainhubHubName) }
  }

  const params = { app_id:'eq.asht' }
  // export const doFetchList = wrapRequest(({ entity, lineageFilter } ) => 
  // const params = { app_id:'eq.asht' }

  if (lineageFilter) {
    for (const prop in lineageFilter) {
      if (Object.prototype.hasOwnProperty.call(lineageFilter, prop)) {
        params[`entity_lineage->>${prop}`] = `eq.${lineageFilter[prop]}`
      }
    }
  }
  const qs = Object.keys(params).map((key) => key + '=' + params[key]).join('&')

  return fetch(`${retainHubOakApiUrl}/activity?${qs}&order=recorded_at.desc`, requestOptions)
})
export const doPostOne = wrapRequest(({ activity }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json', 'Prefer': 'return=representation', ...getHubAuthHeader(retainhubHubName) },
    body: JSON.stringify(activity)
  }

  return fetch(`${retainHubOakApiUrl}/activity`, requestOptions)
})