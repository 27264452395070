import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest  } = nav
const apiUrl = process.env.VUE_APP_ASHTA_API_URL
const ashtaHubName = 'ashtahub'
const doDirectoryLookup = wrapRequest(({ nameLike, forBusinessId, scope }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=estimated', ...getHubAuthHeader(ashtaHubName) },
    body: JSON.stringify(
      {
        name: nameLike,
        for_business_id: forBusinessId,
        scope: scope
      })
  }

  return fetch(`${apiUrl}/rpc/directory_lookup`, requestOptions)
})

export default {
  doDirectoryLookup
}
