//import { organizationService } from 'oak-vuex'
import { doFetchList, doPostOne } from '@/services/signup.service'
import { snakeCase } from 'lodash'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { getField, updateField } from 'vuex-map-fields'
const snakes = createHumps(snakeCase)

export const signupOne = {
  namespaced: true,
  state: {
    one: null,
    inProgress: true,
    peopleOptions: ['0-1', '2-5', '6-10', '11-50', '50-1,000,000'],
    currentRoleOptions: ['Sales', 'Buyer', 'Ceo', 'Finance / Accounting', 'Logistics', 'Admin', 'Other'],
    list:[],
    ownerEmail:'',
    recordsCount: 0
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    },
    setListCount(state, number) {
      state.recordsCount = number
    },
    setOwnerEmail(state, ownerEmail) {
      state.ownerEmail = ownerEmail
    }

  },
  actions: {
    new({ commit },{ ownerEmail }) {
      commit('inProgress', false)
      commit('setOne', {
        firstName:'',
        lastName: '',
        ownerEmail:ownerEmail,
        phoneNumber:'',
        receiveNewsConsent: false,
        peopleCountA: '',
        peopleCountB: '',
        companyName:'',
        companyIndustry:'',
        businessRole: ''
      })
    },

    create: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        await doPostOne({ registrationObj:snakes(state.one) })

        // commit('setOne', humps(created))

        return { data: true }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        console.log('Error in register', err)
      } finally {
        commit('inProgress', false)
      }
    },
    saveOwnerEmail: async({ commit },{ ownerEmail }) => {
      commit('inProgress', true)
      try {
        commit('setOwnerEmail', ownerEmail)
        commit('inProgress', false)
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        console.log('Error in register', err)
      } finally {
        commit('inProgress', false)
      }
    },
    fetchList: async ({ commit },{  pageNumber, limit }) => {
      commit('inProgress', true)
      try {
        const offset = (pageNumber - 1) * limit
        const response = await doFetchList({ limit,offset })

        if (response.range !== null) {
          const recordsCount = response.range.split('/')

          commit('setListCount', Number(recordsCount[1]))
          const list = await response.data

          if (list) {
            commit('setList', humps((list)))
            commit('inProgress', false)

            return humps(list)
          } else
            commit('setList', [])
          commit('inProgress', false)
        }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      }
      finally {
        commit('inProgress', false)
      }
    }

  }

}