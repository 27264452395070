import { doFetchBrandsDistributorsRights, doFetchConnectionDetails, doFetchDistributionContract, doFetchDistributorsForBrands, doFetchOne, doPatchConnection, doPostOne } from '@/services/network_connection.service'
import { snakeCase } from 'lodash'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { getField, updateField } from 'vuex-map-fields'
const snakes = createHumps(snakeCase)

export const networkConnectionOne = {
  namespaced: true,
  state: {
    one: null,
    inProgress: true,
    availableDistributors:[],
    distributionContract:null,
    connectionDetails:null

  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    setConnectionDetails(state, connection) {
      state.connectionDetails = connection
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setAvailableDistributors(state, list) {
      state.availableDistributors = list
    },
    setDistributionContract(state, distribution) {
      state.DistributionContract = distribution
    }
  },
  actions: {
    new({ commit }) {
      commit('inProgress', false)
      commit('setOne', {
        'retailerId': '',
        'retailerName':'',
        'distributorId': '',
        'distributorName': '',
        'brandId': '',
        'brandName':'',
        'status': '',
        'requestedAt': null,
        'answerMessage': '',
        'answer_retailer_status':''
      })
    },
    create: async({ commit, state },{ connectionObj }) => {
      commit('inProgress', true)
      try {
        commit('setOne', connectionObj)
        
        const created = await doPostOne({ connection:snakes(Object.assign({}, state.one)) })

        commit('setOne', humps(created))

        return true
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    fetchOne: async({ commit, state },{ connectionId }) => {
      commit('inProgress', true)
      try {
        const created = await doFetchOne({ connectionId })

        commit('setOne', humps(created[0]))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    //appId,retailerId, brandId, distributorId
    fetchConnectionDetails: async({ commit },{ retailerId, brandId, distributorId,requestedBy }) => {
      commit('inProgress', true)
      try {
        const connection = await doFetchConnectionDetails({ retailerId, brandId, distributorId,requestedBy })

        commit('setConnectionDetails', humps(connection[0]))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    }, 
    changeConnectionStatus: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        const updated = await doPatchConnection({ connection:snakes(Object.assign({}, state.one)) })

        commit('setOne', humps(updated))
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
 
    fetchDistributorListForCountry: async({ commit, state },{ countryIso3, brandId }) => {
    // here will follow the code
    // you need to fetch a retainhub/commerce/service_contract for a service with NAICS = 4243

    },
    fetchDistributorsForBrandAndCountry: async({ commit, state },{ appId, naicsCode,countriesCode,brandId,retailerId }) => {
      commit('inProgress', true)
      try {
        const list = await doFetchDistributorsForBrands({ appId, naicsCode,countriesCode,brandId ,retailerId })

        commit('setAvailableDistributors', humps(list))

        return humps(list)
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },    
    brandsDistributorsRights: async({ commit },{  countryCode,distributorId }) => {
      commit('inProgress', true)
      try {
        const list = await doFetchBrandsDistributorsRights({  distributorId, countryCode })

        return humps(list)
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    fetchDistributionContract: async ({ commit }, { distributorId,brandId,naicsCode,countriesIso3 }) => {
      commit('inProgress', true)
      try {
        const distribution = await doFetchDistributionContract({ distributorId,brandId,naicsCode,countriesIso3  })

        if (distribution) {
          commit('setDistributionContract', humps(distribution))
          commit('inProgress', false)

          return humps(distribution[0])
        }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      }
      finally {
        commit('inProgress', false)
      }
    }
  }

}
