import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest  } = nav
const apiUrl = process.env.VUE_APP_ASHTA_API_URL
const ashtaHubName = 'ashtahub'

export const doPortolioSelect = wrapRequest(({ preselection, nameFilters }) => {
  console.log(`porfolioSelect preselection:${preselection} nameFilters:${nameFilters}`)
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=estimated', ...getHubAuthHeader(ashtaHubName) },
    body: JSON.stringify(
      {
        preselection, name_filters:nameFilters
      })
  }  

  return fetch(`${apiUrl}/rpc/portfolio_select`, requestOptions)
})
