<template>
  <div>
    <v-app>
      <v-row v-if="!isRouterLoaded" align="center" justify="center">
        <lottie-animation
          path="../static/json/lf30_zn9nqvtg.json"
          :speed="2"
          :width="256"
          :height="256"
        />
      </v-row>
      <!-- Layout component -->
      <component :is="currentLayout" v-if="isRouterLoaded">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </component>

      <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
        {{ toast.message }}
        <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">
          {{ $t('common.close') }}</v-btn>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex'
  
import config from './configs'

// Layouts
import defaultLayout from './layouts/DefaultLayout'
import simpleLayout from './layouts/SimpleLayout'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'
import ExecuteLayout from './layouts/ExecuteLayout'
import ConnectionRequestLayout from './layouts/ConnectionRequestLayout'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    simpleLayout,
    authLayout,
    errorLayout,
    ExecuteLayout,
    ConnectionRequestLayout
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },

  computed: {
    ...mapState('app', ['toast']),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    }
  },
  created() {
    //.././lf30_zn9nqvtg.json
  }

}
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
