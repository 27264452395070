import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest } = nav
const retainHubWorkApiUrl = process.env.VUE_APP_RETAINHUB_WORK_API_URL

const retainhubHubName = process.env.VUE_APP_RETAINHUB_NAME

export const doFetchList = wrapRequest(({ channelId,appId,recipientId }) => {
  //
  const requestOptions = {
    method: 'GET',
    headers: { ...getHubAuthHeader(retainhubHubName) }
  }

  return fetch(`${retainHubWorkApiUrl}/chat_post_receipt?channel_id=eq.${channelId}&app_id=eq.${appId}&recipient_id=eq.${recipientId}`, requestOptions)
})

export const doPachChatReceipt = wrapRequest(({ updateProperty,channelId,appId,recipientId }) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Prefer': 'return=representation', ...getHubAuthHeader(retainhubHubName)
    },
    body: JSON.stringify(updateProperty)
  }

  return fetch(`${retainHubWorkApiUrl}/chat_post_receipt?channel_id=eq.${channelId}&app_id=eq.${appId}&recipient_id=eq.${recipientId}`, requestOptions)
})

export const doPostOne = wrapRequest(({ message }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json', 'Prefer': 'return=representation', ...getHubAuthHeader(retainhubHubName) },
    body: JSON.stringify(message)
  }

  return fetch(`${retainHubWorkApiUrl}/chat_post`, requestOptions)
})

// for channel 
export const doFetchChannelList = wrapRequest(({ resourceField,resourceId,appId, nameLike }) => {
  const requestOptions = {
    method: 'GET',
    headers: { ...getHubAuthHeader(retainhubHubName) }
  }

  // let q = ''

  // if (nameLike) {
  //   q = `&name=ilike.*${nameLike}*`
  // }
  const params = { app_id: `eq.${appId}` }

  if (resourceId) params['resource_ids->0->>' + `${resourceField}`] = `eq.${resourceId}`
  //if (filterBySeason) params['metadata->>' + `${seasonType}`] = `ilike.*${filterBySeason}*`
  if (nameLike) params['name'] = `ilike.*${nameLike}*`

  const qs = Object.keys(params).map((key) => key + '=' + params[key]).join('&')

  return fetch(`${retainHubWorkApiUrl}/chat_channel?${qs}`, requestOptions)
})
// for channel 
export const doPostChannelOne = wrapRequest(({ channel }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json', 'Prefer': 'return=representation', ...getHubAuthHeader(retainhubHubName) },
    body: JSON.stringify(channel)
  }

  return fetch(`${retainHubWorkApiUrl}/chat_channel`, requestOptions)
})

export const doFetchProfile = wrapRequest(({ userIds,appId }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=exact',...getHubAuthHeader(retainhubHubName) }
  }

  return fetch(`${retainHubWorkApiUrl}/chat_profile?user_id=in.(${userIds})&app_id=eq.${appId}`, requestOptions)
})

export const doGetChatSummary = wrapRequest(({ appId,filter }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
      'Prefer': 'return=representation', ...getHubAuthHeader(retainhubHubName) 
    },
    body: JSON.stringify({
      app_id:appId,
      specs:filter
    }
    )
  }

  return fetch(`${retainHubWorkApiUrl}/rpc/chat_get_summary`, requestOptions)
})