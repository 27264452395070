import { doFetchChannelList, doFetchList,doFetchProfile,doGetChatSummary } from '@/services/chat_room.service'
import { getHoursMinutesAMPM } from '@/services/util.service'
//import { businessEntityOneModule as businessEntityOne } from 'commerce-vuex'

import humps from 'lodash-humps'

export const chatRoomList = {
  namespaced: true,
  state: {
    list: [],
    channelList: [],
    storeConfigList:[],
    inProgress: false,
    chatMessageReceivedFlag:false,
    chatUnreadMessagesCount:0
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      if (list && list.length) {
        const uniqueDates = []

        const newMsgList =  list.filter((msg) => {
          const dt = msg.createdAt ? msg.createdAt.substring(0,10) : null

          if (dt) {
            if (!uniqueDates.length) {
              uniqueDates.push(dt)
              msg.uniqueDate = msg.createdAt
            } else if (!uniqueDates.includes(dt))
            {
              uniqueDates.push(dt)
              msg.uniqueDate = msg.createdAt
            }
            else
              msg.uniqueDate = null
            
            msg.messageTime = getHoursMinutesAMPM(msg.createdAt)

            return msg
          }          
        })

        state.list = []
        state.list = newMsgList        
      }
      state.list = list
    },
    setChannelList(state, channelList) {
      state.channelList = channelList
    }
  },
  actions: {
    fetchList: async ({ commit }, { appId, channelId,recipientId } ) => {
      commit('inProgress', true)
      const list = await doFetchList( { appId, channelId,recipientId } )

      if (list) {
        const authorIds = list.map((item) => item.author_id)
        const unqauthorIds = [...new Set(authorIds)]

        if (unqauthorIds) {
          const userIds = unqauthorIds.join()
          const userList = await doFetchProfile({ appId, userIds } )

          userList.map((item) => {
            let colorName = '#'

            for (let i = 0; i < 3; i++)
            {  colorName += ('0' + Math.floor(Math.random() * Math.pow(16, 2) / 2).toString(16)).slice(-2)} 
            item.color = colorName

            return item
          })
          const dataList = list.map((item) => {
            let newRec = {}
            let userName = null
            let colorCode = null
                
            for (let j = 0; j < userList.length; j++) {
             
              if (item.author_id === userList[j].user_id) {
                colorCode = userList[j].color
                userName = userList[j].user_full_name
              }
            }
            newRec = Object.assign({}, item, { user_name: userName,color:colorCode })

            return newRec
          })

          commit('setList', humps((dataList)))
          commit('inProgress', false)
  
          return humps((dataList))
        }
       
      } else
      {
        commit('setList', [])
        commit('inProgress', false)

        return  []
      }
     
    },
    setList: async ({ commit }) => {
      commit('setList', [])
      commit('inProgress', false)
    
    },
    fetchChannelList: async ({ commit,dispatch,state }, { appId,resourceId,resourceField, nameLike,recipientId } ) => {
      commit('inProgress', true)
      try {
        const list = await doFetchChannelList( { appId,resourceId,resourceField, nameLike } )

        if (list && list.length) {
          const channelIds = list.map((item) => item.id)
          const unqChannelIds = [...new Set(channelIds)]

          //console.log(channelIds)
          //debugger
          const filter = {
            'channel_ids':recipientId ? unqChannelIds : [],
            'channel_name':null,
            'recipient_ids':unqChannelIds && unqChannelIds.length ? [recipientId] : [],
            'author_ids':[]
          }
          // eslint-disable-next-line no-await-in-loop
          const summaryList = await doGetChatSummary({  appId: appId,filter })

          if (summaryList && summaryList.unread_messages_count > 0) {
            state.chatMessageReceivedFlag = true
            state.chatUnreadMessagesCount = summaryList.unread_messages_count
          } else if (summaryList && summaryList.unread_messages_count === 0) {
            state.chatMessageReceivedFlag = false
            state.chatUnreadMessagesCount = 0
          }

          for (let i = 0; i < list.length ;i++) {
            if (summaryList.channels) {
              for (let j = 0;j < summaryList.channels.length;j++ ) {
                if (summaryList.channels[j].channel_id === list[i].id) {
                  if (summaryList.channels[j].channel_unread_messages_count > 0) {
                    list[i].all_messages_read = false
                    list[i].channel_unread_messages_count = summaryList.channels[j].channel_unread_messages_count
                  } else {
                    list[i].all_messages_read = true
                  }
                // if (result && result.channels[0].channelReadMessagesCount && result.channels[0].channelReadMessagesCount < 0) {
                //   return true
                // }
                }   
              }
            } else {
              list[i].all_messages_read = true 
            }
          }
          // for (let i = 0; i < list.length ;i++) {
          //   const item = list[i]

          //   if (item && item.resource_ids.length) {
          //     if (item.resource_ids[0].retailer_id) {
          //       // eslint-disable-next-line no-await-in-loop
          //       const data =  await dispatch('businessEntityOne/fetchOne', {
          //         businessEntityId: item.resource_ids[0].retailer_id,
          //         appId: appId
          //       }, { root: true })

          //       list[i].retailer_name = data.name
          //     }
          //     if (item.resource_ids[0].distributor_id) {
          //       // eslint-disable-next-line no-await-in-loop
          //       const data =  await dispatch('businessEntityOne/fetchOne', {
          //         businessEntityId: item.resource_ids[0].distributor_id,
          //         appId: appId
          //       }, { root: true })

          //       list[i].distributor_name = data.name
          //     }
          //   }
          // }
          commit('setChannelList', humps((list)))
          commit('inProgress', false)
  
          return humps((list))
        } else
        {
          commit('setChannelList', [])
          commit('inProgress', false)
  
          return  []
        }
      }
      catch (error)
      {
        commit('inProgress', false)
        console.log(error.message)
      }
     
    }
  }
}
