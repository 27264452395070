import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest } = nav
const ashtaApiUrl = process.env.VUE_APP_ASHTA_API_URL

const ashtHubName = process.env.VUE_APP_ASHTA_HUB_NAME
const retainhubCommerceApiUrl = process.env.VUE_APP_RETAINHUB_COMMERCE_API_URL
const asthaApiUrl = process.env.VUE_APP_ASHTA_API_URL

const hubName = process.env.VUE_APP_RETAINHUB_NAME

export const doPostOne = wrapRequest(({ connection }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
      'Prefer': 'return=representation', ...getHubAuthHeader(ashtHubName) },
    body: JSON.stringify(connection)
  }

  return fetch(`${ashtaApiUrl}/network_connection`, requestOptions)
})
export const doFetchConnectionsList = wrapRequest(({ businessEntityId, type }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(ashtHubName) }
  }
  let q = ''

  if (type === 'distributor') {
    q = `distributor_id=eq.${businessEntityId}`
    q = q + '&answer_distributor_status=eq.pending'
    q = q + '&requested_by=eq.retailer'
  }
  if (type === 'retailer') {
    q = `retailer_id=eq.${businessEntityId}`
    q = q + '&answer_retailer_status=eq.pending'
    q = q + '&requested_by=in.(distributor,brand)'
  }
  if (type === 'brand') {
    q = `brand_id=eq.${businessEntityId}`
    q = q + '&answer_retailer_status=eq.pending'
    q = q + '&requested_by=eq.retailer'
  }

  return fetch(`${ashtaApiUrl}/network_connection?${q}&status=eq.pending`, requestOptions)
})

export const doPatchConnection = wrapRequest(({ connection }) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
      'Prefer': 'return=representation', ...getHubAuthHeader(ashtHubName)
    },
    body: JSON.stringify(connection)
  }

  return fetch(`${ashtaApiUrl}/network_connection?id=eq.${connection.id}`, requestOptions)
})

export const doFetchOne = wrapRequest(({ connectionId }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(ashtHubName) }
  }

  return fetch(`${ashtaApiUrl}/network_connection?id=eq.${connectionId}`, requestOptions)
})
export const doFetchConnectionDetails = wrapRequest(({ retailerId, brandId, distributorId,requestedBy  }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(ashtHubName) }
  }
  const params = { }

  if (retailerId) params['retailer_id'] = `eq.${retailerId}`
  if (brandId) params['brand_id'] = `eq.${brandId}`
  if (distributorId) params['distributor_id'] = `eq.${distributorId}`
  if (requestedBy) params['requested_by'] = `eq.${requestedBy}`
  const qs = Object.keys(params).map((key) => key + '=' + params[key]).join('&')

  return fetch(`${ashtaApiUrl}/network_connection?${qs}`, requestOptions)
})
export const doFetchDistributionContract = wrapRequest(({ distributorId,brandId,naicsCode,countriesIso3 }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=exact', ...getHubAuthHeader(hubName) }
  }
 
  return fetch(`${retainhubCommerceApiUrl}/service?naics_code=eq.${naicsCode}&business_entity_id=eq.${distributorId}&countries_iso3=cs.{${countriesIso3}}&brands=cs.{${brandId}}`, requestOptions)
})
export const doFetchDistributorsForBrands = wrapRequest(({ appId, naicsCode,countriesCode,brandId ,retailerId }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
      'Prefer': 'return=representation', ...getHubAuthHeader(ashtHubName) 
    },
    body: JSON.stringify(
      {
        'retailer_id': retailerId,
        'brand_id':brandId,
        'country_iso3':countriesCode
      }
    )
  }
  /* const params = { app_id:`eq.${appId}` }

  if (naicsCode) params['naics_code'] = `eq.${naicsCode}`
  if (countriesCode) params['countries_iso3'] = `cs.{${countriesCode}}`
  if (brandId) params['brands'] = `cs.{${brandId}}`
  // const qs = Object.keys(params).map((key) => key + '=' + params[key]).join('&')

  // qs = `or=(countries_iso3.cs.{${countriesCode}},countries_iso3.is.null)&` + qs 
*/

  return fetch(`${asthaApiUrl}/rpc/retailer_get_distributors_for_brand`, requestOptions)
})
export const doFetchBrandsDistributorsRights = wrapRequest(({ distributorId, countryCode }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', 'Prefer': 'count=exact', ...getHubAuthHeader(ashtHubName) }
  }
 
  return fetch(`${asthaApiUrl}/distributor_brand_rights?distributor_id=eq.${distributorId}&countries_iso3=cs.{${countryCode}}`, requestOptions) //
})
