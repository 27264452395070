<template>
  <div class="d-flex">
    <div
      v-if="storeList.length > 1 &&
        businessEntityOne &&
        (!businessEntitiesShortList || businessEntitiesShortList.length < 1)
      "
      large
      class="ml-2 ml-sm-0 toolbaruser-button d-flex align-center"
      dark
      text
      :icon="$vuetify.breakpoint.xsOnly"
    >
      <!--  @click="handleChange(businessEntitiesShortList[0],'business')"-->
      <v-avatar size="40" class="mr-2">
        <v-img
          class="no-blur-effect"
          :src="`${avatarCdnUrl}/avatar/busines-entity/${businessEntityOne.id}.png`"
          :lazy-src="require('@/assets/business_avatar.jpg')"
        />
      </v-avatar>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mr-1 mr-sm-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ businessEntityOne.name }}
      </div>
      <div
        v-else
        class="mr-1 mr-sm-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ initialName(businessEntityOne.name) }}
      </div>
    </div>
    <!-- storeList.length > 1 && -->
    <v-menu
      v-if=" businessEntityOne && businessEntitiesShortList.length > 0"
      offset-y
      right
      transition="slide-y-transition"
    >
      <template #activator="{ on, value }">
        <v-btn
          large
          class="ml-2 ml-sm-0 toolbaruser-button"
          dark
          text
          :icon="$vuetify.breakpoint.xsOnly"
          v-on="on"
        >
          <v-avatar size="40" class="mr-2 cursor-pointer">
            <v-img
              class="no-blur-effect"
              :src="`${avatarCdnUrl}/avatar/busines-entity/${businessEntityOne.id}.png`"
              :lazy-src="require('@/assets/avatar_retailer.png')"
            />
          </v-avatar>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mr-1 mr-sm-2 caption text-sm-body-1 cursor-pointer font-weight-light grey--text"
          >
            {{ businessEntityOne.name }}
          </div>
          <div
            v-else
            class="mr-1 mr-sm-2 caption text-sm-body-1 cursor-pointer font-weight-light grey--text"
          >
            {{ initialName(businessEntityOne.name) }}
          </div>

          <v-icon v-if="!value" class="grey--text">mdi mdi-chevron-down</v-icon>
          <v-icon v-else class="grey--text">mdi mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <!-- dropdown card -->
      <v-card max-height="600">
        <v-list dense max-width="400">
          <!-- <v-subheader class="pa-2 font-weight-bold">Business List</v-subheader> -->
          <div v-if="businessEntitiesShortList.length > 0">
            <div
              v-for="(item, index) in businessEntitiesShortList"
              :key="index"
            >
              <v-divider
                v-if="index > 0 && index < businessEntitiesShortList.length"
                inset
              ></v-divider>
              <v-list-item @click="handleChange(item, 'business')">
                <v-list-item-content>
                  <div
                    class="mr-1 mr-sm-2 caption text-sm-body-1 cursor-pointer font-weight-light"
                    @click="goToPage(item)"
                  >
                    {{ item.name }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-menu>
    <!-- <div></div> -->
    <div
      v-if="brandOne && (!brandShortList || brandShortList.length < 1)"
      large
      class="ml-2 ml-sm-0 toolbaruser-button d-flex align-center"
      dark
      text
      :icon="$vuetify.breakpoint.xsOnly"
    >
      <!-- @click="handleChange(brandShortList[0],'brand')" -->

      <v-avatar size="40" class="mr-2">
        <v-img
          class="no-blur-effect"
          :src="`${avatarCdnUrl}/avatar/brand/${brandOne.id}.png`"
          :lazy-src="require('@/assets/avatar_brand.png')"
        />
      </v-avatar>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ brandOne.name }}
      </div>
      <div
        v-else
        class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ initialName(brandOne.name) }}
      </div>
    </div>
    <v-menu
      v-if="brandOne && brandShortList.length > 1"
      offset-y
      right
      transition="slide-y-transition"
    >
      <template #activator="{ on, value }">
        <v-btn
          large
          class="ml-2 ml-sm-0 toolbaruser-button"
          dark
          text
          :icon="$vuetify.breakpoint.xsOnly"
          v-on="on"
        >
          <v-avatar size="40" class="mr-2">
            <v-img
              class="no-blur-effect"
              :src="`${avatarCdnUrl}/avatar/brand/${brandOne.id}.png`"
              :lazy-src="require('@/assets/avatar_brand.png')"
            />
          </v-avatar>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
          >
            {{ brandOne.name }}
          </div>
          <div
            v-else
            class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
          >
            {{ initialName(brandOne.name) }}
          </div>
          <v-icon v-if="!value" class="grey--text">mdi mdi-chevron-down</v-icon>
          <v-icon v-else class="grey--text">mdi mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <!-- dropdown card -->
      <v-card max-height="600">
        <v-list dense max-width="400">
          <!-- <v-subheader class="pa-2 font-weight-bold">Business List</v-subheader> -->
          <div v-if="brandShortList.length > 0">
            <div v-for="(item, index) in brandShortList" :key="index">
              <v-divider
                v-if="index > 0 && index < brandShortList.length"
                inset
              ></v-divider>
              <v-list-item @click="handleChange(item, 'brand')">
                <v-list-item-content>
                  <div
                    class="mr-1 mr-sm-2 caption text-sm-body-1 cursor-pointer font-weight-light"
                  >
                    {{ item.name }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-menu>
    <div
      v-if="storeList.length > 1 && storeOne && (!storeShortList || storeShortList.length < 1)"
      large
      class="ml-2 ml-sm-0 d-flex align-center"
      dark
      text
      :icon="$vuetify.breakpoint.xsOnly"
    >
      <!-- @click="handleChange(storeShortList[0],'store')" -->
      <v-avatar v-if="storeOne.name" size="40" class="mr-2">
        <v-img
          class="no-blur-effect"
          :src="`${avatarCdnUrl}/avatar/store/${storeOne.id}.png`"
          :lazy-src="require('@/assets/shop_avatar.jpg')"
        />
      </v-avatar>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ storeOne.name }}
      </div>
      <div
        v-else
        class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
      >
        {{ initialName(storeOne.name) }}
      </div>
    </div>
    <v-menu
      v-if="storeList.length > 1 && storeOne && storeShortList && storeShortList.length > 0"
      offset-y
      right
      transition="slide-y-transition"
    >
      <template #activator="{ on, value }">
        <v-btn
          large
          class="ml-2 ml-sm-0 toolbaruser-button"
          dark
          text
          :icon="$vuetify.breakpoint.xsOnly"
          v-on="on"
        >
          <v-avatar v-if="storeOne.name" size="40" class="mr-2">
            <v-img
              class="no-blur-effect"
              :src="`${avatarCdnUrl}/avatar/store/${storeOne.id}.png`"
              :lazy-src="require('@/assets/shop_avatar.jpg')"
            />
          </v-avatar>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
          >
            {{ storeOne.name }}
          </div>
          <div
            v-else
            class="mr-2 caption text-sm-body-1 font-weight-light grey--text"
          >
            {{ initialName(storeOne.name) }}
          </div>
          <v-icon v-if="!value" class="grey--text">mdi mdi-chevron-down</v-icon>
          <v-icon v-else class="grey--text">mdi mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <!-- dropdown card -->
      <v-card max-height="600">
        <v-list dense max-width="400">
          <!-- <v-subheader class="pa-2 font-weight-bold">Business List</v-subheader> -->
          <div v-if="storeShortList.length > 0">
            <div v-for="(item, index) in storeShortList" :key="index">
              <v-divider
                v-if="index > 0 && index < storeShortList.length"
                inset
              ></v-divider>
              <v-list-item @click="handleChange(item, 'store')">
                <v-list-item-content>
                  <div
                    class="mr-1 mr-sm-2 caption text-sm-body-1 cursor-pointer font-weight-light"
                  >
                    {{ item.name }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar Notifications Component
|---------------------------------------------------------------------
|
| Quickmenu to check out notifications
|
*/
import { mapState } from 'vuex'
const avatarCdnUrl = process.env.VUE_APP_RETAINHUB_CDN_URL

export default {
  data() {
    return {
      avatarCdnUrl
    }
  },
  computed: {
    appId: function () {
      return process.env.VUE_APP_APP_ID
    },
    ...mapState({
      businessEntityOne: (state) => state.portfolioSelection.businessOne,
      storeOne: (state) => state.portfolioSelection.storeOne,
      brandOne: (state) => state.portfolioSelection.brandOne,
      businessEntitiesShortList: (state) =>
        state.portfolioSelection.businessShortList,
      brandShortList: (state) => state.portfolioSelection.brandShortList,
      storeShortList: (state) => state.portfolioSelection.storeShortList,
      storeList: (state) => state.storeList.list

    })
  },
  methods: {
    initialName(str) {
      const checkSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/

      if (checkSpecialChar.test(str)) {
        return str.substr(0, str.indexOf('-'))
      } else if (str.split(/\W+/).length >= 2) {
        return str.substr(0, str.indexOf(' '))
      } else {
        return str
      }
    },
    goToPage(val) {
      console.log('val goToPage', val)
    },
    async handleChange(item, type) {
      if (type === 'business') {
        const b64ps = window.btoa(
          JSON.stringify({
            business_entity_id: item.id
          })
        )
        const r = this.$router.resolve({
          name: 'PurchaseOrderListPage',
          params: { b64ps }
        })

        window.location.assign(r.href)
      } else if (type === 'brand') {
        const b64ps = window.btoa(
          JSON.stringify({
            business_entity_id: item.id,
            brand_id: item.id
          })
        )
        const r = this.$router.resolve({
          name: 'BusinessDashboardPage', // to be BrandDashboardPage when ready
          params: { businessEntityId: item.id, b64ps }
        })

        window.location.assign(r.href)
      } else if (type === 'store') {
        const b64ps = window.btoa(
          JSON.stringify({
            business_entity_id: item.id,
            store_id: item.id
          })
        )
        const r = this.$router.resolve({
          name: 'BusinessDashboardPage', // to be StoreDashboardPage when ready
          params: { businessEntityId: item.id, b64ps }
        })

        window.location.assign(r.href)
      }
    }
  }
}
</script>
<style src="./ToolbarUser.scss" lang="scss" scoped />
