<template>
  <div>
    <!-- divider=">" -->
    <!-- :to="item.to" -->
    <!-- :disabled="item.disabled" -->
    <v-breadcrumbs :items="crumbs" divider="" class="apps-breadcrumb">
      <v-breadcrumbs-item
        v-if="item.to.name === pageName"
        slot="item"
        slot-scope="{ item }"
        class="font-color-4D4E4E comfortaa-regular-font line-height-46 fontsize-24"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </div>
</template>
<script>
const _ = require('lodash')

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      businessOne: (state) => state.businessEntityOne.one,
      psBusinessShortList: (state) => state.portfolioSelection.businessShortList     
    }),
    pageName() {
      return this.$route.name
    },
    crumbs() {
    
      const items = []

      if (this.psBusinessShortList.length > 1) {
        const ancestors = this.getBreadcrumbTrail(this.$route)

        for (const i in ancestors) {
          const item = this.createBreadcrumbItemFromMeta(ancestors[i])

          items.push(item)
        }
      }
      else
      {
        const ancestors = this.getBreadcrumbTrail(this.$route)

        for (const i in ancestors) {
          const item = this.createBreadcrumbItemFromMeta(ancestors[i])
          
          if (item.to.name !== 'PortfolioPage' && item.to.name !== 'BusinessDashboardPage')
          {
            items.push(item)
          }
          
        }
        //const lastElement = ancestors[ancestors.length - 1]
        // const item = this.createBreadcrumbItemFromMeta(lastElement)
        
      //  items.push(item)
      }

      return items
    }
  },
  methods: {
    getBreadcrumbTrail(route) {
      const invTrail = []

      this.collectAncestorsBreadcrumb(route, invTrail)

      return invTrail.reverse()
    },

    collectAncestorsBreadcrumb(route, invTrail) {
      let currentMeta = { label: 'Missing breadcrumb meta' }

      if (route.meta && route.meta.breadcrumb) currentMeta = route.meta.breadcrumb
      currentMeta.routeName = route.name

      invTrail.push(currentMeta)
      if (currentMeta.parent) {
        const parentRoute = this.findRouteWithName(
          this.$router.options.routes,
          currentMeta.parent
        )

        if (parentRoute === null) {
          invTrail.push({
            label: `Missing route with name: ${currentMeta.parent}`
          })
        } else {
          this.collectAncestorsBreadcrumb(parentRoute, invTrail)
        }
      }
    },

    findRouteWithName(inArray, routeName) {
      let found = null

      for (const i in inArray) {
        const iRoute = inArray[i]

        if (routeName === iRoute.name) {
          found = iRoute
          break
        }
        if (iRoute.children && iRoute.children.length > 0) {
          found = this.findRouteWithName(iRoute.children, routeName)
          if (found) {
            break
          }
        }
      }

      return found
    },

    createBreadcrumbItemFromMeta(meta) {
      const item = {}

      if (_.isString(meta.label)) {
        item.text = meta.label
      } else if (_.isFunction(meta.label)) {
        item.text = meta.label(this.$store.state)
      }

      if ((item.text === 'Portfolio' || (this.businessOne && item.text === this.businessOne.name)) && this.psBusinessShortList.length < 1) {
        item.disabled = true
      } else {
        item.disabled = false
      }
      const rparams = {}

      if (meta.routeParams) {
        for (const param in meta.routeParams) {
          if (_.isFunction(meta.routeParams[param])) {
            rparams[param] = meta.routeParams[param](this.$store.state)
          }
        }
      }
      const resolved = this.$router.resolve({
        name: meta.routeName,
        params: rparams
      })

      if (resolved.route) {
        item.to = { name: resolved.route.name }
      }

      return item
    }
  }
}
</script>
<style lang='scss'>
.apps-breadcrumb {
  & a.v-breadcrumbs__item, 
  & .theme--dark.v-breadcrumbs .v-breadcrumbs__divider {
    color: black !important;
    font-weight: 100 ;
  }
  & a.v-breadcrumbs__item--disabled {
    color: black !important;
    font-weight: bold !important;
  }
  &.v-breadcrumbs{
    padding: 12px 12px 12px 12px !important;
  }
  
}
</style>
