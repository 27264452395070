import { doFetchList } from '@/services/activity.service'
import { getActivityLabel,checkActivityResourceDetails } from '@/services/util.service'
import humps from 'lodash-humps'

export const activityList = {
  namespaced: true,
  state: {
    list: [],
    allActivity:[],
    newActivity:null,
    inProgress: false
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    /* eslint-disable */
    setList(state, list) {
     let deletedNoteList=[]
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if(item.action === 'D' && item.entity==='note'){
          deletedNoteList.push(item)
        }
        if(deletedNoteList && deletedNoteList.length){
          for(let j=0; j < deletedNoteList.length; j++){
           if(item && item.newData && deletedNoteList[j].oldData.id === item.newData.id && item.entity === 'note')
           item.noteIsDeleted=true
          }
         }
        item.label = getActivityLabel(item)
        //state.list.push(item)
      }
      state.list = list
      state.allActivity=list
      // if(state.newActivity){
      //   if(state.list.length > 0){
      //     if(checkActivityResourceDetails(state.list,state.newActivity)){
      //       state.list.unshift(state.newActivity)
      //     }
      //     }else{
      //       state.list.unshift(state.newActivity) 
      //     }
      // }
    },
    setActivityList(state,type) {
      if(type === 'note'){
        state.list = state.allActivity.filter((item)=> item.entity === 'note' && item.action==='I' && !item.noteIsDeleted)
      }else{
        state.list = state.allActivity    //.slice(0, 3);
      }        
    },
    updateList(state, activityObj) {
      state.newActivity=activityObj

      if(state.list.length > 0){
      if(checkActivityResourceDetails(state.list,activityObj)){
      state.list.unshift(activityObj)
      }
      }else{
        state.list.unshift(activityObj) 
      }
    }
  },
  actions: {
    fetchList: async ({ commit }, {entity, lineageFilter,type}) => {

      commit('inProgress', true)

      const list = await doFetchList({entity, lineageFilter})

      if (list.length) {
        commit('setList', humps((list)))
        commit('setActivityList',type)
      } else
        commit('setList', [])
      commit('inProgress', false)
    },
    updateActivityList: async ({ commit }, activityObj) => {
        commit('updateList', humps(activityObj))
    },
    activityListByType: async ({ commit }, type) => {
      commit('setActivityList',type)
  }
  }
}