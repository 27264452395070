import Vue from 'vue'
import { format } from 'fecha'
import store from '../store'

Vue.filter('formatDate', (value, filterFormat) => {
  const { zone, format } = store.state.app.time

  if (value) {
    format(value, filterFormat || format || 'lll')
  }

  return ''
})
