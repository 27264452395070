import { doChangeConnection, doChangePassword, doFetchList } from '@/services/marketplace_connection.service'
import humps from 'lodash-humps'

export const marketplaceConnectionsList = {
  namespaced: true,
  state: {
    list: [],
    inProgress: false,
    passwordInProgress: false,
    connectionInProgress: false

  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    passwordInProgress(state, yesOrNo) {
      state.passwordInProgress = yesOrNo
    },
    connectionInProgress(state, yesOrNo) {
      state.connectionInProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    }
  },
  actions: {
    fetchList: async ({ commit }, { businessEntityId, appId } ) => {

      commit('inProgress', true)
      const list = await doFetchList( { businessEntityId, appId } )
     
      if (list) {
        commit('setList', humps((list)))
      } else
        commit('setList', [])
      commit('inProgress', false)
    },
    changePassword: async ({ commit }, { userId,newPass } ) => {
      commit('passwordInProgress', true)
      const response = await doChangePassword({ userId,newPass })
     
      if (response) {
        console.log(response)
        commit('passwordInProgress', false)

        return true

      } else
      {
        commit('passwordInProgress', false)

        return ''
      }
    },
    changeConnection: async ({ commit }, { userId,enabledFlag } ) => {
      commit('connectionInProgress', true)
      const response = await doChangeConnection({ userId,enabledFlag })
         
      if (response) {
        console.log(response)
        commit('connectionInProgress', false)

        return true

      } else {
        commit('connectionInProgress', false)

        return ''
      }
    }
  }
}