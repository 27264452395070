<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template #activator="{ on, value }">
      <v-btn
        large
        class="ml-2 ml-sm-0 toolbaruser-button"
        dark
        text
        :icon="$vuetify.breakpoint.xsOnly"
        color="primary"
        v-on="on"
      >

        <v-avatar size="40" class="mr-1">
          <v-img
            class="no-blur-effect"
            :src="userAvatar?userAvatar:require('@/assets/avatar.png')"                
            :lazy-src="require('@/assets/avatar.png')"
          ></v-img>
        </v-avatar>
        
        <!--<span
          class="d-none d-sm-flex"
        >{{ userAuth.first_name }}
        </span>-->
        <v-icon v-if="!value">mdi mdi-chevron-down</v-icon>
        <v-icon v-else>mdi mdi-chevron-up</v-icon>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <!-- <v-list-item @click="navigateToProfile( userAuth.id)">
        <v-list-item-icon>
          <v-icon small>mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.myProfile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->
      <!-- || isBusinessOwnerManager"> -->
      <!--<div v-if="isPlatformManager"> 
        <v-divider class="my-1"></v-divider>
        <v-list-item @click="navigateToAccount()">
          <v-list-item-icon>
            <v-icon small>mdi-badge-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.myAccount') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-divider class="my-1"></v-divider>-->

      <v-list-item @click="goToProfilePage()">
        <v-list-item-icon>
          <v-icon small>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >           
            {{ userAuth.first_name }} {{ userAuth.last_name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logout()">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import config from '../../configs'
const loginUrl = process.env.VUE_APP_LOGIN_URL

import permissionsMixin from '@/mixins/permissionsMixin'
import eventBus from '@/pages/EventBus.vue'
// import userProfileViewPageVue from '../../pages/users/userProfileViewPage.vue'

/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  mixins: [permissionsMixin],

  data() {
    return {
      menu: config.toolbar.user
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.userAuth
    },
    userProfile() {
      return this.$store.state.userProfileOne.one
    },
    userAvatar() {
      if (this.userProfile && this.userProfile.avatarUrl)
        return this.userProfile.avatarUrl
      else
        return false //'/images/avatars/avatar1.svg'
    },
    /* portfolioSelectionObj: function() {
      return this.$store.state.portfolioSelection.ps
    },*/
    portfolioSelection: function () {
      return this.$route.params.b64ps
    }
  },
  created() {
    if (this.userAuth && this.userAuth.id)
      this.$store
        .dispatch('userProfileOne/fetchOne', {
          userProfileId: this.userAuth.id,
          recursive: true
        })
  },
  methods: {
    async logout () {
      eventBus.$emit('logoutConnectionCalled')
      await this.$store.dispatch('auth/logout')
      window.location.href = loginUrl
    },
    navigateToProfile(userId)
    {

      this.$router.push({
        name: 'UserOpsPage',
        params: { userId: userId
        }
      })
    },
    navigateToAccount()
    {

      this.$router.push({
        name: 'AshtaAccountPage',
        params: { b64ps: this.portfolioSelection
        }
      })

    },
    goToProfilePage() {
      this.$router.push({
        name:'UserProfileViewPage',
        params: { b64ps: this.portfolioSelection }
      })
    }

  }
}
</script>
<style src="./ToolbarUser.scss" lang="scss" scoped />
