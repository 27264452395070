<template>
  <div v-if="!isChalhoubBusiness && userSubscriptionDetail && !['free_user_plan',null].includes(userSubscriptionDetail.activePlan)" class="w-100">
    <v-col v-if="userSubscriptionDetail && userSubscriptionDetail.creditCardError && ['past_due','unpaid'].includes(userSubscriptionDetail.status)" cols="12" style="background: #DA2C2199;" class="py-0 mb-1 text-center">
      <span class="my-1 comfortaa-bold-font fontsize-12 line-height-46 ">
        {{ userSubscriptionDetail.creditCardError }} 
        <a class="font-color-3d88a1" @click="goToBillingPage"> Update your payment information </a>
      </span>
    </v-col>
    <v-col v-if="userSubscriptionDetail && userSubscriptionDetail.subscriptionError" cols="12" style="background: #DA2C2199;" class="py-0 mb-1 text-center">
      <span class="my-1 comfortaa-bold-font fontsize-12 line-height-46 ">
        {{ userSubscriptionDetail.subscriptionError }} <a class="font-color-3d88a1" @click="goToBillingPage"> Update your payment information </a>
      </span>
    </v-col>
    <v-col v-if="userSubscriptionDetail && userSubscriptionDetail.status == 'trialing' && userSubscriptionDetail.totalPaymentMethods == 0" cols="12" style="background: #A7D1684D;" class="py-0 text-center">
      {{ userSubscriptionDetail.activePlan }}
      <span class="my-1 comfortaa-bold-font fontsize-12 line-height-46 ">
        You are on a 14 days free trial. To activate your subscription & avoid losing any information after the free trial is over, add your payment details <a class="font-color-00ADE7" @click="goToBillingPage"> here. </a>
      </span>
    </v-col>
    <v-col v-else-if="userSubscriptionDetail && userSubscriptionDetail.status == 'past_due'" cols="12" style="background: #DA2C2199;" class="py-0 text-center">
      <span class="my-1 comfortaa-bold-font fontsize-12 line-height-46">
        Your 14 day free trial is over, please update immediately your payment details to avoid losing any data. <a class="font-color-3d88a1" @click="goToBillingPage"> Update my payment information. </a>
      </span>
    </v-col>
    <v-col v-else-if="userSubscriptionDetail && userSubscriptionDetail.status == 'unpaid'" cols="12" style="background: #DA2C2199;" class="py-0 text-center">
      <span class="my-1 comfortaa-bold-font fontsize-12 line-height-46">
        Your 14 day free trial is over and your account has been suspended. To activate your account please urgently <a class="font-color-3d88a1" @click="goToBillingPage"> Update your payment information. </a>
      </span>
    </v-col>
  </div>
</template>
<script>
export default {
  name: 'TrialPeriodNotification',
  data: () => {
    return {
      trialPeriodIsOn : true
    }
  },
  computed:{
    portfolioSelection: function () {
      return this.$route.params.b64ps
    },
    psBusinessEntityOne: function () {
      return this.$store.state.portfolioSelection.businessOne
    }, 
    userSubscriptionDetail: function () {
      return this.$store.state.portfolioSelection.userSubscriptionDetail
    },
    businessEntityOne() {
      return this.$store.state.businessEntityOne.one
    },
    isChalhoubBusiness: function ()  {
      if (this.businessEntityOne && this.businessEntityOne.metadata && this.businessEntityOne.metadata.enterpriseProfile === 'Challoub_Level_v0') {
        return true
      }

      return false
    }
  },
  methods:{
    goToBillingPage() {
      this.$router.push({
        path:'/portfolio/business/' + this.psBusinessEntityOne.id + '/config/ps/' + this.portfolioSelection + '/#billing' 
      })
    }
  }
}
</script>
<style>
 .font-color-3d88a1{
   color: #3d88a1 !important;
 }
</style>
