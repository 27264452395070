import { auth } from 'oak-vuex'
import { beforeEachRoute } from '@/services/util.service'
import Vue from 'vue'
import Router from 'vue-router'
import AshtaRoutes from './ashta.routes'
// Routes
import GateRoutes from './gate.routes'
const { getAuthUser } = auth
//const ashtaSiteHomeUrl = process.env.VUE_APP_ASHTA_SITE_HOME_URL

Vue.use(Router)

export const routes = [
  {
    path: '/',
    redirect:() => {
      if (getAuthUser())
      {
        return '/home/ps/:b64ps?'
      }
      else
      {
        return '/gate/signin'
      }
    }
  },
  ...GateRoutes,
  ...AshtaRoutes,

  {
    path: '/site/blank',
    name: 'blank',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/BlankPage.vue')
  },
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeResolve(beforeEachRoute)

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
