<template>
  <div
    v-shortkey="['ctrl', '/']"
    v-resize="resizeView"
    class="d-flex flex-grow-1"
    @shortkey="onKeyup"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      :mini-variant.sync="mini"
      :permanent="navigationDrawerVisibility"
      :width="navigationDrawerWidth"
      class="elevation-1 background-color-4D4E4E"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      dark
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2 mb-8">
          <div
            :class="
              !mini
                ? 'd-flex align-center justify-center justify-space-between'
                : 'd-flex align-center justify-center'
            "
          >
            <!--<div
              v-if="!mini"
              class="title font-weight-bold text-uppercase primary--text"
            >
              {{ product.name }}
            </div>-->
            <v-img
              v-if="!mini"
              contain
              dark
              aspect-ratio="2"
              class="ml-n1"
              max-width="130"
              :src="require('@/assets/ashta-white.png')"
            ></v-img>
            <!-- <div
              v-else
              class="title font-weight-bold text-uppercase primary--text text-center"
            >
              {{ product.name.charAt(0) }}
            </div>-->
            <div v-if="!mini">
              <v-btn
                class="float-right d-none d-sm-flex navigation-drawer-button"
                icon
                @click.stop="mini = !mini"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                class="float-right d-flex d-sm-none navigation-drawer-button"
                icon
                @click.stop="drawer = !drawer"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>
            <v-app-bar-nav-icon
              v-else
              class="d-none d-sm-flex navigation-drawer-button"
              @click.stop="mini = !mini"
            ></v-app-bar-nav-icon>
          </div>
          <v-img
            v-if="mini"
            contain
            class="mt-1 mx-auto"
            max-width="50"
            :src="require('@/assets/ashta-blue-symbol.png')"
          ></v-img>

          <div v-if="!mini" class="text-caption grey--text">
            {{ product.version }}
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :mini-flag="mini" :chat-message-received-flag="chatMessageReceivedFlag" :chat-unread-messages-count="chatUnreadMessagesCount"/>
      <!-- Navigation menu footer -->
      <template #append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <!-- <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            :href="item.href"
            :target="item.target"
            small
            text
          >
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn> -->
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
      class="header"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <!-- uncomment below and commentout above -->
            <!-- <div v-else class="d-flex flex-grow-1 align-center"> -->

            <v-app-bar-nav-icon
              class="d-flex d-sm-none"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <ToolbarPortfolioSelection
              v-if="$vuetify.breakpoint.smAndUp"
              class="grey--text"
            ></ToolbarPortfolioSelection>
            <v-spacer class="d-lg-block"></v-spacer>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications
                :business-entity-id="businessEntityId"
                :notification-received-flag="notificationReceivedFlag"
                @go-to-notifications="goToNotifications"
                @notification-received="notificationReceivedFlag = false"
              />
              <!--@go-to-notifications="goToNotifications"-->
            </div>
            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main class="position-relative po-page-main">
      <TrialPeriodNotification
        v-show="$vuetify.breakpoint.xsOnly"
        class="subscription-errors-messages"
      />
      <v-app-bar
        v-if="showPageRouteBreadcrumb"
        v-show="$vuetify.breakpoint.smAndUp"
        color="#f2f5f8"
        app
        fixed
        class="mt-sm-7 mb-2 mt-md-8 elevation-0"
      >
        <v-row class="position-relative" no-gutters >
          <TrialPeriodNotification class="subscription-errors-messages"/>
          <v-col class="" :cols="showMiniRoute ? 7 : 12">
            <breadcrumb class="grey--text"></breadcrumb>
          </v-col>
          <v-col v-if="showMiniRoute" cols="5" class="mt-1 text-right">
            <!-- <BoardSwitchControl> </BoardSwitchControl> -->
          </v-col>
        </v-row>
      </v-app-bar>
      <!--<v-row v-show="$vuetify.breakpoint.smAndUp">
        <v-col :cols="showMiniRoute?8:12" >
          <breadcrumb class="grey--text"></breadcrumb>
        </v-col>
        <v-col v-if="showMiniRoute" cols="4" class="mt-1 text-right ">
          <BoardSwitchControl>
          </BoardSwitchControl>
        </v-col>
      </v-row >-->
      <v-container class="fill-height mt-sm-4 mt-md-3" :fluid="!isContentBoxed">
        <v-layout class="pt-3">
          <slot></slot>
        </v-layout>
      </v-container>

      <v-footer app inset>
        <v-spacer></v-spacer>
        <div class="overline">
          Fait avec <v-icon small color="pink">mdi-heart</v-icon>
        </div>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const avatarCdnUrl = process.env.VUE_APP_RETAINHUB_CDN_URL

// navigation menu configurations
import config from '../configs'

import MainMenu from '../components/navigation/MainMenu'
import ToolbarUser from '../components/toolbar/ToolbarUser'
//import ToolbarConnections from '../components/toolbar/ToolbarConnections'
//ToolbarNotifications
import ToolbarNotifications from '../components/toolbar/ToolbarNotifications'
import ToolbarPortfolioSelection from '../components/toolbar/ToolbarPortfolioSelection'
// import ToolbarCurrency from '../components/toolbar/ToolbarCurrency'
import Breadcrumb from '../components/common/Breadcrumb'
//import BoardSwitchControl from '@/components/common/BoardSwitchControl'
import permissionsMixin from '@/mixins/permissionsMixin'
import TrialPeriodNotification from '@/components/common/TrialPeriodNotification'
import eventBus from '@/pages/EventBus.vue'

import WebSocketClient from '@/services/WebSocketClient'
//import { auth } from 'oak-vuex'
//const { getHubAuthHeader } = auth
//const oakhubHubName = process.env.VUE_APP_OAK_HUB_NAME

export default {
  components: {
    MainMenu,
    ToolbarUser,
    //ToolbarConnections,
    ToolbarNotifications,
    ToolbarPortfolioSelection,
    // ToolbarCurrency,
    Breadcrumb,
    //BoardSwitchControl,
    TrialPeriodNotification
  },
  mixins: [permissionsMixin],
  data() {
    return {
      notificationReceivedFlag:false,
      //chatMessageReceivedFlag:false,
      //oakhubHubName:oakhubHubName,
      avatarCdnUrl: avatarCdnUrl,
      drawer: null,
      showSearch: false,
      mini: false,
      connection: null,
      navigation: config.navigation
    }
  },
  computed: {
    userId: function () {
      return this.$store.state.auth.userAuth.id
    },
    navigationDrawerWidth: function () {
      return this.$vuetify.breakpoint.sm
        ? '170'
        : this.$vuetify.breakpoint.md
          ? '200'
          : this.$vuetify.breakpoint.lgAndUp
            ? '245'
            : '245'
    },
    navigationDrawerVisibility: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : false
    },
    ...mapState({
      psBusinessOne: (state) => state.portfolioSelection.businessOne
    }),
    ...mapState('app', [
      'product',
      'isContentBoxed',
      'menuTheme',
      'toolbarTheme',
      'isToolbarDetached',
      'appId',
      'toast',
      'oakToken'
    ]),
    userLanguage() {
      return this.$store.state.userOne.userProfile
    },
    portfolioSelectionObj: function () {
      return this.$store.state.portfolioSelection.businessOne
    },
    businessEntityId: function () {
      if (this.$store.state.portfolioSelection.businessOne) {
        return this.$store.state.portfolioSelection.businessOne.id
      } else {
        return null
      }
    },
    portfolioSelection: function () {
      return this.$route.params.b64ps
    },
    showMiniRoute: function () {
      if (!this.$route.path.includes('directory')) {
        return this.$store.state.global.showMiniRoute
      }

      return false
    },
    showPageRouteBreadcrumb: function () {
      return this.$store.state.global.showPageRouteBreadcrumb
    },
    ...mapState({
      one: (state) => state.businessEntityOne.one,
      storeOne: (state) => state.storeOne.one,
      psBusinessShortList: (state) =>
        state.portfolioSelection.businessShortList,
      purchaseOrderList: (state) =>
        state.purchaseOrderList.purchaseOrdersWithBrands,
      salesOrderList: (state) => state.salesOrderList.wholesaleOrdersWithBrands
      //showMiniRoute: (state) => state.global.showMiniRoute
    }),
    businessEntityAvatarUrl: function () {
      return this.one && this.one.avatarUrl
        ? this.one.avatarUrl
        : require('@/assets/business_avatar.jpg')
    },
    storeAvatarUrl: function () {
      return require('@/assets/shop_avatar.jpg')
    },
    webSocketUrl: function () {
      return process.env.VUE_APP_WEBSOCKET_URL
    },
    currentPage: function() {
      return this.$route.name
    },
    chatMessageReceivedFlag: function () {
      return this.$store.state.chatRoomList.chatMessageReceivedFlag
    },
    chatUnreadMessagesCount: function () {
      return this.$store.state.chatRoomList.chatUnreadMessagesCount
    }
  },
  watch: {
    currentPage() {
      if (this.currentPage === 'ChatChannelPage') {
        //this.chatMessageReceivedFlag = false
      }
    },
    businessEntityId: {
      immediate: true,
      handler() {
        if (this.businessEntityId) {
          //this.networkConnectionsList()
          this.setSideBarNavigation()
          this.storeList()
          this.getChatMessages()
        }
      }
    },
    $route(to, from) {
      //  this.$router.para
      this.setSideBarNavigation()
      //this.navigation = config.navigation({ routeParams:this.$route.params,user:this.$store.state.auth.userAuth  })
    }
  },
  async mounted() {
    this.setSideBarNavigation()
    //this.navigation = config.navigation({ routeParams:this.$route.params,user:this.$store.state.auth.userAuth })

    /***********************************WebSocket********************************** */

    // const oakToken = this.oakToken.Authorization.replace('Bearer ','')

    // console.log('Starting connection to WebSocket Server', this.webSocketUrl + '?userId=' + this.userId + '&appId=' + this.appId + '&oakToken=' + oakToken)
    // this.connection = await new WebSocket(this.webSocketUrl + '?userId=' + this.userId + '&appId=' + this.appId  + '&oakToken=' + oakToken)
    // console.log('Starting connection to WebSocket Server', this.connection)

    // this.connection.onmessage = (event) => {
    //   //console.log(event)
    //   //action data

    //   const  value  = JSON.parse(event.data)

    //   if (value && value.action === 'note') {
    //     debugger
    //     vm.messageContent = value
    //     vm.doPatchNote()
    //             console.log('value',vm.messageContent)

    //   }
    // }
    // console.log('onmessage data',vm.messageContent)
    // this.connection.onopen = function(event) {
    //   console.log(event)
    //   console.log('Successfully connected to the echo websocket server...')
    // }
    // this.connection.onclose = function(e) {
    //   console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
    //   setTimeout(() => {
    //     this.connection.connect()
    //   }, 1000)
    // }

    // this.connection.onerror = function(err) {
    //   console.error('Socket encountered error: ', err.message, 'Closing socket')
    //   this.connection.close()
    // }
    // eventBus.$on('logoutConnectionCalled', () => {
    //   this.connection.close()
    //   console.log('Successfully closeed to the websocket server...')
    // })
  },
  created() {
    try {
      const oakToken = this.oakToken.Authorization.replace('Bearer ', '')
      const socketClient = new WebSocketClient(
        this.webSocketUrl +
        '?userId=' +
        this.userId +
        '&appId=' +
        this.appId +
        '&oakToken=' +
        oakToken,
        {}
      )

      socketClient.connect()

      socketClient.onOpen = () => {
        console.log('socket connected')
      }
      socketClient.onMessage = (msg) => {
        const value = JSON.parse(msg.data)

        console.log('data',value)
        if (value && value.action === 'activity-added') {
          this.fetchActivity(value.data)
          //window.location.reload() 
        }
        if (value && value.action === 'order_processed') {
          this.fetchOrderDetails(value.data)
          //window.location.reload() 
        }
        // if (value && value.data && value.data.body && value.data.body.notification_type === 'document_processed') {
        //   window.location.reload() 
        // }
        if (value && value.action === 'chat_channel-updated') {
          this.fetchChat(value.data)
        }
        if (value && (value.action === 'chat' || value.action === 'connection')) {
          this.fetchChat(value.data)
          if (this.$store.state.auth.userAuth) {
            this.$store.dispatch(
              'dashboard/fetchUserState',
              this.$store.state.auth.userAuth.id
            )
          }
        }
      }
      socketClient.onClose = (e) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
        setTimeout(() => {
          socketClient.connect()
        }, 1000)
      }
      socketClient.onError = (err) => {
        console.error('Socket encountered error: ', err.message, 'Closing socket')
      }
      eventBus.$on('logoutConnectionCalled', () => {
        socketClient.close()
        console.log('Successfully closeed to the websocket server...')
      })
      /*if (this.userId)
    {
       
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        'event':'app_access',
        'data':{ 'user_id':  this.userId }
      })
    }*/

      //for portfolio Selection
      if (this.$route.params.b64ps) {
        this.$store.dispatch(
          'portfolioSelection/fetchFromRoute',
          this.$route.params.b64ps
        )
      } else {
        this.$store.dispatch('portfolioSelection/fetchDefault', this.$router)
      }

      if (this.$store.state.auth.userAuth) {
        this.$store.dispatch(
          'dashboard/fetchUserState',
          this.$store.state.auth.userAuth.id
        )
      }
      if (this.businessEntityId) {
      
        //this.networkConnectionsList()
      }
      /* this.$store.dispatch(
      'brandRetailerConnectionList/fetchConnectionList'
    )*/
      // this.setSideBarNavigation()

      //await this.$store.dispatch('retainhubContext/fetchTeamsForUser', { appId:this.appId })

    /* if (this.$store.state.auth.userAuth) {
      this.$store.dispatch(
        'userOne/fetchUserState',
        this.$store.state.auth.userAuth.id
      )
      this.$store.dispatch(
        'notificationList/fetchList',
        this.$store.state.auth.userAuth.id
      )
      this.$store.dispatch('userOne/fetchUserProfile', { userId:this.$store.state.auth.userAuth.id })

    }*/
    } catch (error) {
      this.toast.show = true
      this.toast.message = error.message
      this.toast.color = 'error'
      throw error
    }
  },

  methods: {
    async getChatMessages() {
      if (this.psBusinessOne)
        this.$store.dispatch(
          'chatRoomList/fetchChannelList',
          {
            appId: this.appId,
            resourceField:this.psBusinessOne && this.psBusinessOne.isRetailer ? 'retailer_id' : 'distributor_id',
            resourceId: this.businessEntityId,
            recipientId:this.$store.state.auth.userAuth.id
          }
        )
    },
    fetchOrderDetails(data) {
      try {
        this.notificationReceivedFlag = true
        eventBus.$emit('doFetchOrderDetails',data)
        // this.$store.dispatch("activityList/updateActivityList", activity)
        // const lineageFilter = { 'resource_owner_id': this.businessEntityId }
        // this.$store.dispatch('activityList/fetchList', { entity: this.entity, lineageFilter:lineageFilter })
    
      }
      catch (error) {
        this.toast.show = true
        this.toast.message = error.message
        this.toast.color = 'error'
        throw error
      }
    },
    async fetchActivity(activity) {
      try {
        this.notificationReceivedFlag = true
        eventBus.$emit('doFetchActivity',activity)
        // this.$store.dispatch("activityList/updateActivityList", activity)
        // const lineageFilter = { 'resource_owner_id': this.businessEntityId }
        // this.$store.dispatch('activityList/fetchList', { entity: this.entity, lineageFilter:lineageFilter })
    
      }
      catch (error) {
        this.toast.show = true
        this.toast.message = error.message
        this.toast.color = 'error'
        throw error
      }
    },
    async fetchChat(chat) {
      try {
        this.notificationReceivedFlag = true
        // if (this.currentPage !== 'ChatChannelPage') {
        //   this.chatMessageReceivedFlag = true
        // }
        this.getChatMessages()
        eventBus.$emit('doFetchChat')
        // this.$store.dispatch("activityList/updateActivityList", activity)
        // const lineageFilter = { 'resource_owner_id': this.businessEntityId }
        // this.$store.dispatch('activityList/fetchList', { entity: this.entity, lineageFilter:lineageFilter })
    
      }
      catch (error) {
        this.toast.show = true
        this.toast.message = error.message
        this.toast.color = 'error'
        throw error
      }
    },
    goToNotifications() {
      this.$router
        .push({
          name: 'NotificationsPage',
          params: {
            businessEntityId: this.businessEntityId,
            b64ps: this.portfolioSelection
          }
        })
        .catch((err) => err)
    },
    storeList() {
      this.$store.dispatch('storeList/fetchList', {
        businessEntityId: this.businessEntityId,
        appId: this.appId
      })
    },
    networkConnectionsList() {
      if (this.portfolioSelectionObj) {
        if (this.portfolioSelectionObj.isRetailer) {
          this.$store.dispatch('networkConnectionsList/fetchConnectionsList', {
            businessEntityId: this.portfolioSelectionObj.id,
            appId: this.appId,
            type: 'retailer'
          })
        }
        if (this.portfolioSelectionObj.isDistributor) {
          this.$store.dispatch('networkConnectionsList/fetchConnectionsList', {
            businessEntityId: this.portfolioSelectionObj.id,
            appId: this.appId,
            type: 'distributor'
          })
        }
        if (this.portfolioSelectionObj.isBrand_owner) {
          this.$store.dispatch('networkConnectionsList/fetchConnectionsList', {
            businessEntityId: this.portfolioSelectionObj.id,
            appId: this.appId,
            type: 'brand'
          })
        }
      }
    },
    resizeView() {
      if (!this.$vuetify.breakpoint.smAndUp) {
        this.mini = false
      }
    },
    async setSideBarNavigation() {
      try {
        // console.log('this.businessEntityId',this.businessEntityId)
        if (this.businessEntityId) {
          await this.$store.dispatch('businessEntityOne/fetchOne', {
            businessEntityId: this.businessEntityId,
            appId: this.appId
          })

          // console.log('this.$store.state.businessEntityOne.one',this.$store.state.businessEntityOne.one)
          this.navigation = config.navigation({
            dirBrandOne: this.$store.state.directorySelection.dirBrandOne,
            dirBusinessEntityOne:
              this.$store.state.directorySelection.dirBusinessEntityOne,
            dirStoreOne: this.$store.state.directorySelection.dirStoreOne,
            businessEntityOne: this.$store.state.businessEntityOne.one,
            routeParams: this.$route.params,
            user: this.$store.state.auth.userAuth,
            businessEntityId: this.businessEntityId
          })
        } else {
          this.navigation = config.navigation({
            dirBrandOne: this.$store.state.directorySelection.dirBrandOne,
            dirBusinessEntityOne:
              this.$store.state.directorySelection.dirBusinessEntityOne,
            dirStoreOne: this.$store.state.directorySelection.dirStoreOne,
            businessEntityOne: null,
            routeParams: this.$route.params,
            user: this.$store.state.auth.userAuth,
            businessEntityId: null
          })
        }
      } catch (error) {
        this.toast.show = true
        this.toast.message = error.message
        this.toast.color = 'error'
        throw error
      }
    },
    setAltStoreImg(event) {
      event.target.src = require('@/assets/shop_avatar.jpg')
    },
    setAltBusinessImg(event) {
      event.target.src = require('@/assets/business_avatar.jpg')
    },
    onKeyup(e) {
      this.$refs.search.focus()
    }
  }
}
</script>
<style lang="scss">
// horizontal scrollbar for all pages
.v-data-table__wrapper {
  position: relative !important;
}
.subscription-errors-messages{
  position: absolute;
  top: -5px;
}
.position-relative{
  position: relative;
}
</style>
<style src="./DefaultLayout.scss" lang="scss" scoped />