import serviceDirectory from '../../services/directory.service'
import humps from 'lodash-humps'
const { doDirectoryLookup } = serviceDirectory
const appId = process.env.VUE_APP_APP_ID

export const directoryList = {
  namespaced: true,
  state: {
    list: [],
    inProgress: false
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    }
  },
  actions: {
    lookupList: async ({ commit, dispatch }, { nameLike, forBusinessId, scope }) => {
      /*eslint-disable*/
      commit('inProgress', true)
      const list = await doDirectoryLookup({ nameLike, forBusinessId, scope })

      if (list) {
        const unqBrandIds = [...new Set(list.filter((item) => item.type === 'brand').map((item) => item.id))]
        if (unqBrandIds.length > 0) {
          const ids = unqBrandIds.join()

          let brandList = await dispatch('brandList/fetchBrandListByIds', {
            brandIds: ids,
            appId: appId,
            pageNumber: 1,
            limit: unqBrandIds.length
          }, { root: true })
          const dataList = list.map((item) => {
            let newRec = {}
            let descriptionList = []
            brandList.forEach((val)=>{ 
            if(item.type === 'brand' && item.id === val.id && val.description !== '')
             {descriptionList.push(val.description)}
            })
            newRec = Object.assign({}, item, { description: descriptionList.length > 0 ? descriptionList.find((item) => item) : null })
            return newRec
          })
          commit('setList', humps((dataList)))
        } else {
          commit('setList', humps((list)))
        }
      } else
        commit('setList', [])

      commit('inProgress', false)
    }
  }
}
