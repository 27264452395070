<template>
  <div class="background-color-FFFFFF">
   
    <slot ></slot>

  </div>
</template>
<script>
//import Header from './Header.vue'
//import Footer from './Footer.vue'
export default ({
  components: {
  
  },
  methods:{
  
  }
})
</script>