<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2">
      <div class="mt-3 mt-md-10 pa-2">
        <!--<div class="display-2 font-weight-bold primary--text">
          {{ product.name }}
        </div>-->
        <v-img
          max-width="160"
          contain
          dark
          aspect-ratio="2"
          class="mx-auto"
          :src="require('@/assets/ashta-blue.png')"
        ></v-img>
        <div class="title my-2">{{ $t('greetings.greetings') }}</div>
        <v-btn class="my-4" @click="goToAshtaSiteHomeUrl">{{ $t('common.takeMeBack') }}</v-btn>
      </div>
      <v-img class="w-full" src="/images/illustrations/signin-illustration.svg" />
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto w-full">
        <v-card >
          
        </v-card>
       
        <slot></slot>
      </div>
      <div class="overline mt-4">{{ product.name }} - {{ product.version }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const languages = process.env.VUE_APP_LANGUAGES.split(',')

export default {
  components: {
     
  },
  computed: {
    ...mapState('app', ['product']),
    userLanguage() { 
      return this.$store.state.userOne.userProfile
    },
    ashtaSiteHomeUrl() { 
      return process.env.VUE_APP_ASHTA_SITE_HOME_URL
    }
  },
  created() {
    if (localStorage.getItem('preferredLanguage')) {
      return this.$i18n.locale = localStorage.getItem('preferredLanguage')
    }
    else {
      return this.$i18n.locale = languages[0]
    }
  },
  methods:{
    goToAshtaSiteHomeUrl() {
      location.replace(this.ashtaSiteHomeUrl)
    }
  }
}
</script>

<style scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
