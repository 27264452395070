import configs from '../../configs'
import actions from './actions'
import mutations from './mutations'
import { auth } from 'oak-vuex'
const { getHubAuthHeader } = auth
const oakhubHubName = process.env.VUE_APP_OAK_HUB_NAME

const { product, time, theme, currencies  } = configs

const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL } = theme
const { currency, availableCurrencies } = currencies

// state initial values
const state = {
  appId:'asht',
  product,

  time,

  // currency
  currency,
  availableCurrencies,

  // themes and layout configurations
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isRTL,
  oakToken:{ ...getHubAuthHeader(oakhubHubName) },
  // App.vue main toast
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
