const defaultAppId = process.env.VUE_APP_APP_ID
const beforeEachRoute = function (to,from, next) {
  if (!to.params.appId) {
    to.params.appId = defaultAppId
  }
  next()
}

export default [{
  path: '/gate/signin/:appId?/:invitationId?',
  name: 'auth-signin',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/SigninPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
}, 
{
  path: '/gate/signup/:appId?/:invitationId?',
  name: 'auth-signup',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/SignupPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
},
{
  path: '/gate/verify-email',
  name: 'auth-verify-email',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/VerifyEmailPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
}, {
  path: '/gate/forgot-password/:appId?',
  name: 'auth-forgot',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/ForgotPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
}, 
{
  path: '/gate/accept-invitation/:appId?/:invitationId',
  name: 'auth-accept-invitation',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/AcceptInvitationPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
}, 
{
  path: '/gate/reset-password/:appId?/:userId/:token/:emailId?',
  name: 'auth-reset',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/ResetPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
},
{
  path: '/gate/confirm-user-email/:appId/:userId/:token',
  name: 'ConnfirmUserEmailPage',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/ConfirmUserEmail.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    breadcrumb: { label: 'Email Confirmation' }
  }
},
{
  path: '/gate/set-password/:appId/:userId/:token/:fullName?/:userEmail?/:businessId?',
  name: 'auth-set',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/SetPasswordPage.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
   
},
{
  path: '/gate/oauth',
  name: 'auth-set',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/Oauth.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
   
},
{
  path: '/gate/oauth2',
  name: 'auth-set',
  component: () => import(/* webpackChunkName: "gate" */ '@/pages/gate/Oauth2.vue'),
  beforeEnter : beforeEachRoute,
  meta: {
    layout: 'auth'
  }
   
}
]