<template>
  <div>
    <v-list-item
      v-if="(route && routeParams) || link"
      :to="availableRoute()"
      :exact="exact"
      :disabled="disabled"
      active-class="font-color-A7D168 wirednav-menu-list"
      link
      :ripple="false"
      :class="miniFlag ?'':'mx-n1 px-3'"
      class="wirednav-menu-list"
    >
      <v-tooltip right >
        <template #activator="{ on }">
          <v-list-item-icon class="my-auto" v-on="on">

            <!-- <div v-if="badgeFlag">
              <v-badge
                bordered
                color="error"
                overlap
                dot
              >
                <v-icon :small="small" class="my-auto fontsize-20" :class="{ 'grey--text': disabled }">
                  {{ icon || 'mdi-circle-medium' }}
                </v-icon>
              </v-badge>
            </div> -->
            <!-- v-else -->
            <div>
              <div v-if="text== 'Messages'">
                <v-img :src="require('@/assets/messages.png')" width="25" />
              </div>
              <div v-else>
                <v-icon :small="small" class="my-auto fontsize-20" :class="{ 'grey--text': disabled }">
                  {{ icon || 'mdi-circle-medium' }}
                </v-icon>
              </div>

            </div>
          </v-list-item-icon>
        </template>
        <span class="comfortaa-bold-font fontsize-14 line-height-18">{{ i18nkey ? $t(i18nkey) : text }}</span>
      </v-tooltip>
      <v-list-item-content class="my-auto">
        <v-list-item-title class="comfortaa-bold-font fontsize-14 line-height-18">
          {{ i18nkey ? $t(i18nkey) : text }}
          <!-- <v-icon v-if="text== 'Messages' && badgeFlag" color="red" :small="small" class="my-auto fontsize-20" >
            mdi-circle-medium
          </v-icon> -->
        </v-list-item-title>
      </v-list-item-content>
      <v-badge
        v-if="text== 'Messages' && messageCount >0"
        :content="messageCount"
        color="red"
        class="ml-0 comfortaa-bold-font fontsize-18"
        inline
      ></v-badge>
    </v-list-item>

  </div>
</template>
<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/

export default {
  props: {
    exact: {
      type: Boolean,
      default: false
    },
    i18nkey: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    route: {
      type: String,
      default: null
    },
    routeParams: {
      type: Object,
      default: () => null
    },
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    subgroup: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    businessEntityOne:
    {
      type: Object,
      default: () => null
    },
    miniFlag:{
      type:Boolean,
      default:false
    },
    badgeFlag:{
      type:Boolean,
      default:false
    },
    messageCount:{
      type:Number,
      default:0
    }
  },
  methods: {
    availableRoute() {
      if (this.route !== '' && this.routeParams) {
        return { name: this.route, params:this.routeParams }
      } else {
        return this.link
      }

    }
  }
}
</script>
<style src="./WiredNavMenuItem.scss" lang="scss" scopped/>
