import { auth, nav } from 'oak-vuex'
const { getHubAuthHeader } = auth
const { wrapRequest } = nav
const ashtaApiUrl = process.env.VUE_APP_ASHTA_API_URL

const ashtHubName = process.env.VUE_APP_ASHTA_HUB_NAME

/*export const doFetchList = wrapRequest(({ businessEntityId, appId }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(oakHubName) }
  }
  return fetch(`${retainHubApiUrl}/business_config?business_entity_id=eq.${businessEntityId}&app_id=eq.${appId}`, requestOptions)
})*/

/*export const doFetchList = async() => {
  const data = [
    {
      'name':'Joor',
      'password':'demo1234',
      'status':'green'
    },
    {
      'name':'NuOrder',
      'password':'demo1234',
      'status':'red'
    }  
  ]
  
  return data
}*/
export const doFetchList = wrapRequest(({ businessEntityId }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...getHubAuthHeader(ashtHubName) }
  }

  return fetch(`${ashtaApiUrl}/marketplace_connection?business_entity_id=eq.${businessEntityId}`, requestOptions)
})
export const doPostOne = wrapRequest(({ connection }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
      'Prefer': 'return=representation', ...getHubAuthHeader(ashtHubName) },
    body: JSON.stringify(connection)
  }

  return fetch(`${ashtaApiUrl}/marketplace_connection`, requestOptions)
})
export const doChangePassword = wrapRequest(({ userId, newPass }) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json','Accept': 'application/vnd.pgrst.object+json', 'Prefer': 'return=representation', ...getHubAuthHeader(ashtHubName) 
    },
    body: JSON.stringify({ user_pw:newPass })
  }

  return fetch(`${ashtaApiUrl}/marketplace_connection?id=eq.${userId}`, requestOptions)
})
export const doChangeConnection = wrapRequest(({ userId,enabledFlag }) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json','Accept': 'application/vnd.pgrst.object+json','Prefer': 'return=representation',...getHubAuthHeader(ashtHubName) 
    },
    body: JSON.stringify({ enabled:enabledFlag })
  }

  return fetch(`${ashtaApiUrl}/marketplace_connection?id=eq.${userId}`, requestOptions)
})