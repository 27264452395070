export default [
  {
    path: '/home/ps/:b64ps?',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/home/HomePage.vue'),
    meta: {
      breadcrumb: {
        label: 'Home'
      }
    }
  },
  {
    path: '/maintenance/:disableId?',
    name: 'utility-maintenance',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/utility/MaintenancePage.vue'),
    meta: {
      layout: 'simple'
    }
  },
  {
    path: '/home/ps/:b64ps?',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/home/HomePage.vue'),
    meta: {
      breadcrumb: {
        label: 'Home'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/config/ps/:b64ps',
    name: 'BusinessConfigPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/business/BusinessConfigPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Config',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },

  {
    path: '/config/:businessEntityId/brand_owner/:brandOwnerId/brand/:brandId/edit/ps/:b64ps',
    name: 'BrandEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? 'Edit Brands' : 'New Brands',
        routeParams: { brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId' },
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/config/:businessEntityId/brand_owner/:brandOwnerId/brand/:brandId/ops/ps/:b64ps',
    name: 'BrandOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? state.brandOne.one.name : 'Brand view',
        routeParams: { brandOwnerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'brandOwnerId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId' },
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/config/:businessEntityId/brand_owner/:brandOwnerId/brand/:brandId/brand_linesheet/:brandLinesheetId/ps/:b64ps',
    name: 'BrandLinesheetEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandLinesheetEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandCatalogOne.one && state.brandCatalogOne.one.id ? 'Edit Linesheet' : 'New Linesheet',
        routeParams: { brandOwnerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'brandOwnerId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId', brandLinesheetId: (state) => state.brandCatalogOne.one ? state.brandCatalogOne.one.id : 'BrandLinesheetId' },
        parent: 'BrandOpsPage'
      }
    }
  },
  {
    path: '/config/:businessEntityId/brand_owner/:brandOwnerId/brand/:brandId/brand_linesheet/:brandLinesheetId/ops/ps/:b64ps',
    name: 'BrandLinesheetOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandLinesheetOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandCatalogOne.one && state.brandCatalogOne.one.id ? state.brandCatalogOne.one.name : 'Brands linesheet details',
        routeParams: { brandOwnerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'brandOwnerId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId', brandLinesheetId: (state) => state.brandCatalogOne.one ? state.brandCatalogOne.one.id : 'BrandLinesheetId' },
        parent: 'BrandOpsPage'
      }
    }
  },
  {
    path: '/config/:businessEntityId/brand_owner/:brandOwnerId/brand/:brandId/:brandLinesheetId/brand_style/:brandStyleId/ps/:b64ps',
    name: 'BrandStyleEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandStyleEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandProductOne.one && state.brandProductOne.one.id ? state.brandProductOne.one.name : 'New Style',
        routeParams: { brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId', brandLinesheetId: (state) => state.brandCatalogOne.one ? state.brandCatalogOne.one.id : 'BrandLinesheetId', brandStyleId: (state) => state.brandProductOne.one ? state.brandProductOne.one.id : 'brandStyleId' },
        parent: 'BrandLinesheetOpsPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/store/:storeId/ps/:b64ps',
    name: 'StoreDashboardPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/store/StoreDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.storeOne.one ? `${state.businessEntityOne.one.name}-${state.storeOne.one.name} > Dashboard` : 'Store Dashboard')
        },
        businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', storeId: (state) => state.storeOne.one ? state.storeOne.one.id : 'storeId' },
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/config/business/:businessEntityId/store/:storeId/ps/:b64ps',
    name: 'StoreConfigPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/store/Store.config.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.storeOne.one ? `${state.businessEntityOne.one.name}-${state.storeOne.one.name} > Config` : 'Store Config Page')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', storeId: (state) => state.storeOne.one ? state.storeOne.one.id : 'storeId' },
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/config/business/:businessEntityId/store/:storeId/edit/ps/:b64ps',
    name: 'StoreEditPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/store/store.edit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.storeOne.one ? `${state.businessEntityOne.one.name}-${state.storeOne.one.name}-edit` : 'Store Edit Page')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', storeId: (state) => state.storeOne.one ? state.storeOne.one.id : 'StoreId' },
        parent: 'StoreConfigPage'
      }
    }
  },
  {
    path: '/directory/store/:storeId/profile/ps/:b64ps',
    name: 'StoreProfilePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/store/StoreProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.storeOne.one ? `${state.storeOne.one.name}/profile` : 'Store Profile',
        routeParams: { storeId: (state) => state.storeOne.one ? state.storeOne.one.id : 'storeId' },
        parent: 'DirectoryPage'
      }
    }
  },
  //////////// refactored
  {
    path: '/portfolio/business/:businessEntityId/store/:storeId/profile/ps/:b64ps',
    name: 'PortfolioStoreProfilePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/store/StoreProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.storeOne.one ? `${state.storeOne.one.name}/profile` : 'Store Profile',
        routeParams: { storeId: (state) => state.storeOne.one ? state.storeOne.one.id : 'storeId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/ps/:b64ps',
    name: 'BusinessDashboardPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/business/BusinessDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.businessEntityOne.one ? `${state.businessEntityOne.one.name}` : 'Business Dashboard',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/procurement/ps/:b64ps',
    name: 'BusinessProcurementDashboardPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/business/BusinessProcurementDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Procurement',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/sales-channels/ps/:b64ps',
    name: 'BusinessSalesChannelsDashboardPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/BusinessSalesChannelsDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Sales Channels',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/collections/ps/:b64ps',
    name: 'BusinessCollectionsDashboardPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/BusinessCollectionsDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Collections',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/distribution/ps/:b64ps',
    name: 'BusinessDistributionDashboardPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/BusinessDistributionDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Distribution',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/distributor/:distributorId/ps/:b64ps',
    name: 'BusinessDistributorEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/BusinessDistributorEditPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Distributor',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/geo/:geoBoardId/ps/:b64ps',
    name: 'GeoBoardPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/geoBoard/GeoBoardPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Geo Board',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },

  //// end refactored
  {
    path: '/portfolio/business/:businessEntityId/retailer-collections/ps/:b64ps',
    name: 'RetailerCollectionsDashboardPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/retailerCollection/RetailerCollectionsDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Retailer Collections',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/retailer-collections/:collectionId/ps/:b64ps',
    name: 'RetailerCollectionsEditPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/retailerCollection/RetailerCollectionsEditPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Retailer Collections',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'RetailerCollectionsDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:collectionId/ops/ps/:b64ps',
    name: 'RetailerCollectionsOpsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/retailerCollection/RetailerCollectionsOpsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Collections view',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'RetailerCollectionsDashboardPage'
      }
    }
  },
  {
    path: '/directory/retailer/:businessEntityId/profile/ps/:b64ps',
    name: 'RetailerProfilePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/business/BusinessProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.businessEntityOne.one ? `${state.businessEntityOne.one.name}/profile` : 'Retailer Profile',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'DirectoryPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/profile/ps/:b64ps',
    name: 'PortfolioRetailerProfilePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/business/BusinessProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.businessEntityOne.one ? `${state.businessEntityOne.one.name}/profile` : 'Retailer Profile',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PortfolioPage'
      }
    }
  },
  // {
  //   path: '/portfolio/business/:businessEntityId/purchase_orders/ps/:b64ps',
  //   name: 'PurchaseOrderListPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderList.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: () => 'Purchase Orders',
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'BusinessDashboardPage'
  //     }
  //   }
  // },
  {
    path: '/procurement/purchase_orders/ps/:b64ps',
    name: 'PurchaseOrderListPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderList.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Purchase Order List',
        parent: 'BusinessDashboardPage'
      }
    }
  },
  // {
  //   path: '/portfolio/business/:businessEntityId/purchase_orders/:purchaseOrderId/edit/ps/:b64ps',
  //   name: 'PurchaseOrderEditPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderEdit.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'New Purchase Order')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'PurchaseOrderListPage'
  //     }
  //   }
  // },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/edit-details/ps/:b64ps',
    name: 'PurchaseOrderEditPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Add a purchase order')
        },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  // {
  //   path: '/portfolio/purchase_orders/:businessEntityId/:purchaseOrderId/ops/ps/:b64ps',
  //   name: 'PurchaseOrderOpsPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderOps.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'PurchaseOrderListPage'
  //     }
  //   }
  // },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/ops/ps/:b64ps',
    name: 'PurchaseOrderOpsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
        },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/payments/ps/:b64ps',
    name: 'PurchaseOrderPaymentsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderPayments.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
        },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/payments/ps/:b64ps',
    name: 'SalesOrderPaymentsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/salesOrders/salesOrderPayments.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.poNumber}` : 'Order Details')
        },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/processing/ps/:b64ps',
    name: 'PurchaseOrderProcessingPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/purchaseOrders/purchaseOrderProcessing.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
        },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/view-details/ps/:b64ps',
    name: 'PurchaseOrderViewDetailsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderViewDetails.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
        },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  // {
  //   path: '/portfolio/purchase_orders/:businessEntityId/:purchaseOrderId/purchase_order_item_list/ps/:b64ps',
  //   name: 'PurchaseOrderItemsListPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/PurchaseOrderItemsListPage.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? 'Order Items List' : 'Order Details')
  //       //${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'PurchaseOrderOpsPage'
  //     }
  //   }
  // },
  // {
  //   path: '/portfolio/purchase_orders/:businessEntityId/:purchaseOrderId/:purchaseOrderItemId/ops/ps/:b64ps',
  //   name: 'PurchaseOrderItemsOpsPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderItemsOps.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id && state.purchaseOrderItemOne.one ? state.purchaseOrderItemOne.one.name : 'Order Items Details')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'PurchaseOrderItemsListPage'
  //     }
  //   }
  // },
  // {
  //   path: '/portfolio/purchase_orders/:businessEntityId/:purchaseOrderId/purchase_order_item/:purchaseOrderItemId/edit/ps/:b64ps',
  //   name: 'PurchaseOrderItemEditPage',
  //   component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderItemEdit.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => state.purchaseOrderItemOne.one && state.purchaseOrderItemOne.one.id ? state.purchaseOrderItemOne.one.name : 'New Item Order',
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'PurchaseOrderItemsListPage'
  //     }
  //   }
  // },
  {
    path: '/user/:userId/ps/:b64ps',
    name: 'UserOpsPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/users/user.ops.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.userProfileOne.one ? state.userProfileOne.one.firstName : 'UserOpsPage',
        routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/user/:userId/edit/ps/:b64ps',
    name: 'UserEditPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/users/user.edit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.userProfileOne.one && state.userProfileOne.one.userId ? 'Edit User' : 'New User',
        routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'
      }
    }
  },
  {
    path: '/user/:userId/team-membership/:teamMembershipId/edit',
    name: 'UserTeamMembershipEditPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/users/user_team_membership.edit.vue'),
    meta: {
      breadcrumb: {
        label: 'Team Membership',
        routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'
      }
    }
  },
  {
    path: '/chat/:appId/:resourceId',
    name: 'ChatRoomPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/chat/ChatRoomPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Chat Room',
        parent: 'PortfolioPage'
        /*routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'*/
      }
    }
  },
  {
    path: '/activity',
    name: 'ActivityTimeLinePage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/activity/ActivityTimelinePage.vue'),
    meta: {
      breadcrumb: {
        label: 'Activity Time Line',
        parent: 'PortfolioPage'
        /*routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'*/
      }
    }
  },
  {
    path: '/metric',
    name: 'MetricPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/metric/MetricPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Metric Box',
        parent: 'PortfolioPage'
        /*routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'*/
      }
    }
  }, 
  {
    path: '/channel_chat/:resourceId/:channelId/ps/:b64ps',
    name: 'ChatChannelPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/chat/ChatChannelPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Messages',
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/ps/:b64ps?',
    name: 'PortfolioPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/portfolio/PortfolioPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Portfolio'
      }
    }
  },
  {
    path: '/directory/brand/:brandId/profile/ps/:b64ps',
    name: 'BrandProfilePage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? state.brandOne.one.name : 'Brand Profile',
        routeParams: { brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'brandId' },
        parent: 'DirectoryPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/brand/:brandId/profile/ps/:b64ps',
    name: 'PortfolioBrandProfilePage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandProfilePage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? state.brandOne.one.name : 'Brand Profile',
        routeParams: { brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'brandId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/brand/:brandId/ps/:b64ps',
    name: 'BrandDashboardPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/BrandDashboardPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.brandOne.one ? `${state.businessEntityOne.one.name}-${state.brandOne.one.name}/dashboard` : 'Brand Dashboard')
        },
        businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'brandId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/connect-to-brand/:secondaryBusinessId/ps/:b64ps',
    name: 'ConnectRetailerToBrandPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/business/ConnectRetailerToBrandPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.brandOne.one ? `${state.businessEntityOne.one.name}-${state.brandOne.one.name}` : 'Retailer to brand connection')
        },
        routeParams: { retailerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'retailerId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'brandId' },
        parent:'BrandConnectionsPage'
        //parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/directory/brand/:brandId/connect-to-retailer/:retailerId/ps/:b64ps',
    name: 'ConnectBrandToRetailerPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/ConnectBrandToRetailerPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.directorySelection.dirBusinessEntityOne && state.portfolioSelection.brandOne ? `${state.portfolioSelection.brandOne.name}-${state.directorySelection.dirBusinessEntityOne.name}` : 'Brand to Retailer connection')
        },
        routeParams: { retailerId: (state) => state.directorySelection.dirBusinessEntityOne ? state.directorySelection.dirBusinessEntityOne.id : 'retailerId', brandId: (state) => state.portfolioSelection.brandOne ? state.portfolioSelection.brandOne.id : 'brandId' },
        parent:'DirectoryPage'
        //parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/distributor/:distributorId/connect-to-retailer/:retailerId/ps/:b64ps',
    name: 'ConnectDistributorToRetailerPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/ConnectDistributorToRetailerPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.directorySelection.dirBusinessEntityOne && state.distributorOne.one ? `${state.distributorOne.one.name}-${state.directorySelection.dirBusinessEntityOne.name}` : 'Distributor to Retailer connection')
        },
        routeParams: { retailerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'retailerId', distributorId: (state) => state.distributorOne.one ? state.distributorOne.one.id : 'distributorId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/directory/ps/:b64ps?',
    name: 'DirectoryPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/directory/DirectoryPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Directory'
      }
    }
  },

  {
    path: '/connection_list/:connectionId/:connectedFlag/ps/:b64ps',
    name: 'ConnectionRequestPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/business/ConnectionRequestPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.businessEntityOne.one ? `${state.businessEntityOne.one.name}/connection list` : 'connection list',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/business/:businessEntityId/claim-brand/:brandId/ps/:b64ps',
    name: 'ClaimToBrandPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/ClaimToBrandPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.brandOne.one ? `${state.businessEntityOne.one.name}-${state.brandOne.one.name}` : 'Claim to brand connection')
        },
        routeParams: { retailerId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'brandId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/purchase_order/:purchaseOrderId/compare/:date1/:date2/ps/:b64ps',
    name: 'PurchaseOrderComparePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderChangesPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  {
    path: '/portfolio/purchase_order/:purchaseOrderId/discrepancy-report/ps/:b64ps',
    name: 'PurchaseOrderDiscrepancyReportPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderDiscrepancyReportPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Discrepancy Details')
        },
        // routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/portfolio/purchase_order/:purchaseOrderId/change-request/ps/:b64ps',
    name: 'PurchaseOrderChangeRequestPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/purchaseOrderChangeRequestPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order changes Details')
        },
        // routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/portfolio/purchase_order/:purchaseOrderId/reception/:shipmentStartDate/:shipmentCancelDate/ps/:b64ps',
    name: 'PurchaseOrderReceptionDiscrepancyReportPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/PurchaseOrderReceptionDiscrepancyReportPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Reception Discrepancy Details')
        },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/portfolio/purchase_order/:purchaseOrderId/:documentId/order-confirmation/ps/:b64ps',
    name: 'OrderConfirmationPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/dashboard/OrderConfirmationPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Order-${state.purchaseOrderOne.one.poNumber}` : 'Order confirmation')
        },
        // routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchaseOrderItemsOpsPage'
      }
    }
  },
  {
    path: '/account/ps/:b64ps',
    name: 'AshtaAccountPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/account/AshtaAccountPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name} ` : 'Account Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  /* sales order routing*/
  {
    path: '/wholesale/sales_orders/ps/:b64ps',
    name: 'SalesOrderListPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrders/salesOrderList.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Sales Orders',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/edit/ps/:b64ps',
    name: 'SalesOrderEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrders/salesOrderEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'New Sales Orders')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesOrderListPage'
      }
    }
  },
  // {
  //   path: '/portfolio/sales_orders/:businessEntityId/:salesOrderId/ops/ps/:b64ps',
  //   name: 'SalesOrderOpsPage',
  //   component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/salesOrderOps.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Details')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'SalesOrderListPage'
  //     }
  //   }
  // },
  {
    path: '/wholesale/sales_orders/:salesOrderId/ops/ps/:b64ps',
    name: 'SalesOrderOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrders/salesOrderOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesOrderListPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/processing/ps/:b64ps',
    name: 'SalesOrderProcessingPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrders/salesOrderProcessing.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesOrderListPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/items/ps/:b64ps',
    name: 'SalesOrderItemsListPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrderItems/salesOrderItemsListPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.salesOrderOne.one && state.salesOrderOne.one.id ? 'Order Items List' : 'Order Details')
        },
        routeParams: { },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/items/:salesOrderItemId/view/ps/:b64ps',
    name: 'SalesOrderItemsOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrderItems/salesOrderItemsOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.salesOrderOne.one && state.salesOrderOne.one.id && state.salesOrderItemOne.one ? state.salesOrderItemOne.one.name : 'Order Items Details')
        },
        routeParams: { },
        parent: 'SalesOrderItemsListPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/items/:salesOrderItemId/edit/ps/:b64ps',
    name: 'SalesOrderItemEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesOrderItems/salesOrderItemEdit.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.salesOrderItemOne.one && state.salesOrderItemOne.one.id ? state.salesOrderItemOne.one.name : 'New Item Order',
        routeParams: { },
        parent: 'SalesOrderItemsListPage'
      }
    }
  },
  // {
  //   path: '/portfolio/sales_orders/:businessEntityId/:salesOrderId/sales_order_item_list/ps/:b64ps',
  //   name: 'salesOrderItemsListPage',
  //   component: () => import(/* webpackChunkName: "brand" */'@/pages/sales/salesOrderItemsListPage.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? 'Order Items List' : 'Order Details')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'SalesOrderOpsPage'
  //     }
  //   }
  // },
  // {
  //   path: '/portfolio/sales_orders/:businessEntityId/:salesOrderId/:salesOrderItemId/ops/ps/:b64ps',
  //   name: 'SalesOrderItemsOpsPage',
  //   component: () => import(/* webpackChunkName: "brand" */'@/pages/sales/salesOrderItemsOps.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => {
  //         return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id && state.salesOrderItemOne.one ? state.salesOrderItemOne.one.name : 'Order Items Details')
  //       },
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'salesOrderItemsListPage'
  //     }
  //   }
  // },

  {
    path: '/portfolio/sales_order/:salesOrderId/discrepancy-report/ps/:b64ps',
    name: 'SalesOrderDiscrepancyReportPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/salesOrderDiscrepancyReportPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Discrepancy Details')
        },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/portfolio/sales_order/:salesOrderId/change-request/ps/:b64ps',
    name: 'SalesOrderChangeRequestPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/salesOrderChangeRequestPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Discrepancy Details')
        },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  // {
  //   path: '/portfolio/sales_orders/:businessEntityId/:salesOrderId/sales_orders/:salesOrderItemId/edit/ps/:b64ps',
  //   name: 'SalesOrderItemEditPage',
  //   component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/salesOrderItemEdit.vue'),
  //   meta: {
  //     breadcrumb: {
  //       label: (state) => state.salesOrderItemOne.one && state.salesOrderItemOne.one.id ? state.salesOrderItemOne.one.name : 'New Item Order',
  //       routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
  //       parent: 'salesOrderItemsListPage'
  //     }
  //   }
  // },
  {
    path: '/portfolio/wholesale_order/:salesOrderId/compare/:date1/:date2/ps/:b64ps',
    name: 'SalesOrderComparePage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/salesOrderChangesPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Wholesalee Orders-${state.salesOrderOne.one.poNumber}` : 'Order Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesOrderListPage'
      }
    }
  },
  {
    path: '/registrations/ps/:b64ps?',
    name: 'RegistrationsPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/registration/RegistrationsPage.vue'),
    meta: {
      breadcrumb: {
        label: 'Registrations List',
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:purchaseOrderId/payments/ps/:b64ps',
    name: 'PurchasePaymentsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/payment/PaymentsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Payments List',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchaseOrderListPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:purchaseOrderId/payments/:paymentId/ops/ps/:b64ps',
    name: 'PurchasePaymentOpsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/payment/PaymentOpsPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.paymentOne.one && state.paymentOne.one.id ? `${state.businessEntityOne.one.name}-Payment-${state.paymentOne.one.businessEntity.name}` : 'Payment Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchasePaymentsPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:purchaseOrderId/payments/:paymentId/edit/ps/:b64ps',
    name: 'PurchasePaymentEditPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/payment/PaymentEditPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.paymentOne.one && state.paymentOne.one.id ? `${state.businessEntityOne.one.name}-Payment-${state.paymentOne.one.businessEntity.name}` : 'New Payment')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PurchasePaymentsPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:salesOrderId/salesPayments/ps/:b64ps',
    name: 'SalesPaymentsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesPayment/SalesPaymentsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Sales Payments List',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesOrderListPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:salesOrderId/salesPayments/:paymentId/ops/ps/:b64ps',
    name: 'SalesPaymentOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesPayment/SalesPaymentOpsPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesPaymentOne.one && state.salesPaymentOne.one.id ? `${state.businessEntityOne.one.name}-Payment-${state.salesPaymentOne.one.businessEntity.name}` : 'Payment Details')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesPaymentsPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/:salesOrderId/salesPayments/:paymentId/edit/ps/:b64ps',
    name: 'SalesPaymentEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesPayment/SalesPaymentEditPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesPaymentOne.one && state.salesPaymentOne.one.id ? `${state.businessEntityOne.one.name}-Payment-${state.salesPaymentOne.one.businessEntity.name}` : 'New Payment')
        },
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'SalesPaymentsPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/sales_payment_tracker/ps/:b64ps',
    name: 'SalesPaymentTrackerPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/salesPayment/SalesPaymentTrackerPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Payment Tracker',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/payment_tracker/ps/:b64ps',
    name: 'PaymentTrackerPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/payment/PaymentTrackerPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Payment Tracker',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/notification/ps/:b64ps',
    name: 'NotificationsPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/notification/NotificationsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Notifications',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/execute/order-processing-tasks',
    name: 'TaskListPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/purchaseDataProcessing/TaskListPage.vue'),
    meta: {
      layout: 'execute',
      breadcrumb: {
        label: () => 'Task List Page',
        routeParams: { }
        //parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/execute/orders-data-process-list',
    name: 'OrdersDataProcessListPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/purchaseDataProcessing/OrdersDataProcessListPage.vue'),
    meta: {
      layout: 'execute',
      breadcrumb: { label: 'Order List' }
    }
  },
  { 
    path: '/execute/purchase-order-data-processing-test/:taskId',
    name: 'PurchaseOrderDataProcessingTestPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/purchaseDataProcessing/PurchaseOrderDataProcessingTestPage.vue'),
    meta: {
      layout: 'execute',
      breadcrumb: {  label: (state) => {
        return (state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.purchaseOrderOne.one.poNumber}-${state.purchaseOrderOne.one.purchaserBusinessEntity.name}` : 'Order Details')
      }, parent: 'TaskListPage'
      }
    }
  },
  { 
    path: '/execute/purchase-order-data-processing/:taskId',
    name: 'PurchaseOrderDataProcessingPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/purchaseDataProcessing/PurchaseOrderDataProcessingPage.vue'),
    meta: {
      layout: 'execute',
      breadcrumb: {  label: (state) => {
        return (state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.purchaseOrderOne.one.poNumber}-${state.purchaseOrderOne.one.purchaserBusinessEntity.name}` : 'Order Details')
      }, parent: 'TaskListPage'
      }
    }
  },
  { 
    path: '/execute/sales-order-data-processing/:taskId',
    name: 'SalesOrderDataProcessingPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/salesDataProcessing/SalesOrderDataProcessingPage.vue'),
    meta: {
      layout: 'execute',
      breadcrumb: {  label: (state) => {
        return (state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.salesOrderOne.one.soNumber}-${state.salesOrderOne.one.purchaserBusinessEntity.name}` : 'Order Details')
      }, parent: 'TaskListPage'
      }
    }
  },
  {
    path: '/portfolio/business/:businessEntityId/collections/:collectionId/ps/:b64ps',
    name: 'BusinessCollectionsEditPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/BusinessCollectionsEditPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Collections',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/users/ps/:b64ps',
    name: 'UsersPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/users/users.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Users',
        routeParams: { userId: (state) => state.userProfileOne.one ? state.userProfileOne.one.id : 'userId' },
        parent: 'UserOpsPage'
      }
    }
  },
  { 
    path: '/portfolio/business/:businessEntityId/brand-connection/ps/:b64ps',
    name: 'BrandConnectionPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/connection/BrandConnectionPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Brand Connection',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/users/:userId/ps/:b64ps',
    name: 'UserRegistrationPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/users/user.new.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.userProfileOne.one && state.userProfileOne.one.userId ? 'Edit User' : 'New User',
        parent: 'UserOpsPage'
      }
    }
  },
  {   
    path: '/portfolio/business/:businessEntityId/retailer-connection/ps/:b64ps',
    name: 'ConnectionRetailerPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/connection/ConnectionRetailerPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Retailer Connection',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/order-success/ps/:b64ps',
    name: 'orderSuccessPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/order/orderSuccess.vue'),
    meta: {
      breadcrumb: {
        label: () => 'orderSuccess',
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/order-error/ps/:b64ps',
    name: 'OrderErrorPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/order/orderError.vue'),
    meta: {
      breadcrumb: {
        label: () => 'orderError',
        parent: 'BusinessDashboardPage'
      }
    }
  },
  {
    path: '/platform/brand/:brandId/ops/ps/:b64ps',
    name: 'PlatformBrandOpsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/platformBrand/BrandOps.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? state.brandOne.one.name : 'Brand view',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/platform/brand/:brandId/:brandName/ops/ps/:b64ps',
    name: 'PlatformBrandOpsPageNew',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/platformBrand/BrandOpsNew.vue'),
    meta: {
      breadcrumb: {
        label: (state) => state.brandOne.one && state.brandOne.one.id ? state.brandOne.one.name : 'Brand view',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId', brandId: (state) => state.brandOne.one ? state.brandOne.one.id : 'BrandId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/platform/brands/ps/:b64ps',
    name: 'PlatformBrandListPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/platformBrand/BrandListPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Brand list',
        routeParams: { businessEntityId: (state) => state.businessEntityOne.one ? state.businessEntityOne.one.id : 'businessEntityId' },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/vendors/ps/:b64ps',
    name: 'BusinessVendorsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/connection/BusinessVendorsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Vendors list',
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/retailers/ps/:b64ps',
    name: 'BusinessRetailersPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/connection/BusinessRetailersPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Retailers list',
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/retailer-connections/ps/:b64ps',
    name: 'RetailersConnectionPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/connection/RetailersConnectionPage.vue'),
    meta: {
      breadcrumb: {
        label: () => '',//Retailers connection list
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/brand-connections/ps/:b64ps',
    name: 'BrandConnectionsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/connection/BrandConnectionPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Brand connections',
        parent: 'PortfolioPage'
      }
    }
  },
  { 
    path: '/brand-connection-requests/:registrationId',
    name: 'BrandConnectionRequestsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/ConnectionRequestsPage.vue'),
    meta: {
      layout: 'connectionRequest',
      breadcrumb: { 
        label:  () => 'Connection requests'
      }
    }
  },
  { 
    path: '/brand-connection-activation/:registrationId',
    name: 'BrandConnectionActivationPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/brand/ConnectionActivationPage.vue'),
    meta: {
      layout: 'connectionRequest',
      breadcrumb: { 
        label:  () => 'Connection activation'
      }
    }
  },
  { 
    path: '/retailer-connection-requests/:invitationId',
    name: 'RetailersConnectionRequestsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/connection/RetailersConnectionRequestPage.vue'),
    meta: {
      layout: 'connectionRequest',
      breadcrumb: { 
        label:  () => 'Connection requests'
      }
    }
  },
  { 
    path: '/retailer-connection-activation/:invitationId',
    name: 'RetailerConnectionActivationPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/connection/RetailerConnectionActivationPage.vue'),
    meta: {
      layout: 'connectionRequest',
      breadcrumb: { 
        label:  () => 'Connection activation'
      }
    }
  },
  {
    path: '/portfolio/create-connections/ps/:b64ps',
    name: 'CreateConnectionsPage',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/connection/CreateConnectionsPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Create connections',
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/portfolio/:businessEntityId/upgrade-subscription/ps/:b64ps',
    name: 'UpgradeFreeUserSubscription',
    component: () => import(/* webpackChunkName: "platform" */ '@/pages/business/UpgradeFreeUserSubscription.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Upgrade subscription',
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/business-meta-editor/:businessEntityId/ps/:b64ps',
    name: 'BusinessMetaEditor',
    component: () => import(/* webpackChunkName: "admin" */ '@/pages/business/BusinessMetaEditor.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Business Meta Editor',
        parent: 'BusinessConfigPage'
      }
    }
  },
  {
    path: '/purchase-order-meta-editor/:purchaseOrderId/ps/:b64ps',
    name: 'PurchaseOrderMetaEditorPage',
    component: () => import(/* webpackChunkName: "admin" */ '@/pages/dashboard/purchaseOrderMetaEditorPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Purchase order Meta Editor',
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/sales-order-meta-editor/:salesOrderId/ps/:b64ps',
    name: 'SalesOrderMetaEditorPage',
    component: () => import(/* webpackChunkName: "admin" */ '@/pages/sales/salesOrderMetaEditorPage.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Sales order Meta Editor',
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  // new purchase order items routes
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/items/ps/:b64ps',
    name: 'PurchaseOrderItemsListPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrderItems/PurchaseOrderItemsListPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Items')
        },
        // routeParams: { },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/shipments/ps/:b64ps',
    name: 'PurchaseOrderShipmentsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrders/purchaseOrderShipments.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.purchaseOrderOne.one && state.purchaseOrderOne.one.id ? `${state.businessEntityOne.one.name}-Purchase Orders-${state.purchaseOrderOne.one.poNumber}` : 'Order Shipments')
        },
        parent: 'PurchaseOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/shipments/ps/:b64ps',
    name: 'SalesOrderShipmentsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/salesOrders/salesOrderShipments.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.poNumber}` : 'Order Shipments')
        },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/generate_oc/ps/:b64ps',
    name: 'GenerateSalesOrderConfirmationPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/salesOrders/generateSalesOrderConfirmation.vue'),
    meta: {
      breadcrumb: {
        // label: (state) => {
        //   return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.poNumber}` : 'Order Details')
        // },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/generate_packing_list/ps/:b64ps',
    name: 'GenerateSalesOrderPackingListPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/salesOrders/generateSalesOrderPackingList.vue'),
    meta: {
      breadcrumb: {
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/wholesale/sales_orders/:salesOrderId/generate_commercial_invoice/ps/:b64ps',
    name: 'GenerateSalesOrderCommercialInvoicePage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/salesOrders/generateSalesOrderCommercialInvoice.vue'),
    meta: {
      breadcrumb: {
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/items/:purchaseOrderItemId/view/ps/:b64ps',
    name: 'PurchaseOrderItemsOpsPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrderItems/purchaseOrderItemsView.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.purchaseOrderOne.one && state.purchaseOrderOne.one.id && state.purchaseOrderItemOne.one ? state.purchaseOrderItemOne.one.name : 'Order Items Details')
        },
        routeParams: { },
        parent: 'PurchaseOrderItemsListPage'
      }
    }
  },
  {
    path: '/procurement/purchase_orders/:purchaseOrderId/items/:purchaseOrderItemId/edit/ps/:b64ps',
    name: 'PurchaseOrderItemEditPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/purchaseOrderItems/purchaseOrderItemEdit.vue'),
    meta: {
      breadcrumb: {
        label: () => 'Ordered items list',
        // label: (state) => state.purchaseOrderItemOne.one && state.purchaseOrderItemOne.one.id ? state.purchaseOrderItemOne.one.name : 'New Item Order',
        routeParams: { },
        parent: 'PurchaseOrderItemsListPage'
      }
    }
  },
  {
    path: '/me/profile/ps/:b64ps',
    name: 'UserProfileViewPage',
    component: () => import(/* webpackChunkName: "retailer" */ '@/pages/users/userProfileViewPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) =>  state.userProfileOne.one ? state.userProfileOne.one.firstName  : 'User Profile',
        routeParams: { },
        parent: 'PortfolioPage'
      }
    }
  },
  {
    path: '/sales-discrepancy/sales_orders/:salesOrderId/ps/:b64ps',
    name: 'SalesDiscrepancyReportPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/sales/SalesDiscrepancyReportPage.vue'),
    meta: {
      breadcrumb: {
        label: (state) => {
          return (state.businessEntityOne.one && state.salesOrderOne.one && state.salesOrderOne.one.id ? `${state.businessEntityOne.one.name}-Sales Orders-${state.salesOrderOne.one.soNumber}` : 'Order Discrepancy Details')
        },
        parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/activities/:b64ps',
    name: 'ActivitiesPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/ActivitiesPage.vue'),
    meta: {
      breadcrumb: {
        label:'Activities'
        //parent: 'SalesOrderOpsPage'
      }
    }
  },
  {
    path: '/insights/:b64ps',
    name: 'InsightsPage',
    component: () => import(/* webpackChunkName: "brand" */ '@/pages/business/InsightsPage.vue'),
    meta: {
      breadcrumb: {
        label:'Insights'
        //parent: 'SalesOrderOpsPage'
      }
    }
  }
]