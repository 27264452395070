import { getField, updateField } from 'vuex-map-fields'

export const global = {
  namespaced: true,
  state: {    
    showMiniRoute:false,
    showPageRouteBreadcrumb:false,
    miniRouteList: []
    ,
    inProgress: true
  },
  getters: {
    getField
  },
  mutations: {
    updateField,   
    showMiniRoute(state, yesOrNo) {
      state.showMiniRoute = yesOrNo
    },
    setMiniRouteList(state, list) {
      state.miniRouteList = list
    },
    showPageRouteBreadcrumb(state, yesOrNo) {
      state.showPageRouteBreadcrumb = yesOrNo
    }
  },
  actions: {
    showMiniRoute({ commit },yesOrNo) {
      commit('showMiniRoute', yesOrNo)
    },
    setMiniRouteList({ commit },list) {
      commit('setMiniRouteList', list)
    },
    showPageRouteBreadcrumb({ commit },yesOrNo) {
      commit('showPageRouteBreadcrumb', yesOrNo)
    }
  }

}