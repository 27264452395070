import Vue from 'vue'
import App from './App.vue'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueGtm from '@gtm-support/vue2-gtm'
import msal from 'vue-msal'
// PLUGINS
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/oak'
import './plugins/vue-shortkey'
import './plugins/vue-head'
import './plugins/vue-gtag'
import './plugins/animate'
import './plugins/clipboard'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/formatCurrency'
import './filters/formatDate'
const gtagId = process.env.VUE_APP_GTM_ID
 
// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

// Animation library - https://animate.style/
import 'animate.css/animate.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import LottieAnimation from 'lottie-vuejs' // import lottie-vuejs
Vue.use(VueGtm, {
  id: gtagId, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview:'env-4',
  //   gtm_cookies_win:'x'
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`

  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //  ignoredViews: ['main', 'homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

Vue.use(VueGoogleMaps, {
  load: { key: 'AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo', libraries: ['places', 'geometry'] }
})
// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false
Vue.use(VueTelInput)
Vue.use(LottieAnimation)
Vue.use(msal, {
  auth: {
    clientId: 'a2e65c56-24bc-48d4-8771-42ab3cd2fdee',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://local.ashta.io:8080/'
  },
  cache: {
    cacheLocation: 'localStorage'
  }
})
/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
