export default function({ dirBrandOne,dirBusinessEntityOne,dirStoreOne,businessEntityOne,routeParams,user ,businessEntityId }) {

  return {

    // main navigation - side menu
    menu: [
      //   {
      //   text: '',
      //   key: '',
      //   items: [
      //     { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard/analytics' },
      //     { icon: 'mdi-file-outline', key: 'menu.blank', text: 'Blank Page', link: '/blank' }
      //   ]
      // },
      {
        key: '',
        items: [
          { icon: 'mdi-home', key: 'Home', text: 'Home', show:(user.id ? true : false),  link: '/home/ps/' + routeParams.b64ps || '' },
          { icon: 'mdi-folder-search-outline', key: 'Directory', text: 'Directory', show:(user.id ? true : false),  link: '/directory' + '/ps/' + routeParams.b64ps },
          //////busines profile///////
          { icon: 'mdi-shopping', key: dirBusinessEntityOne ? dirBusinessEntityOne.name : 'Profile', text: dirBusinessEntityOne ? dirBusinessEntityOne.name : 'Profile',   show:(dirBusinessEntityOne ? true : false) , link: '/directory/retailer/' + (dirBusinessEntityOne ? dirBusinessEntityOne.id : '') + '/profile' + '/ps/' + routeParams.b64ps },
          //////brand profile////////
          { icon: 'mdi-shopping', key: dirBrandOne ? dirBrandOne.name : 'Profile', text: dirBrandOne ? dirBrandOne.name : 'Profile', show:(dirBrandOne ? true : false) , link: '/directory/brand/' + (dirBrandOne ? dirBrandOne.id : '') + '/profile'  + '/ps/' + routeParams.b64ps },
          //////store profile///////
          { icon: 'mdi-shopping', key: dirStoreOne ? dirStoreOne.name : 'Profile', text: dirStoreOne ? dirStoreOne.name : 'Profile',   show:(dirStoreOne ? true : false) , link: '/directory/store/' + (dirStoreOne ? dirStoreOne.id : '') + '/profile' + '/ps/' + routeParams.b64ps },

          { icon: 'mdi-clipboard-text-search-outline', key: 'Portfolio', text: 'Portfolio', show: (user.id ? true : false), link: '/portfolio' + '/ps/' + routeParams.b64ps },

          //////--Portfolio---busines profile///////
          { icon: 'mdi-image-frame', key: 'Profile', text: 'Profile', show:(businessEntityId && !routeParams.storeId && !routeParams.brandId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/profile/ps/' + routeParams.b64ps },
          //////--Portfolio---brand profile////////
          { icon: 'mdi-image-frame', key: 'Profile', text: 'Profile', show:(businessEntityId && routeParams.brandId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/brand/' + routeParams.brandId + '/profile/ps/' + routeParams.b64ps },
          //////--Portfolio--store profile///////
          { icon: 'mdi-image-frame', key: 'Profile', text: 'Profile', show:(routeParams.storeId && businessEntityId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/store/' + routeParams.storeId + '/profile/ps/' + routeParams.b64ps },

          { icon: 'mdi-monitor-dashboard', key: businessEntityOne ? businessEntityOne.name : 'Business Dashboard', text: businessEntityOne ? businessEntityOne.name : 'Business Dashboard', show:(businessEntityId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/ps/' + routeParams.b64ps },

          { icon: 'mdi-cart-arrow-down', key: 'Procurement', text: 'Procurement Dashboard', show:(businessEntityId && businessEntityOne.activeProfiles.includes('ashta_retailer') ? true : false),  link:'/portfolio/business/' + businessEntityId + '/procurement/ps/' + routeParams.b64ps },
          { icon: 'mdi-tag-heart-outline', key: 'Collections', text: 'Creative Dashboard', show:(businessEntityId && businessEntityOne.activeProfiles.includes('ashta_brand_owner') ? true : false),  link:'/portfolio/business/' + businessEntityId + '/collections/ps/' + routeParams.b64ps },

          { icon: 'mdi-tag-heart-outline', key: 'Collections', text: 'Creative Dashboard', show:(businessEntityId && (businessEntityOne.activeProfiles.includes('ashta_retailer')) ? true : false),  link:'/portfolio/business/' + businessEntityId + '/retailer-collections/ps/' + routeParams.b64ps },

          { icon: 'mdi-radar', key: 'Sales Channels', text: 'Sales Channels Dashboard', show:(businessEntityId && businessEntityOne.activeProfiles.includes('ashta_brand_owner') ? true : false),  link:'/portfolio/business/' + businessEntityId + '/sales-channels/ps/' + routeParams.b64ps },
          { icon: 'mdi-truck-delivery', key: 'Distribution', text: 'Distribution Dashboard', show:(businessEntityId && businessEntityOne.activeProfiles.includes('ashta_distributor') ? true : false),  link:'/portfolio/business/' + businessEntityId + '/distribution/ps/' + routeParams.b64ps },

          //{ icon: 'mdi-view-dashboard', key: 'Brand Owner Dashboard', text: 'Brand Owner Dashboard', show:(routeParams.brandOwnerId ? true : false),  link:'/portfolio/brand_owner/' + routeParams.brandOwnerId },
          //{ icon: 'mdi-folder-cog-outline', key: 'Brand Owner Config', text: 'Brand Owner Config', show:(routeParams.brandOwnerId ? true : false),  link:'/config/brand_owner/' + routeParams.brandOwnerId },

          { icon: 'mdi-view-dashboard', key: 'Agent Dashboard', text: 'Agent Dashboard', show:(routeParams.agentId ? true : false),  link:'/dashboard/agent/' + routeParams.agentId },
          //{ icon: 'mdi-view-dashboard', key: 'Distributor Dashboard', text: 'Distributor Dashboard', show:(routeParams.distributorId ? true : false),  link:'/dashboard/distributor/' + routeParams.distributorId },
          { icon: 'mdi-file-document', key: 'Purchase Orders', text: 'Purchase Orders', show:(businessEntityId && businessEntityOne.activeProfiles.includes('ashta_retailer')  ? true : false),  link:'/portfolio/business/' + businessEntityId + '/purchase_orders/ps/' + routeParams.b64ps },
          { icon: 'mdi-cog', key: 'Business Config', text: 'Business Config', show:(businessEntityId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/config/ps/' + routeParams.b64ps },
          { icon: 'mdi-briefcase', key: 'Brand Dashboard', text: 'Brand Dashboard', show:(businessEntityId && routeParams.brandId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/brand/' + routeParams.brandId + '/ps/' + routeParams.b64ps },
          { icon: 'mdi-view-dashboard', key: 'Store Dashboard', text: 'Store Dashboard', show:(routeParams.storeId && businessEntityId ? true : false),  link:'/portfolio/business/' + businessEntityId + '/store/' + routeParams.storeId + '/ps/' + routeParams.b64ps },
          { icon: 'mdi-store', key: 'Store Config', text: 'Store Config', show:(businessEntityId && routeParams.storeId ? true : false),  link:'/config/business/' + businessEntityId + '/store/' + routeParams.storeId + '/ps/' + routeParams.b64ps }
        ]
      }],
    // {
    //   text: 'Pages11',
    //   key: 'menu.pages',
    //   items: menuPages
    // }],

    // footer links
    footer: [{
      text: 'Docs',
      key: 'menu.docs',
      href: 'https://vuetifyjs.com',
      target: '_blank'
    }]
  }
}
