import { brandCatalogListModule, brandCatalogOneModule, brandListModule, brandOneModule, brandProductListModule, brandProductOneModule, businessEntityListModule, businessEntityOneModule, directoryOneModule, distributorOneModule, distributorsListModule, onboardRegistrationRrequestOneModule, portfolioListModule, serviceListModule, serviceOneModule,unclaimedBusinessOneModule, retailerCollectionOneModule ,retailerCollectionListModule,connectionListModule, stripeSubscriptionListModule, stripeSubscriptionOneModule, stripeCustomerOneModule, stripeSessionOneModule,stripeUserBillingHistroyListModule, stripeCardListModule, stripeCardOneModule,connectionOneModule,templateListModule } from 'commerce-vuex'
import {
  authStoreModule, dashboardModule, invitationModule, notificationList, notificationOne, organizationList, organizationOne, registrationModule, teamInviteOne, teamList, teamMembershipList, teamMembershipOne, teamOne, userOneModule, registrationRequestOneModule,registrationRequestListModule,userListModule, subscriptionOneModule, subscriptionListModule,userNotificationListModule,userNotificationSettingsListModule,userNotificationSettingsOneModule,hubNotificationListModule
} from 'oak-vuex'
import { ordersToProcessListModule, paymentListModule, paymentOneModule, purchaseOrderPaymentTermsListModule, purchaseOrderItemListModule, 
  purchaseOrderItemOneModule, purchaseOrderListModule, purchaseOrderOneModule, purchaseOrderTemplateListModule, purchaseOrderTemplateOneModule,
  retailTeamListModule,retailTeamOneModule,purchaseOrderSnapshotOneModule,purchaseOrderSnapshotListModule, purchaseOrderPaymentRecordListModule, purchaseOrderPaymentRecordOneModule,
  purchaseOrderProcessingModule, purchaseOrderConnectedDiscrepancyOneModule, purchaseOrderChangesetListModule, purchaseOrderChangesetOneModule } from 'procurement-vuex' 
import { storeListModule, storeOneModule } from 'retail-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import { wholesaleOrderItemListModule, wholesaleOrderItemOneModule, wholesaleOrderListModule, wholesaleOrderOneModule, wholesalePaymentListModule,wholesalePaymentOneModule, wholesalePackingListOneModule, wholesaleCommercialInvoiceOneModule,wholesaleRepListModule,wholesaleRepOneModule, wholesalePackingListBoxOneModule, wholesalePackingListItemListModule, wholesaleCommercialInvoiceItemListModule,wholesaleTeamListModule,wholesaleTeamOneModule,wholesaleOrderProcessorOneModule,wholesaleOrderSnapshotOneModule,wholesaleOrderProcessingOneModule, wholesaleDiscrepancyReportModule, wholesaleOrderChangesetOneModule, wholesaleOrderPaymentListModule, wholesaleOrderPaymentOneModule,wholesaleOrderPaymentRecordListModule, wholesaleOrderPaymentRecordOneModule,wholesaleOrderPaymentTermsListModule, wholesaleOrderConfirmationOneModule } from 'wholesale-vuex'
import { dataProcessorOneModule, documentListModule, documentOneModule, taskListModule, taskOneModule, noteOneModule } from 'work-vuex'
// Global vuex
import AppModule from './app'
import { activityList } from './ashta/activity.list'
import { activityOne } from './ashta/activity.one'
import { chatRoomList } from './ashta/chat_room.list'
import { chatRoomOne } from './ashta/chat_room.one'
import { directoryList } from './ashta/directory.list'
import { directorySelection } from './ashta/directory_selection'
import { global } from './ashta/global'
import { marketplaceConnectionOne } from './ashta/marketplace_connection.one'
import { marketplaceConnectionsList } from './ashta/marketplace_connections.list'
import { networkConnectionsList } from './ashta/network_connection.list'
import { networkConnectionOne } from './ashta/network_connection.one'
import { portfolioSelection } from './ashta/portfolio_selection'
import { oauth } from './gate/oauth.one'

// import { invitation } from './gate/invitation.module'
import { signupOne } from './gate/signup.one'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    global:global,
    app: AppModule,
    dashboard: dashboardModule,
    auth: authStoreModule,
    registration: registrationModule,
    signupOne,
    oauth:oauth,
    invitation: invitationModule,
    teamInviteOne,
    teamMembershipList,
    teamMembershipOne,
    organizationOne,
    organizationList,
    teamList,
    teamOne,
    marketplaceConnectionOne,
    marketplaceConnectionsList,
    storeOne: storeOneModule,
    storeList: storeListModule,
    chatRoomOne,
    chatRoomList,
    activityList,
    userProfileOne: userOneModule,
    userProfileList: userListModule,
    portfolioSelection:portfolioSelection,
    purchaseOrderOne: purchaseOrderOneModule,
    purchaseOrderList: purchaseOrderListModule,
    purchaseOrderItemOne: purchaseOrderItemOneModule,
    purchaseOrderItemList: purchaseOrderItemListModule,
    brandOne: brandOneModule,
    brandList: brandListModule,
    brandCatalogOne: brandCatalogOneModule,
    brandCatalogList: brandCatalogListModule,
    brandProductOne: brandProductOneModule,
    brandProductsList: brandProductListModule,
    businessEntityOne: businessEntityOneModule,
    businessEntityList: businessEntityListModule,
    portfolioList: portfolioListModule,
    directoryList,
    directoryOne: directoryOneModule,
    documentOne:documentOneModule,
    documentList:documentListModule,
    networkConnectionOne,
    networkConnectionsList,
    distributorsList:distributorsListModule,
    distributorOne:distributorOneModule,
    directorySelection,
    serviceList: serviceListModule,
    serviceOne : serviceOneModule,
    salesOrderOne: wholesaleOrderOneModule,
    salesOrderList: wholesaleOrderListModule,
    salesOrderItemOne: wholesaleOrderItemOneModule,
    salesOrderItemList: wholesaleOrderItemListModule,
    notificationOne:notificationOne,
    notificationList:notificationList,
    paymentList:paymentListModule,
    paymentOne:paymentOneModule,
    purchaseOrderPaymentTermsList : purchaseOrderPaymentTermsListModule,
    taskList: taskListModule,
    taskOne: taskOneModule,
    ordersToProcessList: ordersToProcessListModule,
    dataProcessorOne : dataProcessorOneModule,
    onboardRegistrationRrequestOne:onboardRegistrationRrequestOneModule,
    salesPaymentList:wholesalePaymentListModule,
    salesPaymentOne:wholesalePaymentOneModule,
    salesPackingListOne: wholesalePackingListOneModule,
    salesPackingListBoxOne : wholesalePackingListBoxOneModule,
    salesPackingListItemList : wholesalePackingListItemListModule,
    salesCommercialInvoiceOne: wholesaleCommercialInvoiceOneModule,
    salesCommercialInvoiceItemList: wholesaleCommercialInvoiceItemListModule,
    salesRepOne:wholesaleRepOneModule,
    salesRepList:wholesaleRepListModule,
    wholesaleOrderConfirmationOne: wholesaleOrderConfirmationOneModule,
    unclaimedBusinessOneModuleOne:unclaimedBusinessOneModule,
    salesTeamOne:wholesaleTeamOneModule,
    salesTeamList:wholesaleTeamListModule,
    registrationRequestOne:registrationRequestOneModule,
    registrationRequestList:registrationRequestListModule,
    retailerCollectionOne : retailerCollectionOneModule,
    retailerCollectionList:retailerCollectionListModule,
    connectionList:connectionListModule,
    stripeSubscriptionList : stripeSubscriptionListModule,
    stripeSubscriptionOne : stripeSubscriptionOneModule,
    stripeCustomerOne : stripeCustomerOneModule,
    stripeSessionOne: stripeSessionOneModule,
    stripeUserBillingHistoryList:stripeUserBillingHistroyListModule,
    stripeCardList: stripeCardListModule,
    stripeCardOne: stripeCardOneModule,
    connectionOne:connectionOneModule,
    subscriptionOne : subscriptionOneModule,
    subscriptionList : subscriptionListModule,
    userNotificationList:userNotificationListModule,
    userNotificationSettingsList:userNotificationSettingsListModule,
    userNotificationSettingsOne:userNotificationSettingsOneModule,
    hubNotificationList:hubNotificationListModule,
    salesOrderProcessorOne:wholesaleOrderProcessorOneModule,
    templateList:templateListModule,
    purchaseOrderTemplateList:purchaseOrderTemplateListModule,
    purchaseOrderTemplateOne:purchaseOrderTemplateOneModule,
    noteOne:noteOneModule,
    retailTeamList:retailTeamListModule,
    retailTeamOne:retailTeamOneModule,
    activityOne,
    purchaseOrderSnapshot:purchaseOrderSnapshotOneModule,
    purchaseOrderSnapshotList:purchaseOrderSnapshotListModule,
    purchaseOrderPaymentRecordList:purchaseOrderPaymentRecordListModule,
    purchaseOrderPaymentRecordOne:purchaseOrderPaymentRecordOneModule,
    purchaseOrderProcessing: purchaseOrderProcessingModule,
    purchaseOrderConnectedDiscrepancyOne: purchaseOrderConnectedDiscrepancyOneModule,
    salesOrderSnapshotOne:wholesaleOrderSnapshotOneModule,
    salesOrderProcessingOne:wholesaleOrderProcessingOneModule,
    salesDiscrepancyReport: wholesaleDiscrepancyReportModule,
    salesOrderChangesetOne: wholesaleOrderChangesetOneModule,
    purchaseOrderChangesetList: purchaseOrderChangesetListModule,
    purchaseOrderChangesetOne: purchaseOrderChangesetOneModule,
    salesOrderPaymentList:wholesaleOrderPaymentListModule,
    salesOrderPaymentOne:wholesaleOrderPaymentOneModule,
    salesOrderPaymentRecordList:wholesaleOrderPaymentRecordListModule,
    salesOrderPaymentRecordOne:wholesaleOrderPaymentRecordOneModule,
    salesOrderPaymentTermsList:wholesaleOrderPaymentTermsListModule
  }
})

export default store
