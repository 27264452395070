export default {
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    faq: 'FAQ',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
    takeMeBack:'Înapoi la ashta.io'
  },
  board: {
    titlePlaceholder: 'Enter a title for this card',
    deleteDescription: 'Are you sure you want to delete this card?',
    editCard: 'Edit Card',
    deleteCard: 'Delete Card',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTING',
      DONE: 'DONE'
    }
  },
  chat: {
    online: 'Users Online ({count})',
    addChannel: 'Add Channel',
    channel: 'Channel | Channels',
    message: 'Scrieți-vă mesajul'
  },
  email: {
    compose: 'Compose Email',
    send: 'Send',
    subject: 'Subject',
    labels: 'Labels',
    emptyList: 'Empty email list',
    inbox: 'Inbox',
    sent: 'Sent',
    drafts: 'Drafts',
    starred: 'Starred',
    trash: 'Trash',
    work: 'Work',
    invoice: 'Invoice'
  },
  todo: {
    addTask: 'Add Task',
    tasks: 'Tasks',
    completed: 'Completed',
    labels: 'Labels'
  },
  dashboard: {
    activity: 'Activity',
    weeklySales: 'Weekly Sales',
    sales: 'Sales',
    recentOrders: 'Recent Orders',
    sources: 'Traffic Sources',
    lastweek: 'vs last week',
    orders: 'Orders',
    customers: 'Customers',
    tickets: 'Support Tickets',
    viewReport: 'View Report'
  },
  usermenu: {
    profile: 'Profile',
    signin: 'Sign In',
    dashboard: 'Dashboard',
    signout: 'Sign Out'
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Ocurred'
  },
  check: {
    title: 'Setați o parolă nouă',
    backtosign: 'Înapoi la conectare',
    newpassword: 'Parolă Nouă',
    confirmPassword:'Confirmă parola',
    button: 'Setați o parolă nouă și conectați-vă',
    error: 'Linkul de acțiune nu este valid',
    verifylink: 'Se verifică linkul ...',
    verifyemail: 'Se verifică adresa de e-mail ...',
    emailverified: 'E-mail verificat! Redirecționare ...'
  },
  userprofilepage:{
    title:'pagina profilului utilizatorului'
  },
  profile: {
    title: 'Utilizator Profilul'
  },
  clocking: {
    title: 'Utilizator Pontaj',
    status: 'Stare',
    start:'Start',
    stopNow:'Opreste-te acum',
    task:'Sarcină',
    changetask:'Schimba Sarcina',
    change:'Schimbare',
    select:'Selectați'
  },
  forgot: {
    title: 'Ați uitat parola?',
    subtitle: 'Introduceți adresa de e-mail a contului dvs. și vă vom trimite un link pentru a vă reseta parola.',
    email: 'Email',
    button: 'Solicitați resetarea parolei',
    backtosign: 'Înapoi pentru a vă conecta'
  },
  login: {
    title: 'Sign In',
    email: 'Email',
    password: 'Parola',
    button: 'Conectare',
    orsign: 'Or sign in with',
    forgot: 'Ați uitat parola?',
    noaccount: 'nu ai un cont?',
    create: 'Creați unul aici',
    error: 'The email / password combination is invalid',
    subtitle: 'Conectați-vă la contul dvs'
  },
  register: {
    title: 'Creează cont',
    name: 'Numele complet',
    email: 'Email',
    password: 'Parola',
    button: 'Creează cont',
    orsign: 'Or sign up with',
    agree: 'By signing up, you agree to the',
    account: 'Ai deja un cont?',
    signin: 'Conectare',
    firstName: 'Nume',
    lastName:'Nume de familie',
    invitationId:'Jeton de invitație',
    teamWork:'Lucru in echipa'
  },
  utility: {
    maintenance: 'In Maintenance'
  },
  faq: {
    call: 'Have other questions? Please reach out '
  },
  menu: {
    search: 'Search (press \'ctrl + /\' to focus)',
    dashboard: 'Dashboard',
    logout: 'Logout',
    profile: 'Profile',
    blank: 'Blank Page',
    pages: 'Pages',
    others: 'Others',
    email: 'Email',
    chat: 'Chat',
    todo: 'Todo',
    board: 'Task Board',
    users: 'Users',
    usersList: 'List',
    usersEdit: 'Edit',
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify Email',
    authForgot: 'Ați uitat parola',
    authReset: 'Reset Password',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    levels: 'Menu Levels',
    disabled: 'Menu Disabled',
    docs: 'Documentation',
    feedback: 'Feedback',
    support: 'Support'
  },
  greetings:{
    greetings:'Bine ati venit! Echipă de echipă la maxim',
    welcome: 'Bine ai revenit'
  },
  pageNotFound:{
    title:'Cum ai ajuns aici?',
    subTitle:'Ne pare rău, nu putem găsi pagina pe care o căutați.',
    button:'Trimite-mi înapoi',
    search:'Căutați pe site'
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...'
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.'
      },
      sortBy: 'Sort by'
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year'
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} more'
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}'
      }
    }
  }
}
