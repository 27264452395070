<template>
  <div>
    <v-badge
      v-if="hubNotification && hubNotification.webUnreadCount > 0"
      :dot="true"
      color="red"
      offset-x="24"
      offset-y="20"
    >
      <v-btn icon @click="goToNotifications">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
    </v-badge>
    <!-- <v-btn v-else icon @click="goToNotifications">
      <v-icon>mdi-bell-outline</v-icon>
    </v-btn> -->
    <v-icon v-else :class="notificationReceivedFlag?'notification-received-bell':''" @click="goToNotifications">mdi-bell-outline</v-icon>
    <!-- <v-menu offset-y left transition="slide-y-transition">
      <template #activator="{ on }">
        <v-badge
          bordered
          color="red"
          offset-x="22"
          offset-y="22"
        >
          <v-btn icon v-on="on">
            <v-icon :class="notificationReceivedFlag?'notification-received-bell':''">mdi-bell-outline</v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-card max-height="600">
        <v-list dense max-width="400">
          <v-row>
            <v-col cols="8"> <v-subheader class="pa-2 comfortaa-light-font fontsize-14 font-color-8D9FAC">Notifications</v-subheader></v-col>
            <v-col cols="4"> <v-btn class="pa-2 font-color-00ADE7 comfortaa-light-font fontsize-14" text @click="goToNotifications">View all</v-btn></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item >
            <v-list-item-icon> <v-btn
              color="#e1f3c6"
              fab
              dark
              x-small
            >
              <v-icon color="#A7D168">mdi-check</v-icon>
            </v-btn></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-color-4D4E4E" >2 new orders marked as submitted</v-list-item-title>
              <v-list-item-subtitle class="caption font-color-8D9FAC">2 new orders were uploaded successfully and are ready to be viewed.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card>
    </v-menu> -->
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar Notifications Component
|---------------------------------------------------------------------
|
| Quickmenu to check out notifications
|
*/
export default {
  props:{
    businessEntityId: {
      type: String,
      default:''
    },
    notificationReceivedFlag:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
     
    }
  },
  computed: { 
    connectionList() {
      return this.$store.state.networkConnectionsList.connectionsList
    },
    inProgress() {
      return this.$store.state.networkConnectionsList.inProgress
    },
    portfolioSelection: function () {
      return this.$route.params.b64ps
    },
    hubNotification: function () {
      return this.$store.state.dashboard.notifications
    }
  },
  watch:{
    notificationReceivedFlag() {
      setInterval(async () => {
        this.$emit('notification-received')  
      }, 3000)
    }
  },
  methods:{
    goToNotifications()
    {
      this.$emit('go-to-notifications')

      /*if (flag === 'accept')
      {
        this.$router.push({
          name: 'ConnectRetailerToBrandPage',
          params: { retailerId: retailerId,
            brandId:brandId }
        })
      }
      else
      {
        this.$router.push({
          name: 'ConnectRetailerToBrandPage',
          params: { retailerId: retailerId,
            brandId:brandId }
        })
      }*/
     
    }
  }
}
</script>
<style scoped>
.notification-received-bell{
  display:block;
   color: #9e9e9e;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
</style>