import { auth } from 'oak-vuex'
const _ = require('lodash')
const { getHubAuthHeader, getAuthUser } = auth
const retainhubHubName = process.env.VUE_APP_RETAINHUB_NAME

const retainhubOpsApiUrl = process.env.VUE_APP_RETAINHUB_OPS_API_URL

const ashtaMaintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE

import store from '@/store'
export const customGTMEvent = (action, obj) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: action,
    data: obj
  })
  console.log('google analytics event called ', action)

  return true
}
export const commonValidationRules = {
  noRules: [],
  requiredRules: [(v) => !!v || 'This field is required'],
  requiredRulesWithZero: [
    (v) => v === 0 || !!v || 'This field is required',
    (v) => v > -1 || 'Value must be greater than 0 or 0'],
  requiredInvitationIdRules: [
    (v) => !!v || 'Invitation Token field is required'
  ],
  requiredFirstNameRules: [(v) => !!v || 'First Name field is required'],
  requiredLastNameRules: [(v) => !!v || 'Last Name field is required'],
  minLengthTenChars: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 10) || 'This field must be at least 10 characters'
  ],
  maxLength64Chars: [
    (v) => !!v || 'This field is required',
    (v) =>
      (v && v.length >= 64) || 'This field must be less then 64 characters'
  ],
  emailIdRules: [
    (v) => !!v || 'E-mail is required',
    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ],
  minSelectOneOption: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 1) || 'Select at least one option'
  ],
  barcodeRules: [
    //  (v) => !!v || 'This field is required',
    (v) =>
      (v && v.length === 13) || 'This field must be equal to 13 characters',
    (v) =>
      /^[a-zA-Z0-9-_ ]+$/.test(v) || 'This field must contain alphanumeric'
  ]
}

export const seasonList = [
  'Never Out Of Stock / Core Collection',
  'Pre-collection Spring Summer 22',
  'Main Collection Spring Summer 22',
  'Pre-collection Fall Winter 23',
  'Main Collection Fall Winter 23',
  'Pre-collection Spring Summer 23',
  'Main Collection Spring Summer 23',
  'Pre-collection Fall Winter 24',
  'Main Collection Fall Winter 24',
  'Pre-collection Spring Summer 24',
  'Main Collection Spring Summer 24',
  'Pre-collection Fall Winter 25',
  'Main Collection Fall Winter 25',
  'Pre-collection Spring Summer 25',
  'Main Collection Spring Summer 25',
  'Other'
]
export const paymentTermsList = [
  '30% deposit payment / 70% payment before first delivery',
  '30% deposit payment / 70% after last delivery',
  '30% deposit payment / 70% net 30 days after last delivery',
  '100% payment before first delivery',
  '100% payment after last delivery',
  '100% payment net 30 days after last delivery',
  'Other'
]
export const otherPaymentTermsList = [
  'Payment on order confirmation',
  'Payment before first delivery',
  //'Payment before each delivery',
  //'Payment before last delivery',
  //'Payment after first delivery',
  //'Payment after each delivery',
  'Payment after last delivery'
]
export const seasonsAndYears = {
  'Year': {
    'fieldId': 'customfield_10234',
    'name': 'Year',
    'description': '',
    'validValues': [
      {
        'value': '11370',
        'label': '2020',
        'children': []
      },
      {
        'value': '11583',
        'label': '2021',
        'children': []
      },
      {
        'value': '11584',
        'label': '2022',
        'children': []
      },
      {
        'value': '11585',
        'label': '2023',
        'children': []
      },
      {
        'value': '11586',
        'label': '2024',
        'children': []
      },
      {
        'value': '11587',
        'label': '2025',
        'children': []
      },
      {
        'value': '11588',
        'label': '2026',
        'children': []
      },
      {
        'value': '11589',
        'label': '2027',
        'children': []
      },
      {
        'value': '11590',
        'label': '2028',
        'children': []
      },
      {
        'value': '11591',
        'label': '2029',
        'children': []
      },
      {
        'value': '11592',
        'label': '2030',
        'children': []
      }
    ]
  },
  'Season': {
    'fieldId': 'customfield_10262',
    'name': 'Season',
    'description': '', 
    'validValues': [
      {
        'value': '11635',
        'label': 'BASIC',
        'children': []
      },
      {
        'value': '11636',
        'label': 'CRUISE',
        'children': []
      },
      {
        'value': '11637',
        'label': 'FALL',
        'children': []
      },
      {
        'value': '11638',
        'label': 'FALL-WINTER',
        'children': []
      },
      {
        'value': '11639',
        'label': 'PRE-FALL',
        'children': []
      },
      {
        'value': '11640',
        'label': 'PRE-SPRING',
        'children': []
      },
      {
        'value': '11641',
        'label': 'REGULAR',
        'children': []
      },
      {
        'value': '11642',
        'label': 'SPRING',
        'children': []
      },
      {
        'value': '11643',
        'label': 'SPRING-SUMMER',
        'children': []
      },
      {
        'value': '11644',
        'label': 'SUMMER',
        'children': []
      },
      {
        'value': '11645',
        'label': 'WINTER',
        'children': []
      }
    ]
  }
}

export const sliderInformation = {
  labels: [
    'DRAFT',
    'SUBMITTED',
    'APPROVED',
    'PACKED',
    'READY TO SHIP',
    'SHIPPED',
    'RECEIVED'
  ],
  labelObjects: [
    { text: 'draft', value: 30 },
    { text: 'submitted', value: 110 },
    { text: 'approved', value: 212 },
    { text: 'packed', value: 20 },
    { text: 'ready to ship', value: 65 },
    { text: 'shipped', value: 120 },
    { text: 'received', value: 54 }
  ],
  statuses: {
    PROCESSING: { icon: 'mdi-refresh', text: 'processing' },
    DRAFT: { icon: 'mdi-pencil', text: 'draft' },
    SUBMITTED: { icon: 'mdi-send', text: 'submitted 5 days ago' },
    APPROVED: {
      icon: 'mdi-timer-sand-empty',
      text: 'Estimated delivery in 14 weeks'
    },
    PACKED: {
      icon: 'mdi-timer-sand-empty',
      text: 'Shipment 1 of 2 expected in 3 weeks'
    },
    READY_TO_SHIP: {
      icon: 'mdi-timer-sand-empty',
      text: 'Expected delivery in 3 weeks'
    },
    SHIPPED: {
      icon: 'mdi-timer-sand-empty',
      text: 'Expected delivery in 3 weeks'
    },
    RECEIVED: { icon: 'mdi-gift-outline', text: 'Received today' }
  }
}
export const orderStates = {
  labels: [
    'DRAFT',
    'SUBMITTED',
    'APPROVED',
    'PACKED',
    'READY TO SHIP',
    'SHIPPED',
    'RECEIVED'
  ],

  states: [
    'draft',
    'draft_approved',
    'draft_rejected',
    'drafted',
    'submitted',
    'submitted_processing_failed',
    'submitted_processing_succeeded',
    'submitted_processing',
    'approved',
    'approved_processing',
    'approved_processing_failed',
    'approved_processing_succeeded',
    'approved_with_discrepancies',
    'approved_no_discrepancies',
    'approved_accept_discrepancies',
    'approved_reject_discrepancies',
    'packed',
    'packed_processing',
    'packed_processing_failed',
    'packed_processing_succeeded',
    'packed_with_discrepancies',
    'packed_no_discrepancies',
    'packed_wait_on_missing',
    'packed_ship_and_cancel',
    'packed_ship_and_wait',
    'ready_to_ship',
    'shipped',
    'received'
  ],
  processingStates:[
    'submitted_processing_failed',
    'submitted_processing_succeeded',
    'submitted_processing',
    'approved_processing',
    'approved_processing_failed',
    'approved_processing_succeeded',
    'packed_processing',
    'packed_processing_failed',
    'packed_processing_succeeded'
  ]
}
export const goToRoute = (name, params, vm) => {
  vm.$router.push({
    name: name,
    params: params
  })

  return true
}
export const validateSelection = (sel, vm) => {
  if (sel.length === 0) {
    vm.toast.show = true
    vm.toast.message = 'You need to select an item first.'
    vm.toast.color = 'error'

    return false
  } else {
    if (sel.length > 1) {
      let message = null

      message = 'You had more then one selection. Assuming the first ....'
      vm.$snotify.success(message, {
        position: 'rightTop',
        showProgressBar: false
      })
    }

    return true
  }
}
export const getSelection = (selectedRec) => {
  const sel = []

  if (selectedRec[0] !== null) {
    sel.push(selectedRec[0])
  }

  return sel
}
// export const isoStringToNormalDate = (date) => {
//   const newDateTime = Date.parse(date)

//   return new Date(newDateTime) // return format Sun Aug 22 2021 19:40:44 GMT+0530 (India Standard Time)
// }

export const convertDateYMDToMDY = (excelFileDate) => {
  // date should be in MM/DD/YYYY otherwise this function return NaN-NaN-NaN
  if (excelFileDate) {
    const newDateTime = Date.parse(excelFileDate)
    const newDate = new Date(newDateTime)
    const resultDate = (newDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' + newDate.getDate().toString().padStart(2, '0') + '/' + newDate.getFullYear()

    return resultDate // in format MM/DD/YYYY
  }
}
export const convertDateYMDToMDYWithTime = (excelFileDate) => {
  // date should be in MM/DD/YYYY otherwise this function return NaN-NaN-NaN
  if (excelFileDate) {
    const newDateTime = Date.parse(excelFileDate)
    const newDate = new Date(newDateTime)
    const resultDate = (newDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' + newDate.getDate().toString().padStart(2, '0') + '/' + newDate.getFullYear() +
      ' ' + newDate.getHours().toString().padStart(2, '0') + ':' + newDate.getMinutes().toString().padStart(2, '0')

    return resultDate // in format MM/DD/YYYY H:M
  }
}

export const isoToYMDFormat = (date) => {
  const newDateTime = Date.parse(date)
  const newDate = new Date(newDateTime)
  const resultDate =
    newDate.getFullYear() +
    '-' +
    (newDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    newDate.getDate().toString().padStart(2, '0')

  return resultDate //return format  "2021-08-22"
}
export const isoToDMYFormat = (date) => {
  const newDateTime = Date.parse(date)
  const newDate = new Date(newDateTime)
  const resultDate =
    newDate.getDate().toString().padStart(2, '0') +
    '-' +
    (newDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    newDate.getFullYear()

  return resultDate //return format  "2021-08-22"
}
export const calendarDateFormate = (date) => {
  const newDateTime = Date.parse(date)
  const newDate = new Date(newDateTime)
  const resultDate =
    newDate.getFullYear() +
    '-' +
    (newDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    newDate.getDate().toString().padStart(2, '0') +
    ' ' +
    newDate.getHours().toString().padStart(2, '0') +
    ':' +
    newDate.getMinutes().toString().padStart(2, '0')

  return resultDate //return format  "2021-8-22 19:40"
}

export const getLastDayOfCurrentMonth = (startDate) => {
  const givenDate = new Date(startDate)

  const lastDateOfMonth = new Date(
    givenDate.getFullYear(),
    givenDate.getMonth() + 1,
    0
  )

  const resultDate =
    lastDateOfMonth.getFullYear() +
    '-' +
    (lastDateOfMonth.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    lastDateOfMonth.getDate().toString().padStart(2, '0')

  return resultDate //return format  "2021-08-31"
}
export const formatDateAMPM = (date) => {
  const newDate = new Date(date)
  let hours = newDate.getHours()

  let minutes = newDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const strTime =
    monthNames[newDate.getMonth()] +
    ' ' +
    newDate.getDate() +
    ', ' +
    newDate.getFullYear() +
    ' ' +
    hours +
    ':' +
    minutes +
    ' ' +
    ampm

  return strTime //return format "August 22, 2021 7:40 PM"
}
export const formatDateToMonthNameDateYear = (date) => {
  const newDate = new Date(date)

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const strTime =
    monthNames[newDate.getMonth()] +
    ' ' +
    newDate.getDate() +
    ', ' +
    newDate.getFullYear()

  return strTime //return format "August 22, 2021 7:40 PM"
}

export const formatDateMonthYear = (date) => {
  const newDate = new Date(date)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const strTime = monthNames[newDate.getMonth()] + ' ' + newDate.getFullYear()

  return strTime //return format "August 2021"
}

export const getActivityLabel = (item) => {
  let label = ''

  switch (item.action) {
  case 'U':
    label =
        item.userFullName +
        ` has modified the details of business ${item.entityLineage.businessEntityName}`
    break
  case 'D':
    label =
        item.userFullName +
        ` has removed the details of business ${item.entityLineage.businessEntityName}`
    break
  case 'I':
    label =
        item.userFullName +
        ` has created the details of business${item.entityLineage.businessEntityName}`
    break
  case 'A':
    label =
        item.userFullName +
        ` has archived the details of business ${item.entityLineage.businessEntityName}`
    break
  default:
    label =
        item.userFullName +
        ` has modified the details of business ${item.entityLineage.businessEntityName}`
  }

  return label
}
export const getHoursMinutesAMPM = (date) => {
  const newDate = new Date(date)
  let hours = newDate.getHours()

  let minutes = newDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  const strTime = hours + ':' + minutes + ' ' + ampm

  return strTime //return format "August 22, 2021 7:40 PM"
}

export const defaultSortedSizeRanges = [
  '8/9',
  '10/11',
  '12/13',
  '14/15',
  '15/16',
  '17/18',
  '18/19',
  '39/40',
  '39/42',
  '41/42',
  '43/44',
  '43/46',
  '45/46',
  '47/48',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'XXS/XS',
  'XS/S',
  'P/S',
  'S/M',
  'M/L',
  'L/XL',
  'XL/XXL',
  '3XL',
  '4XL',
  'O/S',
  'IT 34',
  'IT 36',
  'IT 38',
  'IT 40',
  'IT 42',
  'IT 44',
  'IT 46',
  'IT 48',
  'IT 50',
  'IT 52',
  'IT 54',
  'FR 32',
  'FR 34',
  'FR 36',
  'FR 38',
  'FR 40',
  'FR 42',
  'FR 44',
  'FR 46',
  'FR 48',
  'FR 50',
  'FR 52',
  'US 00',
  'US 0',
  'US 2',
  'US 4',
  'US 6',
  'US 8',
  'US 10',
  'US 12',
  'US 14',
  'US 16',
  'UK 4',
  'UK 6',
  'UK 8',
  'UK 10',
  'UK 12',
  'UK 14',
  'UK 16',
  'UK 18',
  'UK 20',
  'UK 22',
  'UK 24',
  'DK 30',
  'DK 32',
  'DK 34',
  'DK 36',
  'DK 38',
  'DK 40',
  'DK 42',
  'DK 44',
  'DK 46',
  'AUD 4',
  'AUD 6',
  'AUD 8',
  'AUD 10',
  'AUD 12',
  'AUD 14',
  'AUD 16',
  'AUD 18',
  'AUD 20',
  'US 5',
  'US 5.5',
  'US 6.5',
  'US 7',
  'US 7.5',
  'US 8.5',
  'US 9',
  'US 9.5',
  'US 10.5',
  'US 11',
  'UK 2',
  'UK 2.5',
  'UK 3',
  'UK 3.5',
  'UK 4.5',
  'UK 5',
  'UK 5.5',
  'UK 6.5',
  'UK 7',
  'UK 7.5',
  'EUR 35',
  'EUR 35.5',
  'EUR 36',
  'EUR 36.5',
  'EUR 37',
  'EUR 37.5',
  'EUR 38',
  'EUR 38.5',
  'EUR 39',
  'EUR 39.5',
  'EUR 40',
  'EUR 40.5',
  'EUR 41',
  '3 Mos',
  '6 Mos',
  '9 Mos',
  '12 Mos',
  '18 Mos',
  '24 Mos'
]

export const getAttachment = async (id) => {
  return fetch(`${retainhubOpsApiUrl}/get-attachment?id=${id}`, {
    headers: {
      ...getHubAuthHeader(retainhubHubName),
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
    .then((response) => {
      console.log('response type', response.headers.get('content-type'))
      // url return from lambda for large files
      if (response.headers && response.headers.get('content-type') === 'application/json') {
        return response.json()
      }
      else
        return response.blob()
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}

export const getPresignUrls = async ({ bucket, region, fileKeys }) => {
  return fetch(`${retainhubOpsApiUrl}/get-presigned-url`, {
    method: 'POST',
    headers: {
      ...getHubAuthHeader(retainhubHubName),
      Accept: 'application/json'
    },
    body: JSON.stringify({ bucket, region, fileKeys })
  })
    .then((response) => {
      console.log('response type', response.headers.get('content-type'))
      // url return from lambda for large files
      if (response.headers && response.headers.get('content-type') === 'application/json') {
        return response.json()
      }
      // else
      //   return response.blob()
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}

export const getQuantityTotal = (qtys) => {
  let totalQty = 0

  if (qtys) {
    Object.keys(qtys).forEach((k) => {
      Object.keys(qtys[k]).forEach((v) => {
        if (!isNaN(qtys[k][v]) && v) totalQty += Number(qtys[k][v])
      })
    })
  }

  return totalQty
}
export const getSizeColorQtyObj = (data) => {
  const item = {}

  Object.keys(data).forEach((size) => {
    Object.keys(data[size]).forEach((obj) => {
      if (obj && size) {
        item.size = size
        item.color = obj
        item.qty = data[size][obj]
      }
    })
  })

  return item
}
export const getSizeColorQuantityList = (data) => {
  const qtyObjects = []

  Object.keys(data).forEach((size) => {
    Object.keys(data[size]).forEach((v) => {
      if (v && data[size][v]) {
        const qtyObject = {}
        const color = {}

        color[v] = data[size][v]
        qtyObject[size] = { ...color }
        qtyObjects.push(getSizeColorQtyObj(qtyObject))
      }
    })
  })

  return qtyObjects
}
export const numberWithCommas = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.00'
}
export const orderStatusColor = (val) => {
  switch (val) {
  case 'draft':
    return '#DC95AF'
  case 'submitted':
    return '#708EC8'
  case 'approved':
    return '#A7D168'
  case 'ready to ship':
    return '#C9DFEA'
  case 'pending':
    return '#00FFFF'
  case 'shipped':
    return '#8D9FAC'
  case 'received':
    return '#00ADE7'
  case 'packed':
    return '#00BFDA'
  case 'cancelled':
    return '#FF4144'
  default:
    return '#808080'
  }
}
export const dateFormateToDMY = (date) => {
  const newDateTime = Date.parse(date)
  const tmp = new Date(newDateTime).toLocaleDateString().split('/')

  return (date = tmp[0] + '/' + tmp[1] + '/' + tmp[2])
}
export const getQuestionAgainstName = (val) => {
  switch (val) {
  case 'order_shipping_address':
    return 'Do you have a shipping address on the order ?'
  case 'order_billing_address':
    return 'Do you have a billing address on the order ?'
  case 'order_shipping_address_matches':
    return 'Does it match the address below ?'
  case 'order_billing_address_matches':
    return 'Does it match the address below ?'
  case 'po_number':
    return 'Does the document have an invoice number ?'
  case 'shipping_cost':
    return 'Are there any shipping costs ?'
  case 'order_discount':
    return 'Is there any discount on the total value of the order ?'
  case 'order_currency':
    return 'Are there any currency specified on the order?'
  case 'order_detailed_data':
    return 'How detailed is the data on the order ?'
  case 'info_on_order_documents':
    return 'Are the below information available on the document?'
  case 'purchase_order_brand':
    return 'Does the brand on the downloaded file match with the brand name below?'
  case 'purchase_order_shipping_delivery_window':
    return 'Does the shipping delivery window on the downloaded file match with the shipping delivery window below?'
  case 'wholesale_order_brand':
    return 'Does the brand on the downloaded file match with the brand name below?'
  default:
    return val
  }
}
export const getQuestionAgainstKey = (val) => {
  switch (val) {
  case 'skuStockKeeping':
    return 'SKU (Stock Keeping Unit)'
  case 'skuQuantityOrdered':
    return 'Quantity ordered'
  case 'skuItemUnitPrice':
    return 'Item Unit price'
  case 'itemCode':
    return 'Item reference or item code'
  case 'itemName':
    return 'Item name'
  case 'itemUnitPrice':
    return 'Item Unit price'
  case 'sizeColor':
    return 'Item size range and / or color'
  case 'quantityOrdered':
    return 'Quantity ordered'

  default:
    return val
  }
}
export const currenciesWithSymbol = [
  { cc: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
  { cc: 'AUD', symbol: '$', name: 'Australian dollar' },
  { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  {
    cc: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka'
  },
  { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  { cc: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  { cc: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  { cc: 'CLP', symbol: '$', name: 'Chilean peso' },
  { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
  { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  { cc: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  { cc: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  { cc: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  { cc: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  { cc: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
  { cc: 'KRW', symbol: 'W', name: 'South Korean won' },
  { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  { cc: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
  { cc: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  { cc: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  { cc: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
  { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  { cc: 'RUB', symbol: 'R', name: 'Russian ruble' },
  { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  { cc: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  { cc: 'USD', symbol: 'US$', name: 'United States dollar' },
  { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
  { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  { cc: 'ZAR', symbol: 'R', name: 'South African rand' },
  { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }
]

function isPlatformManager(loggedInUser) {
  if (loggedInUser && loggedInUser.team_roles) {
    const [isPlatformManager] = loggedInUser.team_roles.filter((team) => {
      return team.team_role === 'platform_manager'
    })

    if (isPlatformManager) return true
    else return false
  }

  return false
}
export const beforeEachRoute = async (to, from, next) => {
  const localAshtaMaintenanceMode = localStorage.getItem(
    'ashtaMaintenanceModeFlag'
  )

  if (
    ashtaMaintenanceMode === 'true' &&
    to.name !== 'utility-maintenance' &&
    (localAshtaMaintenanceMode === null || localAshtaMaintenanceMode === 'true')
  ) {
    return next({
      name: 'utility-maintenance' // back to safety route //
    })
  }
  let subscriptionStatus = null
  let userDetails = null
  const userSubscriptionPriceId = process.env.VUE_APP_USER_SUBSCRIPTION_PRICE
  //const brandConnectionPriceId = process.env.VUE_APP_BRAND_CONNECTION_SUBSCRIPTION_PRICE
  const freeUserPriceId = process.env.VUE_APP_FREE_USER_SUBSCRIPTION_PRICE

  if (!to.matched.length) {
    location.href = to.path
  }
  const loggedInUser = getAuthUser()
  const platformManager = isPlatformManager(loggedInUser)

  // for not platform manager user
  if (!platformManager) {
    // catching up business entity id
    let businessEntityId = to.params.businessEntityId
      ? to.params.businessEntityId
      : null

    if (!businessEntityId && (to.params.b64ps || to.params.ps)) {
      const psObj = JSON.parse(
        atob(to.params.b64ps ? to.params.b64ps : to.params.ps)
      )

      businessEntityId = psObj.business_entity_id
    }

    if (businessEntityId) {
      // fetching business one
      const businessEntityOne = await store.dispatch(
        'businessEntityOne/fetchOne',
        {
          businessEntityId: businessEntityId,
          appId: process.env.VUE_APP_APP_ID
        }
      )

      if (businessEntityOne && businessEntityOne.id) {
        // fetching subscription list
        let subscriptionList = await store.dispatch(
          'subscriptionList/fetchList',
          {
            appId: process.env.VUE_APP_APP_ID,
            ownerId: businessEntityOne.id // '975443cd-8b9e-4002-ad3d-d1a12804ca5b'
          }
        )

        subscriptionList = subscriptionList[0]
        if (subscriptionList && subscriptionList.ppData) {
          const customerPaymentMethodList =
            subscriptionList.ppData &&
            subscriptionList.ppData.ppPaymentMethodsData
              ? subscriptionList.ppData.ppPaymentMethodsData
              : []

          const creditCardErrorMessage = subscriptionList.ppData.ppErrors
            .ppPaymentMethodError
            ? subscriptionList.ppData.ppErrors.ppPaymentMethodError
            : null

          const subscriptionErrorMessage = subscriptionList.ppData.ppErrors
            .ppSubscriptionError
            ? subscriptionList.ppData.ppErrors.ppSubscriptionError
            : null

          const list = subscriptionList.ppData.ppSubscriptionData
            ? subscriptionList.ppData.ppSubscriptionData
            : []

          let currentPlan = null

          list.filter((subscription) => {
            if (subscription.plan) {
              // if (subscription.plan.id === brandConnectionPriceId) {
              //   currentPlan = 'brand_connection'
              // }
              if (subscription.plan.id === freeUserPriceId) {
                currentPlan = 'free_user_plan'
              } else if (subscription.plan.id === userSubscriptionPriceId) {
                currentPlan = 'user_plan'
              }
            }

            userDetails = {
              //  status : subscription.status,
              trialEnd: subscription.trialEnd,
              trialStart: subscription.trialStart,
              subscriptionStart: subscription.currentPeriodStart,
              subscriptionEnd: subscription.currentPeriodEnd,
              subscriptionId: subscription.id,
              subscriptionCustomer: subscription.customer,
              activePlan: currentPlan,
              activePlanPriceId: subscription.plan
                ? subscription.plan.id
                : null,
              creditCardError: creditCardErrorMessage,
              subscriptionError: subscriptionErrorMessage
            }
            //  subscriptionStatus = subscription.status
          })
          if (customerPaymentMethodList) {
            userDetails.totalPaymentMethods = customerPaymentMethodList.length
          }
          userDetails.status = subscriptionList.status
          subscriptionStatus = subscriptionList.status

          // updating user subscription info in portfolio store
          await store.dispatch(
            'portfolioSelection/setUserSubscriptionDetails',
            {
              userDetails
            }
          )
          const isChalloubBusiness =
            businessEntityOne &&
            businessEntityOne.metadata &&
            businessEntityOne.metadata.enterpriseProfile === 'Challoub_Level_v0'
              ? true
              : false

          // if (
          //   !isChalloubBusiness &&
          //   subscriptionStatus &&
          //   subscriptionStatus.toLowerCase() === 'unpaid' &&
          //   to.name !== 'BusinessConfigPage'
          // ) {
          //   next({
          //     name: 'BusinessConfigPage', // back to billing route //
          //     params: {
          //       businessEntityId: businessEntityOne.id,
          //       b64ps: to.params.b64ps
          //     }
          //   })

          //   return true
          // }
        }
        /* if (!subscriptionStatus)
        {
          next({
            name: 'auth-signin' // back to sign in route //
            // params: { businessEntityId: businessEntityOne.id, b64ps: to.params.b64ps }
          })

          return true
        }*/
      }
    }
  }

  next()
}
export const priceFormatter = (price, pdp) => {
  if (price && price !== undefined) {
    price = price.toString()
    // eslint-disable-next-line prefer-destructuring
    const decimals = price.split('.')[1]

    if (!Number(decimals) || isNaN(decimals) || decimals.length === 1)
      //if 2 or 2.1
      return Number(price).toFixed(2)
    if (decimals.length === 2 && pdp)
      //if 2 or 2.1
      return price
    else if (decimals.length > 1)
      // if 2.34 or 2.56565
      return Number(price.match(/^-?\d+(?:\.\d{0,2})?/)[0])
  } else {
    return 0
  }
}
export const checkActivityResourceDetails = (activityList, newActivity) => {
  const listActivity = activityList[0].newData
  const list = activityList.filter(
    (item) => item.newData.id === newActivity.id
  )

  if (
    listActivity.resourceId === newActivity.newData.resourceId &&
    listActivity.resourceOwnerId === newActivity.newData.resourceOwnerId &&
    list.length === 0
  ) {
    return true
  } else {
    return false
  }
}

export const showErrorsSalesOrderMandatoryFields = ({
  salesOrderOne,
  salesOrderItems,
  preTemplate
}) => {
  try {
    // template fields
    let orderTemplateFields = []
    // if business provide mandatory fields to check in xlsx file
    const requiredValuesInExcel = []

    // all catched  errors
    let allMissingColumns = []

    if (preTemplate && preTemplate.itemFields && preTemplate.itemFields.length) {
      // const skippedMandatoryFields =
      //   purchaseOrderOne.metadata.notMandatoryFields &&
      //   purchaseOrderOne.metadata.notMandatoryFields.length
      //     ? purchaseOrderOne.metadata.notMandatoryFields
      //     : []

      orderTemplateFields = preTemplate.itemFields
      preTemplate.itemFields.filter((field) => {
        //if (!skippedMandatoryFields.includes(field.name)) {

        const subString = '\n'

        if (field && field.name && field.name.includes(subString)) {
          field.name = field.name.replace(/\n/g, ' ')
        }

        if (field && field.mapping && field.mapping.includes(subString)) {
          field.mapping = field.mapping.replace(/\n/g, ' ')
        }
        const isMandatory = field.submittedMandatory
        const isFieldVisibleToBrand = field.visibleToBrand === false ? false : field.visibleToBrand === 'false' ? false : true

        const nameInCamelCase = _.camelCase(field.name)

        if (field.group === 'basic_fields' || field.group === 'basicFields') {
          if (isMandatory && field.mapping && isFieldVisibleToBrand)
            requiredValuesInExcel.push({
              name: nameInCamelCase,
              group: field.group,
              mapping: field.mapping ? _.camelCase(field.mapping) : ''
            })
        } else if (isMandatory && isFieldVisibleToBrand) {
          requiredValuesInExcel.push({
            name: nameInCamelCase,
            group: field.group,
            mapping: field.mapping ? _.camelCase(field.mapping) : ''
          })
        }
        // }
      })
    }

    for (let item = 0; item < salesOrderItems.length; item++) {
      const currentItem = salesOrderItems[item]

      for (let mv = 0; mv < requiredValuesInExcel.length; mv++) {
        const currentMandatoryField = requiredValuesInExcel[mv]
        let fieldName = _.kebabCase(currentMandatoryField.name)

        fieldName = fieldName.replaceAll('-', ' ')
        if (
          ['basic_fields', 'basicFields'].includes(
            currentMandatoryField.group
          )
        ) {

          // eslint-disable-next-line no-prototype-builtins
          if (currentItem.hasOwnProperty(currentMandatoryField.mapping)) {
            if (!currentItem[currentMandatoryField.mapping]) {
              allMissingColumns.push(
                'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
              )
            }
          } else {
            const currentItemVariants = currentItem.variants

            if (currentItemVariants && currentItemVariants.length) {
              for (let v = 0; v < currentItemVariants.length; v++) {
                const currentVariant = currentItemVariants[v]

                if (!currentVariant[currentMandatoryField.mapping]) {
                  allMissingColumns.push(
                    'Order item "' +
                    currentItem.name +
                    '" and sku "' +
                    currentVariant['sku'] +
                    '" has missing value for ' +
                    fieldName
                  )
                }
              }
            }
          }
          
        } else if (
          [null, 'null','','item_custom_details'].includes(currentMandatoryField.group) &&
          currentItem.icpData
        ) {
          // if field is mapped and  mapping field has value then skip it
          if (currentMandatoryField.mapping && currentItem.icpData[currentMandatoryField.mapping])
            continue
        
          if (!currentMandatoryField.mapping && currentItem.icpData[currentMandatoryField.name])
            continue
          // if field is mapped and  mapping field dont have value 
          if (currentMandatoryField.mapping && !currentItem.icpData[currentMandatoryField.mapping]) {
            allMissingColumns.push(
              'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
            )
          } else {
            allMissingColumns.push(
              'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
            )
          }
        }
      }
    }
    allMissingColumns = [...new Set(allMissingColumns)]

    return allMissingColumns
  } catch (err) {
    console.log(err)

    return [err.message]
  }
}

export const showErrorsForMandatoryFields = ({
  purchaseOrderOne,
  purchaseOrderItems,
  preTemplate
}) => {
  try {
    // template fields
    let orderTemplateFields = []
    // if business provide mandatory fields to check in xlsx file
    const requiredValuesInExcel = []

    // all catched  errors
    let allMissingColumns = []

    if (preTemplate && preTemplate.itemFields && preTemplate.itemFields.length) {
      // const skippedMandatoryFields =
      //   purchaseOrderOne.metadata.notMandatoryFields &&
      //   purchaseOrderOne.metadata.notMandatoryFields.length
      //     ? purchaseOrderOne.metadata.notMandatoryFields
      //     : []

      orderTemplateFields = preTemplate.itemFields
      preTemplate.itemFields.filter((field) => {
        //if (!skippedMandatoryFields.includes(field.name)) {

        const subString = '\n'

        if (field && field.name && field.name.includes(subString)) {
          field.name = field.name.replace(/\n/g, ' ')
        }

        if (field && field.mapping && field.mapping.includes(subString)) {
          field.mapping = field.mapping.replace(/\n/g, ' ')
        }
        const isMandatory = field.submittedMandatory
        const nameInCamelCase = _.camelCase(field.name)

        if (field.group === 'basic_fields' || field.group === 'basicFields') {
          if (isMandatory && field.mapping)
            requiredValuesInExcel.push({
              name: nameInCamelCase,
              group: field.group,
              mapping: field.mapping ? _.camelCase(field.mapping) : ''
            })
        } else if (isMandatory) {
          requiredValuesInExcel.push({
            name: nameInCamelCase,
            group: field.group,
            mapping: field.mapping ? _.camelCase(field.mapping) : ''
          })
        }
        // }
      })
    }

    for (let item = 0; item < purchaseOrderItems.length; item++) {
      const currentItem = purchaseOrderItems[item]

      for (let mv = 0; mv < requiredValuesInExcel.length; mv++) {
        const currentMandatoryField = requiredValuesInExcel[mv]
        let fieldName = _.kebabCase(currentMandatoryField.name)

        fieldName = fieldName.replaceAll('-', ' ')
        if (
          ['basic_fields', 'basicFields'].includes(
            currentMandatoryField.group
          )
        ) {

          // eslint-disable-next-line no-prototype-builtins
          if (currentItem.hasOwnProperty(currentMandatoryField.mapping)) {
            if (!currentItem[currentMandatoryField.mapping]) {
              allMissingColumns.push(
                'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
              )
            }
          } else {
            const currentItemVariants = currentItem.variants

            if (currentItemVariants && currentItemVariants.length) {
              for (let v = 0; v < currentItemVariants.length; v++) {
                const currentVariant = currentItemVariants[v]

                if (!currentVariant[currentMandatoryField.mapping]) {
                  allMissingColumns.push(
                    'Order item "' +
                    currentItem.name +
                    '" and sku "' +
                    currentVariant['sku'] +
                    '" has missing value for ' +
                    fieldName
                  )
                }
              }
            }
          }
        } else if (
          [null, 'null','','item_custom_details'].includes(currentMandatoryField.group) &&
          currentItem.icpData
        ) {
          // if field is mapped and  mapping field has value then skip it
          if (currentMandatoryField.mapping && currentItem.icpData[currentMandatoryField.mapping])
            continue
        
          if (!currentMandatoryField.mapping && currentItem.icpData[currentMandatoryField.name])
            continue
          // if field is mapped and  mapping field dont have value 
          if (currentMandatoryField.mapping && !currentItem.icpData[currentMandatoryField.mapping]) {
            allMissingColumns.push(
              'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
            )
          } else {
            allMissingColumns.push(
              'Order item "' +
                currentItem.name +
                '" has missing value for ' +
                fieldName
            )
          }
        }
      }
    }
    allMissingColumns = [...new Set(allMissingColumns)]

    return allMissingColumns
  } catch (err) {
    console.log(err)

    return [err.message]
  }
}
export const sortList = (list, property) => {
  return list.sort((a, b) => {
    if (a[property].toLowerCase() < b[property].toLowerCase()) return -1
    if (a[property].toLowerCase() > b[property].toLowerCase()) return 1

    return 0
  })
}
export const sortByAlphabetically = (list) => {
  return list.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1
    if (a.toLowerCase() > b.toLowerCase()) return 1

    return 0
  })
}
export const getCurrency = (val) => {
  switch (val) {
  case 'USD':
    return '$'
  case 'EUR':
    return '€'
  case 'GBP':
    return '£'
  case 'AED':
    return 'AED '
  default:
    return '$'
  }
}
export const orderStateCountDays = (item, snapshotObj) => {
  const stateHistory = snapshotObj && snapshotObj.insights && snapshotObj.insights.stateHistory ? snapshotObj.insights : null

  if (stateHistory) {
    const stateData = stateHistory.find(
      (obj) => obj.state === item.toLowerCase()
    )

    // console.log('state data',stateData)
    if (stateData) {
      const todayDate = new Date()
      const prevDate = new Date(stateData.stateTs)

      const days = Math.round((todayDate - prevDate) / (1000 * 60 * 60 * 24))
      const result = days > 1 ? days + ' days ago' : days + ' day ago'

      return result
      // return stateData ? stateData.stateTs : ''
    }
  }

  return ''
}
export const checkSmallestShipmentStartDate = (orderItemsShipmentDates) => {
  const smallest = orderItemsShipmentDates.reduce((acc, loc) =>
    new Date(acc.shipmentStartDate).getTime() < new Date(loc.shipmentStartDate).getTime()
      ? acc
      : loc
  )

  return smallest
}

export const humpToSnake = (inputString) => {
  let snakeCaseString = ''

  for (let i = 0; i < inputString.length; i++) {
    const char = inputString[i]

    if (char.toUpperCase() === char && i > 0) {
      snakeCaseString += '_'
    }

    snakeCaseString += char.toLowerCase()
  }

  return snakeCaseString
}

export const getPaymentTypesInObj = (data) => {
  const obj = {
    //on_order_confirmation: {},
    //before_delivery: {},
    //after_delivery: {},
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].type === 'Payment on order confirmation' && data[i].paid) {
      obj['on_order_confirmation'] = {}
      obj['on_order_confirmation']['percentage'] = data[i].paid
      //obj.on_order_confirmation.net_payment_days = data[i].days;
    }
    if (data[i].type === 'Payment before first delivery' && data[i].paid) {
      obj['before_first_delivery'] = {}
      obj['before_first_delivery']['percentage'] = data[i].paid
      obj['before_first_delivery']['net_payment_days'] = data[i].days
    }
    
    if (data[i].type === 'Payment after last delivery' && data[i].paid) {
      obj['after_last_delivery'] = {}
      obj['after_last_delivery']['percentage'] = data[i].paid
      obj['after_last_delivery']['net_payment_days'] = data[i].days
    }
  }

  return obj
}

export const getPaymentTypesInArray = (pmtObjs) => {
  if (pmtObjs) {
    const paymentOptions = []
    //to sort payment terms as per required - confrimation, before, after
    const data = {}
    const sortedKeys = Object.keys(pmtObjs).sort().reverse()

    sortedKeys.filter((key) => {
      data[key] = pmtObjs[key]
    })
    Object.keys(data).forEach((key) => {
      const paymentObj = { type: '', paid: 0, days: 0 }

      let flag = false

      if (data[key]) {
        Object.keys(data[key]).forEach((v) => {
          const val = data[key][v]

          if (key === 'afterLastDelivery' && val !== null) {
            flag = true
            paymentObj.type = 'Payment after last delivery'
            if (v === 'percentage') {
              paymentObj.paid = data[key][v]
            } else {
              paymentObj.days = data[key][v]
            }
          }
        
          if (key === 'beforeFirstDelivery' && val !== null) {
            flag = true
            paymentObj.type = 'Payment before first delivery'
            if (v === 'percentage') {
              paymentObj.paid = data[key][v]
            } else {
              paymentObj.days = data[key][v]
            }
          }
        
          if (key === 'onOrderConfirmation' && val !== null) {
            flag = true
            paymentObj.type = 'Payment on order confirmation'
            if (v === 'percentage') {
              paymentObj.paid = data[key][v]
            } else {
              paymentObj.days = data[key][v]
            }
          }
        })
      }
      if (flag) {
        paymentOptions.push(paymentObj)
      }
    })

    return paymentOptions

  }

}
export const removeObjectSymbolsFromString = (item) => {
  if (item) {
    let lable = JSON.stringify(item)

    lable = lable.replaceAll('{', ' ')
    lable = lable.replaceAll('}', ' ')
    lable = lable.replaceAll('"', ' ')
    
    return lable
  }

  return ''
}

export const getDiscrepanciesTitleIcon = (item) => {
  if (item) {
    if (item === 'payment') return 'mdi-credit-card-outline'
    if (item === 'discount_pct') return 'mdi-credit-card-outline'
    if (item === 'shipping_cost') return 'mdi-truck'
    if (item === 'order_ccy') return 'mdi-cash-multiple'
    if (item === 'tax_pct') return 'mdi-credit-card-outline'
    if (item === 'payment_terms_object') return 'mdi-credit-card-outline'

    return 'mdi-information-outline'
  }

  return ''
}
export const updateTermsList = (paymentTermsList) => {
  const copyList = paymentTermsList

  for (let i = 0; i < paymentTermsList.length;i++ ) {

    if (paymentTermsList[i] && paymentTermsList[i].termsList) {
      paymentTermsList[i].termsList = []
    }
    // if(paymentTermsList.length === 1){
    //   let termsList=otherPaymentTermsList
    //   paymentTermsList[i]["termsList"]=termsList
    // }else if(paymentTermsList.length === 2){
    for (let j = 0; j < copyList.length;j++) {
      if (paymentTermsList[i].type !== copyList[j].type && paymentTermsList.length !== otherPaymentTermsList.length) {
        const termsList = otherPaymentTermsList.filter((item) => item !== copyList[j].type)

        paymentTermsList[i]['termsList'] = termsList
      } else if ((paymentTermsList[i].termsList === undefined || paymentTermsList[i].termsList.length === 0 ) && paymentTermsList.length !== otherPaymentTermsList.length) {
        paymentTermsList[i]['termsList'] = otherPaymentTermsList
      } else if (paymentTermsList.length === otherPaymentTermsList.length) {
        const termsList = [paymentTermsList[i].type]

        paymentTermsList[i]['termsList'] = termsList
      }
    }
    // }else{
    //   let termsList=[paymentTermsList[i].type]
    //   paymentTermsList[i]["termsList"]=termsList
    // }
  
  }

  return paymentTermsList
}