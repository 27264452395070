//import { doChangeConnection, doChangePassword, doFetchList } from '@/services/marketplace_connection.service'
//import humps from 'lodash-humps'
import humps from 'lodash-humps'
import { doPortolioSelect } from '../../services/portfolio.service'

export const portfolioSelection = {
  namespaced: true,
  state: {
    businessOne: null,
    brandOne: null,
    storeOne: null,
    businessShortList: [],
    brandShortList: [],
    storeShortList: [],
    b64Encoding: null,
    userSubscriptionDetail: null
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setBusinessOne(state, businessEntityOne) {
      state.businessOne = businessEntityOne
    },
    setBrandOne(state, brandOne) {
      state.brandOne = brandOne
    },
    setStoreOne(state, storeOne) {
      state.storeOne = storeOne
    },
    setBusinessShortList(state, businessShortList) {
      state.businessShortList = businessShortList || []

    },
    setBrandShortList(state, brandShortList) {
      state.brandShortList = brandShortList || []
    },
    setStoreShortList(state, storeShortList) {
      state.storeShortList = storeShortList || []
    },
    setB64Encoding(state, b64Encoding) {
      state.b64Encoding = b64Encoding
    },
    setUserSubscriptionDetails(state, userDetails) {
      state.userSubscriptionDetail = userDetails
    }
  },
  actions: {
    fetchFromRoute: async ( { state, commit, dispatch }, psRouteParam ) => {
      const psObj = JSON.parse(atob(psRouteParam))

      commit('inProgress', true)
      const ps =  await doPortolioSelect ( { preselection:psObj, nameFilters:{} })
      /*ps:  {"selection":
      {"business":{"id":"2f63519f-af89-4cee-b391-28ec8f026c1a","name":"SOVEREIGN Concept store","status":"unclaimed"},
      "store":{"id":"88492800-abde-4721-97b8-8d86ef919520","name":"Main Store ","status":"unclaimed"},
      "brand":{"id":"4f9d63f8-91be-44d8-b9ef-f90433751906","name":"RaresBrand","status":"unclaimed"}},
      "business_short_list":[{"id":"94b0a45a-28b2-4dee-95d6-bbe4cdea168a","name":"AJURS & CR7 concept stores","status":"unclaimed"},{"id":"132b0450-a114-47cd-8890-cbe8fa446e1e","name":"Ashta","status":"unclaimed"},{"id":"cf21fa8a-2257-4186-8ecd-865dd3138643","name":"Business Owner of Bajaj ","status":"unclaimed"},{"id":"cf4d8315-cd7d-4ce5-8a65-4ad6a9775b27","name":"Business Owner of Grandma","status":"unclaimed"},{"id":"e7ae9abf-079d-452a-aa3e-fa7a474bea27","name":"Business Owner of Newbrand","status":"unclaimed"}],"store_short_list":null,"brand_shortlist":null} */

      commit('setBusinessOne', humps(ps.selection.business))
      commit('setBrandOne', humps(ps.selection.brand))
      commit('setStoreOne', humps(ps.selection.store))

      const obj = {}

      if (ps.selection.business) {
        obj.business_entity_id = ps.selection.business.id
      }

      if (ps.selection.brand) {
        obj.brand_id = ps.selection.brand.id
      }

      if (ps.selection.store) {
        obj.store_id = ps.selection.store.id
      }

      commit('setB64Encoding', window.btoa(JSON.stringify(obj)))
      commit('setBusinessShortList',ps.business_short_list)
      commit('setBrandShortList',ps.brand_short_list)
      commit('setStoreShortList',ps.store_short_list)

      commit('inProgress', false)

    },
    fetchDefault: async ( { state, commit, dispatch }, router) => {
      commit('inProgress', true)
      const ps =  await doPortolioSelect ( { preselection:{}, nameFilters:{} })

      /*ps:  {"selection":
      {"business":{"id":"2f63519f-af89-4cee-b391-28ec8f026c1a","name":"SOVEREIGN Concept store","status":"unclaimed"},
      "store":{"id":"88492800-abde-4721-97b8-8d86ef919520","name":"Main Store ","status":"unclaimed"},
      "brand":{"id":"4f9d63f8-91be-44d8-b9ef-f90433751906","name":"RaresBrand","status":"unclaimed"}},
      "business_short_list":[{"id":"94b0a45a-28b2-4dee-95d6-bbe4cdea168a","name":"AJURS & CR7 concept stores","status":"unclaimed"},{"id":"132b0450-a114-47cd-8890-cbe8fa446e1e","name":"Ashta","status":"unclaimed"},{"id":"cf21fa8a-2257-4186-8ecd-865dd3138643","name":"Business Owner of Bajaj ","status":"unclaimed"},{"id":"cf4d8315-cd7d-4ce5-8a65-4ad6a9775b27","name":"Business Owner of Grandma","status":"unclaimed"},{"id":"e7ae9abf-079d-452a-aa3e-fa7a474bea27","name":"Business Owner of Newbrand","status":"unclaimed"}],"store_short_list":null,"brand_shortlist":null} */
      commit('setBusinessOne', ps.selection.business)
      commit('setBrandOne',ps.selection.brand)
      commit('setStoreOne',ps.selection.store)

      const obj = {}

      if (ps.selection.business) {
        obj.business_entity_id = ps.selection.business.id
      }

      if (ps.selection.brand) {
        obj.brand_id = ps.selection.brand.id
      }

      if (ps.selection.store) {
        obj.store_id = ps.selection.store.id
      }

      commit('setB64Encoding', window.btoa(JSON.stringify(obj)))

      commit('setBusinessShortList',ps.business_short_list)
      commit('setBrandShortList',ps.brand_short_list)
      commit('setStoreShortList',ps.store_short_list)

      commit('inProgress', false)
      const b64 = window.btoa(JSON.stringify({
        business_entity_id:state.businessOne ? state.businessOne.id : null,
        brand_id:state.brandOne ? state.brandOne.id : null,
        store_id:state.storeOne ? state.storeOne.id : null
      }))
      let newPath = router.currentRoute.fullPath

      newPath = newPath.replace('/ps', '/ps/' + b64)
      newPath = newPath.replace('//','/')
      window.location.replace(newPath)
      
    },
    setUserSubscriptionDetails: async ( { commit }, { userDetails } ) => {
      commit('setUserSubscriptionDetails', userDetails)
    }
  }//actions
}
