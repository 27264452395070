import en from '../translations/en'
import ro from '../translations/ro'

export default {
  // current locale
  //locale: 'en',

  // when translation is not available fallback to that locale
  fallbackLocale: 'en',

  // availabled locales for user selection
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'ro',
    flag: 'ro',
    label: 'Română',
    messages: ro
  }]
}
