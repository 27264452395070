import { doFetchConnectionsList, doFetchList } from '@/services/network_connection.service'
import humps from 'lodash-humps'

export const networkConnectionsList = {
  namespaced: true,
  state: {
    list: [],
    connectionsList:[],
    inProgress: false,
    connectionsInProgress: false
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    },
    setConnectionsInProgress(state, yesOrNo) {
      state.connectionsInProgress = yesOrNo
    },
    setConnectionsList(state, connectionsList) {
      state.connectionsList = connectionsList
    }
  },
  actions: {
    fetchList: async ({ commit }, { businessEntityId, appId } ) => {

      commit('inProgress', true)
      const list = await doFetchList( { businessEntityId, appId } )
     
      if (list) {
        commit('setList', humps((list)))
      } else
        commit('setList', [])
      commit('inProgress', false)
    },
    fetchConnectionsList: async ({ commit }, { businessEntityId ,appId, type } ) => {
      commit('setConnectionsInProgress', true)
      try {
        if (businessEntityId)
        {
          const list = await doFetchConnectionsList( { businessEntityId, appId, type } )

          if (list) {
            commit('setConnectionsList', humps((list)))
          } else
          {
            commit('setConnectionsList', [])
          }
           
        }
        else
        {
          commit('setConnectionsList', [])
        }

      }
      catch (err)
      {
        console.log(err)
      }
      finally {
        commit('setConnectionsInProgress', false)
      }
     
    }
  }
}