<template>
  <div>
    <!-- menu level 1 -->
    <wired-nav-menu-item
      v-if="!psBusinessShortList.length < 1 && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-home"
      text="Home"
      route="HomePage"
      :route-params="{'ps':psB64Encoding}"
    />
    <!-- && psBusinessShortList.length > 0 -->
    <wired-nav-menu-item
      v-if="psBusinessShortList && psBusinessShortList.length && userProfileOne"
      :mini-flag="miniFlag"
      icon="mdi-clipboard-text-search-outline"
      text="Portfolio"
      route="PortfolioPage"
      :route-params="{'ps':psB64Encoding}"
    />
    <wired-nav-menu-item
      v-if="psBusinessShortList && psBusinessShortList.length > 0 && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-monitor-dashboard"
      :link="'/portfolio/business/' + psBusinessOne.id + '/ps/' + psB64Encoding"
      :text="psBusinessOne?psBusinessOne.name:''"
    />
    <!-- <wired-nav-menu-item
      v-if="psBusinessShortList && psBusinessShortList.length > 0 && psBusinessOne && psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-cart-arrow-down"
      :link="'/portfolio/business/' + psBusinessOne.id + '/procurement/ps/' + psB64Encoding"
      text="Procurement"
    /> -->
    <wired-nav-menu-item
      :mini-flag="miniFlag"
      icon="mdi-format-list-checks"
      :link="'/activities/' + psB64Encoding"
      text="Activities"
    />
    <wired-nav-menu-item
      :mini-flag="miniFlag"
      icon="mdi-chart-line"
      :link="'/insights/' + psB64Encoding"
      text="Insights"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-file-document"
      :link="'/procurement/purchase_orders/ps/' + psB64Encoding"
      text="Purchase Orders"
    />
    <!--<wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isRetailer && purchaseOrderId && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-cash-multiple"
      :link="'/portfolio/business/' + psBusinessOne.id +'/'+ purchaseOrderId +'/payments/ps/' + psB64Encoding"
      text="Payments"
    />-->
    <wired-nav-menu-item
      v-if="psBusinessOne && !psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-text-box-check-outline"
      :link="'/wholesale/sales_orders/ps/' + psB64Encoding"
      text="Sales Orders"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-credit-card-outline"
      :link="'/portfolio/business/' + psBusinessOne.id + '/payment_tracker/ps/' + psB64Encoding"
      text="Payment Tracker"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && !psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-credit-card-outline"
      :link="'/portfolio/business/' + psBusinessOne.id + '/sales_payment_tracker/ps/' + psB64Encoding"
      text="Payment Tracker"
    />
    <!--<wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isDistributor && salesOrderId && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-cash-multiple"
      :link="'/portfolio/business/' + psBusinessOne.id +'/'+ salesOrderId +'/payments/ps/' + psB64Encoding"
      text="Payments"
    />-->
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isBrandOwner && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-radar"
      :link="'/portfolio/business/' + psBusinessOne.id + '/sales-channels/ps/' + psB64Encoding"
      text="Sales Channels"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isDistributor && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-truck-delivery"
      :link="'/portfolio/business/' + psBusinessOne.id + '/distribution/ps/' + psB64Encoding"
      text="Distribution"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isBrandOwner && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-tag-heart-outline"
      :link="'/portfolio/business/' + psBusinessOne.id + '/collections/ps/' + psB64Encoding"
      text="Collections"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && (psBusinessOne.isRetailer || !psBusinessOne.isDistributor || !psBusinessOne.isBrandOwner)"
      :mini-flag="miniFlag"
      icon="mdi-account-switch"
      :link="'/portfolio/vendors/ps/' + psB64Encoding"
      text="Vendors"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && !psBusinessOne.isRetailer && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-account-group"
      :link="'/portfolio/retailers/ps/' + psB64Encoding"
      text="Retailers"
    />
    <!-- <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isBrandOwner && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-connection"
      :link="'/portfolio/business/' + psBusinessOne.id + '/retailer-connection/ps/' + psB64Encoding"
      text="Connection"
    /> -->
    <wired-nav-menu-item
      v-if="psBusinessOne && !psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-link-variant"
      :link="'/portfolio/retailer-connections/ps/' + psB64Encoding"
      text="Connections"
    />
    <wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isRetailer"
      :mini-flag="miniFlag"
      icon="mdi-link-variant"
      :link="'/portfolio/brand-connections/ps/' + psB64Encoding"
      text="Brand Connections"
    />
    <!--<wired-nav-menu-item
      v-if="psBusinessOne && psBusinessOne.isRetailer && isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-tag-heart-outline"
      :link="'/portfolio/business/' + psBusinessOne.id + '/retailer-collections/ps/' + psB64Encoding"
      text="Collections"
    />-->
    <!-- :business-entity-one="businessEntityOne" -->
    <wired-nav-menu-item
      v-if="psBusinessOne"
      :mini-flag="miniFlag"
      icon="mdi-cog"
      :link="'/portfolio/business/' + psBusinessOne.id + '/config/ps/' + psB64Encoding"
      text="Settings"
    />
    <!-- && psBusinessOne.isRetailer -->
    <wired-nav-menu-item
      v-if="psBusinessOne"
      :mini-flag="miniFlag"
      icon="mdi-forum-outline"
      :link="'/channel_chat/' + psBusinessOne.id + '/'+resourceId+'/ps/' + psB64Encoding"
      text="Messages"
      :badge-flag="chatMessageReceivedFlag"
      :message-count="chatUnreadMessagesCount"
    />
    <!-- chatMessageReceivedFlag -->
    <!--<wired-nav-menu-item   
      v-if="psBusinessShortList && psBusinessShortList.length > 0"
      :mini-flag="miniFlag"
      icon="mdi-share-variant-outline"
      :link="'/directory/ps/'+psB64Encoding"
      text="Connections"
    />-->
    <wired-nav-menu-item
      v-if="dsBusinessOne"
      :mini-flag="miniFlag"
      icon="mdi-image-frame"
      :link="'/directory/retailer/' + dsBusinessOne.id + '/profile' + '/ps/' + psB64Encoding"
      text="Profile"
    />
    <wired-nav-menu-item
      v-if="isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-bootstrap"
      :link="'/platform/brands/ps/' + psB64Encoding"
      text="Brands Directory"
    />
    <wired-nav-menu-item
      v-if="isPlatformManager && layout !== 'execute'"
      :mini-flag="miniFlag"
      icon="mdi-account-plus"
      :link="'/registrations' + '/ps/' + psB64Encoding"
      text="Registrations"
    />
    <wired-nav-menu-item
      v-if="isPlatformManager"
      :mini-flag="miniFlag"
      icon="mdi-format-list-bulleted"
      :link="'/execute/order-processing-tasks'"
      text="Tasks List"
    />
    <!--<wired-nav-menu-item
      v-if="layout === 'execute'"
      :mini-flag="miniFlag"
      icon="mdi-file-outline"
      link="/execute/orders-data-process-list"
      text="Order List"
    />-->
    <wired-nav-menu-item
      v-if="isPlatformManager && userProfileOne && userProfileOne.userId"
      :mini-flag="miniFlag"
      icon="mdi-account"
      :link="'/users' + '/ps/' + psB64Encoding"
      text="Users"
    />
  </div>
</template>

<script>
import WiredNavMenuItem from './WiredNavMenuItem'
import { mapState } from 'vuex'
import permissionsMixin from '@/mixins/permissionsMixin'

export default {
  components: {
    WiredNavMenuItem
  },
  mixins: [permissionsMixin],
  props:{
    miniFlag:{
      type:Boolean,
      default:false
    },
    chatMessageReceivedFlag:{
      type:Boolean,
      default:false
    },
    chatUnreadMessagesCount:{
      type:Number,
      default:0
    }
  },
  computed: {
    ...mapState({
      psBusinessOne: (state) => state.portfolioSelection.businessOne,
      psBusinessShortList: (state) => state.portfolioSelection.businessShortList,
      psB64Encoding: (state) => state.portfolioSelection.b64Encoding,
      dsBusinessOne: (state) => state.directorySelection.dirBusinessEntityOne,
      businessEntityOne: (state) => state.businessEntityOne.one,
      userProfileOne:(state) => state.userProfileOne.one
      //dsBrandOne: (state) => state.directorySelection.dirBrandOne,portfolioBusinessList.length === 1
      //dsStoreOne: (state) => state.directorySelection.dirStoreOne
    }),
    purchaseOrderId: function () {
      return this.$route.params.purchaseOrderId
    },
    resourceId: function () {
      return this.$route.params.resourceId ? this.$route.params.resourceId : 'new'
    },
    salesOrderId: function () {
      return this.$route.params.salesOrderId
    },
    layout:function() {
      return this.$route.meta.layout
    }
  }
}
</script>