import { doPostOne } from '@/services/activity.service'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

const snakes = createHumps(snakeCase)

export const activityOne = {
  namespaced: true,
  state: {
    one: null,
    inProgress: true
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    }
  },
  actions: {
    save: async({ commit },activity) => {
      commit('inProgress', true)
      try {
        const created = await doPostOne({  activity })

        commit('setOne', humps(created))

        return created
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    }
  }
}