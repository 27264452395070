import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      teamRoles: (state) => state.dashboard.teamRoles,
      psMainBusinessEntityId:(state) => state.portfolioSelection.businessOne ? state.portfolioSelection.businessOne.id : null
      //purchaseOrderOne: (state) => state.purchaseOrderOne.one
    }), 
    isPurchaseOrderConnected:function() {
      const purchaseOrderOne = this.$store.state.purchaseOrderOne.one

      if (purchaseOrderOne && purchaseOrderOne.metadata && purchaseOrderOne.metadata.connectionId) {
        return true
      }

      return false
    },   
    isSalesOrderConnected:function() {
      const salesOrderOne = this.$store.state.salesOrderOne.one

      if (salesOrderOne && salesOrderOne.metadata && salesOrderOne.metadata.connectionId) {
        return true
      }

      return false
    },
    isOrderProcessor: function () {
      if (this.teamRoles) {
        const [isOrderProcessor]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'order_processor')
        })

        if (isOrderProcessor)
          return true
        else
          return false
      }

      return false
    },
    isPlatformManager: function () {

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else
          return false
      }

      return false
    },
    isBusinessOwnerManager: function () {

      if (this.teamRoles) {
        const [isBusinessOwnerManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'business_owner' || team.teamRole === 'business_manager')
        })

        if (isBusinessOwnerManager)
          return true
        else
          return false
      }

      return false
    },
    isRetailerBusinessPurchaseManager:function() {
      let businessTeam = null

      businessTeam = this.teamRoles.filter((team) => {
        return (team.resourceIds.includes(this.psMainBusinessEntityId) && (team.teamRole === 'business-manager' || team.teamRole === 'purchasing_manager'))
      })[0]
      if (businessTeam)
        return true
      else return false
    },
    isBusinessOwnerForUser: function () {

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'business_owner' && team.teamRole === 'business_manager')
        })

        if (isPlatformManager)
          return true
        else 
          return false
      }

      return false
    },
    isBusinessOwnerForPS: function () {
      let businessTeam = null

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else {
          businessTeam = this.teamRoles.filter((team) => {
            return (team.resourceIds.includes(this.psMainBusinessEntityId) && (team.teamRole === 'business_owner'))
          })[0]
          if (businessTeam)
            return true
          else return false
        }
      }

      return false
    },
    isBusinessManagerForPS: function () {
      let businessTeam = null

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else {
          businessTeam = this.teamRoles.filter((team) => {
            return (team.resourceIds.includes(this.psMainBusinessEntityId) && (team.teamRole === 'business_manager'))
          })[0]
          if (businessTeam)
            return true
          else return false
        }
      }

      return false
    },
    isOrderInProcessing: function() {
      const snapshotData = this.$store.state.purchaseOrderSnapshot.orderSnapshotObj

      if (snapshotData && snapshotData.details && (snapshotData.details.state === 'submitted_processing' || snapshotData.details.state === 'submitted_processing_failed' || snapshotData.details.state === 'submitted_processing_succeeded' ||  snapshotData.details.state === 'approved_processing' || snapshotData.details.state === 'approved_processing_failed' || snapshotData.details.state === 'approved_processing_succeeded' || snapshotData.details.state === 'packed_processing' || snapshotData.details.state === 'packed_processing_failed' || snapshotData.details.state === 'packed_processing_succeeded')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    isBusinessManager: function (businessEntityId) {
      let businessTeam = null

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else {
          businessTeam = this.teamRoles.filter((team) => {
            return (team.resourceIds.includes(businessEntityId) && (team.teamRole === 'business_manager'))
          })[0]
          if (businessTeam)
            return true
          else return false
        }
      }

      return false
    },
    isBusinessOwner: function (businessEntityId) {
      let businessTeam = null

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else {
          businessTeam = this.teamRoles.filter((team) => {
            return (team.resourceIds.includes(businessEntityId) && (team.teamRole === 'business_owner'))
          })[0]
          if (businessTeam)
            return true
          else return false
        }
      }

      return false
    },
    isDistributorManager: function (businessEntityId) {
      let businessTeam = null

      if (this.teamRoles) {
        const [isPlatformManager]  = this.teamRoles.filter((team) => {
          return (team.teamRole === 'platform_manager')
        })

        if (isPlatformManager)
          return true
        else {
          businessTeam = this.teamRoles.filter((team) => {
            return (team.resourceIds.includes(businessEntityId) && (team.teamRole === 'distribution_manager'))
          })[0]
          if (businessTeam)
            return true
          else return false
        }
      }

      return false
    }
  }
}
