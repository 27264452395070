import humps from 'lodash-humps'
import { storeService } from 'retail-vuex'
const { doFetchOne } = storeService

export const directorySelection = {
  namespaced: true,
  state: {
    dirBusinessEntityOne: null, 
    dirBrandOne: null,
    dirStoreOne: null
  },
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setBusinessEntityOne(state, business) {
      state.dirBusinessEntityOne = business
    },
    setBrandOne(state, brand) {
      state.dirBrandOne = brand
    },
    setStoreOne(state, store) {
      state.dirStoreOne = store
    }
  },
  actions: {
    //For Store One  
    setDirectoryStore: async ({ commit }, { storeId, businessEntityId,appId }) => {
      commit('inProgress', true)
      try {
        const store = await doFetchOne({ storeId,businessEntityId, appId })
  
        if (store) {
          commit('setStoreOne', humps(store))
        }  else {
          commit('setStoreOne', humps())
 
        }      
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
  
    //For Brand One
    setDirectoryBrand: async ({ commit,dispatch }, { brandId,appId }) => {
      commit('inProgress', true)
      try {
        const brand = await dispatch('brandOne/fetchOne', {
          brandId: brandId,
          appId: appId
        }, { root: true })
    
        if (brand) {
          commit('setBrandOne', humps(brand))
        } else {
          commit('setBrandOne', humps())
        }        
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
  
    //For BusinessEntity One
    setDirectoryBusinessEntity: async ({ commit,dispatch }, { businessEntityId,appId }) => {
      commit('inProgress', true)
      try {
        const business = await dispatch('businessEntityOne/fetchOne', {
          businessEntityId: businessEntityId,
          appId: appId
        }, { root: true })
    
        if (business) {
          commit('setBusinessEntityOne', humps(business))
        } else {
          commit('setBusinessEntityOne', humps())
        }       
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        throw err
      } finally {
        commit('inProgress', false)
      }
    },
    resetOne: async({ commit }) => {
      commit('setStoreOne',null)
      commit('setBrandOne', null)
      commit('setBusinessEntityOne', null)
    }
  }
}